import React, { useState } from 'react'

import SearchSlide from '../../../components/SearchSlide/SearchSlide'
import GalleryInfo from '../../../components/GalleryInfo/GalleryInfo'
import './Venue.css'
import BannerSlide from '../../../components/BannerSlide/BannerSlide'
import publicGallery from '../../../services/public/artDB/publicGallery'
import { Link, useNavigate } from 'react-router-dom'
import { bannerImage, handleImgError, topicImage, userImage } from '../../../services/imageRoute'
import useMobileDetect from 'use-mobile-detect-hook';
import setting from '../../../services/setting'
import { shallowEqual, useSelector } from 'react-redux'
import AllSlide from '../../../components/BannerSlide/AllSlide'
import { KYCImageGallery } from '../../../components/KYC/gallery'
import { LoadingState } from '../../../context/loadingStateProvider'
import { checkUseName } from '../../../hook/utils/checkUseName'

function Venue() {
  const [banner, setBanner] = React.useState([])
  const { collectionBannerList } = useSelector(v => v.setting, shallowEqual)
  const loadingState = React.useContext(LoadingState);

  const { login } = useSelector(v => v.auth, shallowEqual)
  const navigate = useNavigate()

  const detectMobile = useMobileDetect();

  const rows = 2
  const slidesPerRow = 5

  const [items, setItems] = React.useState([])
  const [tableItem, setTableItem] = React.useState([])
  const [rowItem, setRowItem] = React.useState([])
  const [total, setTotal] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const [checkLang, setCheckLang] = React.useState('ko');

  const [topicItem, setTopicItem] = React.useState([])
  const [topics, setTopics] = React.useState([])


  const setImage = () => {
    let _banner = [];

    collectionBannerList.map(item => {
      _banner.push({
        info: detectMobile.isMobile() || window.innerWidth < 480 ? `${bannerImage}/${item.mobile}` : `${bannerImage}/${item.web}`,
        link: item.address,
        type: item.type
      });
    });

    setBanner(_banner)
  }

  React.useEffect(() => {
    setImage();
    window.addEventListener('resize', setImage);
    return () => {
      window.removeEventListener('resize', setImage);
    }
  }, [collectionBannerList]);



  React.useEffect(() => {
    getTopicList()
  }, [])
  React.useEffect(() => {
    getList(currentPage, rows * slidesPerRow, search)
  }, [currentPage, search])

  const getList = async (currentPage, row, search) => {
    //loadingState.setText('잠시만 기다려주세요.');
    //loadingState.setOpen(true);
    const res = await publicGallery.getGalleryList(currentPage, row, search)
    //loadingState.setOpen(false);
    //loadingState.setText('');
    setTotal(res.total)
    setItems(res.agency_list)
  }

  const getTopicList = async () => {
    const res = await setting.getList(2)
    setTopicItem(res.list)
  }

  const setLike = async (data, setData, pk) => {
    if (!login) {
      alert('로그인이 필요한 서비스입니다.')
      navigate('/login', { replace: true })
      return
    }

    const res = await publicGallery.setLike(pk)
    if (!res.resultCode) {
      return
    }
    setData(
      data.map(
        it => it.user_ID === pk
          ? { ...it, heart: res.message === 'ADD' }
          : it
      )
    )
  }

  React.useEffect(() => {
    if (topicItem.length === 0) {
      return
    }

    const myItem = []
    for (let i = 0; i < topicItem.length; i++) {
      if (i == 3) break;

      const json = {
        pk: topicItem[i].pk,
        info: `${topicImage}/${topicItem[i].sumbnail}`,
        title: topicItem[i].title,
        link: 'issue',
        detail: topicItem[i].content,
        detailM: topicItem[i].content_M,
      }
      myItem.push(json)
    }
    setTopics(myItem)

  }, [topicItem])

  React.useEffect(() => {
    let myRowItem = []
    if (items.length === 0) {
      setRowItem(myRowItem)
      return
    }
    items.map((item, i) => {
      const checkKoName = `${item.first_NAME}${item.name}` !== '';
      const checkEnName = `${item.first_ENG_NAME}${item.eng_NAME}` !== '';
      myRowItem.push(
        <div key={`pageSlide_${i}`} className="page-slider__item" >
          {
            item.user_TYPE === 2
            ?
            <div className="page-slider-item-row" >
            <Link
              to={`/artdb/venue/${item.user_ID}`}
              state={{
                item: item,
              }}
            >
              <div style={{ position: 'relative', zIndex: 1 }}>
                {
                  item.status === 2 &&
                  <KYCImageGallery />
                }
                <img
                  className="page-item-image"
                  style={{ width: detectMobile.isMobile() ? '57rem' : '130rem', height: detectMobile.isMobile() ? '57' : '130rem' }}
                  alt='itemInfo'
                  src={`${userImage}/${item.picture}`}
                  onError={handleImgError}
                  onContextMenu={
                    (e) => {
                        e.preventDefault();
                    }
                  }
                ></img>
              </div>
            </Link>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <h2 className="page-item-author" >
                {
                  item.lang === 'ko' 
                    ? <>
                      {item.first_NAME}<br /><span>{item.first_ENG_NAME}</span>
                    </>
                    :
                    <>
                    {item.first_ENG_NAME}<br /><span>{item.first_NAME}</span>
                    </>
                }
              </h2>
                <h2 className="page-item-email">{item.email}</h2>
              <button onClick={() => (setLike(items, setItems, item.user_ID))} className={item.heart ? "artist-item--heart_active" : "artist-item--heart"} style={{ marginTop: 4 }}>
                <span className="ir_pm">좋아요</span>
              </button>
            </div>
          </div>
          :
          <div className="page-slider-item-row" >
            <Link
              to={`/artdb/collection/${item.user_ID}`}
              state={{
                item: item,
              }}
            >
              <div style={{ position: 'relative', zIndex: 1 }}>
                {
                  item.status === 2 &&
                  <KYCImageGallery />
                }
                <img
                  className="page-item-image"
                  style={{ width: detectMobile.isMobile() ? '57rem' : '130rem', height: detectMobile.isMobile() ? '57' : '130rem' }}
                  alt='itemInfo'
                  src={`${userImage}/${item.picture}`}
                  onError={handleImgError}
                  onContextMenu={
                    (e) => {
                        e.preventDefault();
                    }
                  }
                ></img>
              </div>
            </Link>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <h2 className="page-item-author" >
              {item.use_NAME === '0' ? (
                checkLang === 'ko' ?
                  <h2 className="page-item-author" style={{display: 'block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    {/* {`${item.first_NAME}${item.name}`} <span>{`${item.first_NAME}${item.name}` === `${item.first_ENG_NAME} ${item.eng_NAME}` ? "" : `${item.first_ENG_NAME} ${item.eng_NAME}`}</span> */}
                    {checkKoName ? `${item.first_NAME}${item.name}` : `${item.first_ENG_NAME} ${item.eng_NAME}`}<br /><span>{`${item.first_NAME}${item.name}` === `${item.first_ENG_NAME} ${item.eng_NAME}` ? "" : checkKoName ? `${item.first_ENG_NAME} ${item.eng_NAME}` : ''}</span>
                  </h2> :
                  <h2 className="page-item-author" style={{display: 'block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    {/* {`${item.first_ENG_NAME} ${item.eng_NAME}`} <span>{`${item.first_NAME}${item.name}` === `${item.first_ENG_NAME} ${item.eng_NAME}` ? "" : `${item.first_NAME}${item.name}`}</span> */}
                    {checkEnName ? `${item.first_ENG_NAME} ${item.eng_NAME}`: `${item.first_NAME}${item.name}`}<br /><span>{`${item.first_NAME}${item.name}` === `${item.first_ENG_NAME} ${item.eng_NAME}` ? "" : checkEnName ? `${item.first_NAME}${item.name}` : ''}</span>
                  </h2>
              ) : (
                <></>
              )}
               {item.use_NAME === '1' ? (
                checkLang === 'ko' ?
                <h2 className="page-item-author" style={{display: 'block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    {item.nickname === '' ? item.eng_NICKNAME : item.nickname} <span>{item.nickname === '' ? '' : item.nickname === item.eng_NICKNAME ? "" : item.eng_NICKNAME}</span>
                  </h2> :
                  <h2 className="page-item-author" style={{display: 'block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    {item.eng_NICKNAME === '' ? item.nickname: item.eng_NICKNAME} <span>{item.eng_NICKNAME === '' ? '' : item.nickname === item.eng_NICKNAME ? "" : item.nickname}</span>
                  </h2>
              ) : (
                <></>
              )}
              </h2>
                <h2 className="page-item-email">{item.email}</h2>
              <button onClick={() => (setLike(items, setItems, item.user_ID))} className={item.heart ? "artist-item--heart_active" : "artist-item--heart"} style={{ marginTop: 4 }}>
                <span className="ir_pm">좋아요</span>
              </button>
            </div>
          </div>
          }
          
        </div>
      )
      setRowItem(myRowItem)
    })
  }, [items])

  React.useEffect(() => {
    let colItem = []
    colItem.push(
      <div className="page-slider-my-row-venue"
        style={{ display: 'flex', flexWrap: 'wrap' }}>
        {rowItem}
      </div>
    )
    setTableItem(colItem)
  }, [rowItem])


  return (
    <div className="artdb-venue">
      <AllSlide items={banner} />
      <SearchSlide
        title_e={'Collection'}
        title_k={'소장처'}
        option_title={'콜렉션명'}
        rows={rows}
        slidesPerRow={slidesPerRow}
        items={tableItem}
        breakRows={2}
        breakSlidesPerRow={4}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setCheckLang={setCheckLang}
        search={search}
        setSearch={setSearch}
      />
      <GalleryInfo
        title_e={'Issue +'}
        title_k={'이슈 플러스'}
        items={topics}
      />
    </div>
  )
}
export default Venue