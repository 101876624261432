import React from 'react'
import './DeliveryModal.css'
import market from '../../services/public/market/market'

function DeliveryModal({ setModalOpen, setAddModalOpen, deliveryother, setDeliveryother, setDeliveryName, setDeliveryUser, setDeliveryPhone, setDeliveryAddress, setDeliveryAddressDetail}) {
  

  const [items, setItems] = React.useState([...deliveryother]);

  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false);
    document.body.style.overflow = 'unset'; // 모달 창 종료 시 스크롤 가능
  }

  const AddDelivery = () => {
    closeModal();
    setAddModalOpen(true);
  }

  React.useEffect(() => {
    setItems(items.sort((a, b) => b['delivery_TYPE'] - a['delivery_TYPE']))
  }, [items])

  const setDefaultAddress = async (item) => {
    if (item.delivery_TYPE !== '1') {
      const res = await market.setDefaultAddress(item.pk)
      if (!res.resultCode) return
      // setDeliveryother(v => ({
      //   ...v,
      //   delivery_TYPE: v.pk === item.pk ? '1' : '0'
      // }));

      const sortBeforeItmes = items.map(v => {
        if (v.pk === item.pk) {
          // Create a *new* object with changes
          return { ...v, delivery_TYPE: '1' };
        } else {
          // No changes
          return { ...v, delivery_TYPE: '0' };
        }
      })

      const sortItem = sortBeforeItmes.sort((a, b) => b['delivery_TYPE'] - a['delivery_TYPE'])

      
      setItems(sortItem)
      setDeliveryother(sortItem)
    }
    setDeliveryName(item.address_NAME)
    setDeliveryUser(item.giver)
    setDeliveryPhone(item.phone)
    setDeliveryAddress(item.address)
    setDeliveryAddressDetail(item.address_DETAIL)
    closeModal()
  }

  const deleteAddress = async (item) => {
    if (item.delivery_TYPE === '1') {
      alert('기본배송지는 삭제가 불가능합니다.')
      return
    }
    const res = await market.deleteAddress(item.pk)
    if (!res.resultCode) return

    setDeliveryother(v => ({
      ...v,
      otherAdress: deliveryother.filter(v => v.pk !== item.pk)
    }));
  }


  const selectAddress = (item) => {
    setDeliveryName(item.address_NAME)
    setDeliveryUser(item.giver)
    setDeliveryPhone(item.phone)
    setDeliveryAddress(item.address)
    setDeliveryAddressDetail(item.address_DETAIL)
    closeModal()
  }

  return (
    <div className="del-modal">
      <div className="modal-container" onClick={closeModal}>
        <div className="modal-body" onClick={e => e.stopPropagation()}>
          <header className="del-header">
            <h1>배송지 선택</h1>
            <button className="del-close" onClick={closeModal}></button>
          </header>
          <ul className="del-content">

            {
              items.map((item, i) => {
                return (
                  <li className="del-item" key={i}>
                    <div>

                    </div>
                    <div className="del-tag">
                      {item.address_NAME}
                      {/* <span className="del-tlt">{item.address_NAME}</span> */}
                    </div>
                    <div className="del-info">
                      <span className="del-tlt">받으실 분</span>
                      <p>{item.giver}</p>
                    </div>
                    <div className="del-info">
                      <span className="del-tlt">주소</span>
                      <p>
                        {item.address} <br></br>
                        {item.address_DETAIL}
                      </p>
                    </div>
                    <div className="del-info">
                      <span className="del-tlt">전화번호</span>
                      {/* <p>{`${item.phone.substr(0, 3)}-${item.phone.substr(3,4)}-${item.phone.substr(7,4)}`}</p> */}
                      <p>{item.phone}</p>
                    </div>
                    <div className='del-button'>
                      <div className="del-btn">
                        <button onClick={() => setDefaultAddress(item)}>기본 설정</button>
                      </div>
                      <div className="del-btn">
                        <button onClick={() => deleteAddress(item, i)}>삭제</button>
                        <button onClick={() => selectAddress(item)}>선택</button>
                      </div>
                    </div>
                  </li>
                )
              })
            }
          </ul>
          <button className="del-add" onClick={AddDelivery}>
            <span className="del-add-sym"></span>
            <span>배송지 추가</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default DeliveryModal
