import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import TableSlide from '../../../../components/TableSlide/TableSlide'
import './ArtistWallet.css'
import nft from '../../../../services/myArchive/work/nft'
import { setBidTime } from '../../../../hook/utils/getDateStr'
import { artViewPrice } from '../../../../hook/utils/artdbView'
import { workMainImage } from '../../../../services/imageRoute'
import { shallowEqual, useSelector } from 'react-redux'
import delivery from '../../../../services/myArchive/work/delivery'

import useMobileDetect from 'use-mobile-detect-hook';
import { checkUseName } from '../../../../hook/utils/checkUseName'
import PinModal from '../../../../components/Modal/PinModal'
import ModalPortal from '../../../../components/Modal/ModalPortal'
import { LoadingState } from '../../../../context/loadingStateProvider'
import { onChangePoints } from '../../../../hook/utils/comma'
import DeliveryInfoModal from '../../../../components/Modal/DeliveryInfoModal'
import DeliveryReturnInfoModal from '../../../../components/Modal/DeliveryReturnInfoModal'
import market from '../../../../services/public/market/market'
import { getToday } from '../../../../hook/utils/getToday'
import trade from '../../../../services/myArchive/work/trade'
import ReturnNoticeModal from '../../../../components/Modal/ReturnNoticeModal'
function ArtistWallet() {
  const { lang } = useSelector(v => v.setting, shallowEqual)
  const navigate = useNavigate();
  const detectMobile = useMobileDetect();
  const loadingState = React.useContext(LoadingState);

  const options = ['실물 작품', 'NFT']
  const [menu, setMenu] = useState(0)
  const rows = detectMobile.isMobile() ? 4 : 9;
  const [currentPage, setCurrentPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [work, setWork] = useState([])
  const [item, setItem] = useState([])
  const [tableItem, setTableItem] = useState([])

  const [pinModal, setPinModal] = useState(false)
  const [delModal, setDelModal] = useState(false)
  const [noticeModal, setNoticeModal] = useState(false)
  const [presentModal, setPresentModal] = useState(false)
  const [newPin, setNewPin] = useState('')
  const [data, setData] = useState()

  const getList = async (currentPage, rows, isNft) => {
    const res = await nft.myTopBid(currentPage, rows, isNft)
    if (isNft === 0) {
      // console.log(res)
      setWork(res.data.content)
      setTotal(res.data.totalElements)
    }

  }

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);

  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  }, []);


  React.useEffect(() => {
    getList(currentPage, rows, menu)
  }, [currentPage, menu])


  React.useEffect(() => {
    // nft 보류
    if (item.length === 0) {
      return
    }

    const result = [];

    for (let i = 0; i < item.length; i++) {
      result.push(
        <div className="wallet-item table-slider-item">
          <img src={item[i].image} alt='' onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img>
          <span>{item[i].name}</span>
          <span>{item[i].token_ID}</span>
          <span>{setBidTime(item[i].date)}</span>
          <span>{artViewPrice(1, item[i].bid_PRICE)}</span>
          <Link
            to={'/market/payment'}
            state={{
              item: item[i],
              nft: true,
              bid_nft: true,
            }}
          >
            <button className="delivery-complete">결제하기</button>
          </Link>
          <div className="wallet-mb-item">
            <div className="container wallet-mb-container">
              <img src={item[i].info} alt='' onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img>
              <div className="wallet-mb-info">
                <div className="wallet-mb-state">
                  <span className="w-mb-state-date">[setBidTime(item[i].date)]</span>
                </div>
                <h2>{item[i].name}</h2>
                <h4>{item[i].token_ID}</h4>
                <h1>{artViewPrice(1, item[i].bid_PRICE)}</h1>
                <Link
                  to={'/market/payment'}
                  state={{
                    item: item[i],
                    nft: true,
                    bid_nft: true,
                  }}
                >
                  <button className="w-mb-state-pay">결제하기</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )
    }

    setTableItem(result)

  }, [item])



  const pay = (json) => {

    const item = {}
    // item.image = item.work_INFO.image
    // item.title = item.work_INFO.title
    // item.eng_TITLE = item.work_INFO.eng_TITLE
    // item.eng_TITLE = item.work_INFO.eng_TITLE
    // item.artist = item.work_INFO.eng_ARTIST
    // item.price_UNIT = 1
    // item.price = price
    item.unique_KEY = json.unique_KEY
    item.image = json.image
    item.title = json.title
    item.eng_TITLE = json.eng_TITLE
    item.eng_TITLE = json.eng_TITLE
    item.artist = json.artist
    item.price_UNIT = 1
    item.price = json.price.replaceAll(',', '').split(' ')[0]

    // return;

    navigate('/market/payment', { state: { item: item, sellType: '경매 낙찰' } })
  }
  const confirmed = async (pin) => {

    loadingState.setText('구매 확정중입니다.\n잠시만 기다려주세요.')
    loadingState.setOpen(true)
    const res = await delivery.purchaseConfirmed(data, pin)
    setData();
    if (!res.resultCode) {
      if (res.message === "not delivered") {
        alert('판매자가 배송 후 가능합니다.')
        loadingState.setOpen(false);
        loadingState.setText("");
        return;
      }
      alert('관리자에게 문의해주세요.')
      loadingState.setOpen(false)
      loadingState.setText('')
      return;
    }
    alert('구매확정되었습니다.')
    setWork(
      work.map(v =>
        v.pk === data ? { ...v, completion_TIME: new Date(), state: '1' } : v
      )
    );
    loadingState.setOpen(false)
    loadingState.setText('')
  }

  const action = (data) => {
    if(data.work_INFO.transactionTb.sendDeliveryInfo === null){
      alert('판매자가 배송 후 가능합니다.')
      return;
    }
    setData(data.pk)
    setPinModal(true);
  }


  const notice = (data) => {
    setData(data)
    setNoticeModal(true)
  }

  const cancel = async() => {
    setNoticeModal(false)
    if(data?.work_INFO.transactionTb.sendDeliveryInfo === null){
      const res = await market.cancel(data?.pk);

      if(!res.resultCode){
        alert('관리자에게 문의해주세요.');
        return;
      }
      setWork(work.map(
        work => work.pk === data?.pk
        ? {...work, state: '3', completion_TIME: getToday()}
        : work
      ))
      return;
    }
    setDelModal(true);
  }


  const getPresent = async(data) => {
    setData(data.pk)
    setPresentModal(true);
  }
  
  const confirmedPresent = async (pin) => {

    loadingState.setText('선물을 받는중입니다.\n잠시만 기다려주세요.')
    loadingState.setOpen(true)
    const res = await trade.present.pConfirmed(data, pin)
    setData();
    if (!res.resultCode) {
      alert('관리자에게 문의해주세요.')
      loadingState.setOpen(false)
      loadingState.setText('')
      return;
    }
    alert('선물받기를 완료했습니다.')
    setWork(
      work.map(v =>
        v.pk === data ? { ...v, completion_TIME: new Date(), state: '11' } : v
      )
    );
    loadingState.setOpen(false)
    loadingState.setText('')
  }

  React.useEffect(() => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
  })
  
    if (work.length === 0) {
      return
    }

    const result = [];

    for (let i = 0; i < work.length; i++) {

      const json = {
        unique_KEY: work[i].work_INFO.unique_KEY,
        image: work[i].work_INFO.image,
        state: work[i].state,
        title: lang === 'ko' ? work[i].work_INFO.title : work[i].work_INFO.eng_TITLE,
        artist: lang === 'ko' ? work[i].work_INFO.artist : work[i].work_INFO.eng_ARTIST,
        seller: checkUseName(lang, work[i].seller.use_NAME, `${work[i].seller.first_NAME}${work[i].seller.name}`, `${work[i].seller.first_ENG_NAME} ${work[i].seller.eng_NAME}`, work[i].seller.nickname, work[i].seller.eng_NICKNAME),
        phone : work[i].seller.phone_NUMBER,
        time: setBidTime(work[i].auctionBidTb === null ? work[i].time : work[i].auctionBidTb.date),
        price: work[i].state === '10' || work[i].state === '11' ? '-' :artViewPrice(1, work[i].auctionBidTb === null ? work[i].price : work[i].auctionBidTb.bid_PRICE),
        complete: setBidTime(work[i].auctionBidTb === null ? work[i].completion_TIME : work[i].auctionBidTb.date)
      }

      result.push(
        <div className="table-slider-item" key={work[i].pk} style={matches ? { height: '210rem' } : {}}>
          <ul>
            <li>{(total) - (rows * currentPage + i)}</li>
            <li> <img src={`${workMainImage}/${json.image}`} alt='' onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img></li>
            <li>{json.artist}</li>
            <li>{json.title}</li>
            <li>{json.seller}<br />{json.phone}</li>
            <li>{json.time}</li>
            <li>{json.price}</li>
            <li >
              {
                     json.state === '10' ? 
                     <button className="do-pay" onClick={() => getPresent(work[i])}>선물받기</button>
                     :
                     json.state === '11'?
                      <button className="done-pay">
                     수증<br />{json.complete}</button>
                     :
                work[i].completion_TIME !== null ? (
                    <button className="done-pay">{
                      json.state === '1' ? '' :
                      json.state === '2' ? "구매확정" :
                      json.state === '3' ? "반품요청" :
                      json.state === '4' ? "반품거부" :
                      json.state === '5' ? "반품승인" :
                      json.state === '6' ? "환불완료" : ''
                      }{json.state !== '1' && <br/>}{json.state !== '1' && json.complete}</button>
                ) :
                  work[i].work_INFO.transactionTb === null ?
                    <button className="bid-pay" onClick={() => pay(json)}>낙찰작품결제</button>
                    : (
                      <div style={{display: 'flex', flexDirection: 'column', gap: '10rem'}}>
                        <button className="do-pay" onClick={() => action(work[i])}>구매확정</button>
                        <button className="do-pay" onClick={() => notice(work[i])}>취소/반품</button>
                      </div>
                    )
              }
            </li>
          </ul>

          <div className="work-item-mb" style={{height: '100%'}}>
            <div className="work-item-mb-container" style={{alignItems: 'center'}}>
              <div className="work-item-mb-content">
                <div className="work-item-mb__info" style={{marginBottom: 0, gap: '10rem'}}>
                  <img src={`${workMainImage}/${work[i].work_INFO.image}`} alt='' onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img>
                  <div className="work-item-mb__detail">
                    <h4>[ {json.time} ]</h4>
                    <h1 style={{ marginBottom: 0 }}>{json.title}</h1>
                    <h3 style={{ paddingLeft: 0, paddingTop: '0rem', color: '#222' }}>{json.artist}</h3>
                    <h3 style={{ paddingLeft: 0, paddingTop: '0rem', color: '#222' }}>{json.seller} ({json.phone})</h3>
                    <h3 style={{ paddingLeft: 0, marginTop: '25rem', color: '#000', fontSize: '14rem' }}>{json.price}</h3>
                  </div>
                </div>
                <div style={{ paddingTop: '16rem' }}>
                  <div>
                    {
                       json.state === '10' ? 
                       <button className="do-pay" onClick={() => getPresent(work[i])}>선물받기</button>
                       :
                       json.state === '11'?
                      <button className="done-pay">
                     수증<br />{json.complete}</button>
                       :
                      work[i].completion_TIME !== null ? (
                        <button className="done-pay">
                          {json.state !== '1' && json.complete}
                          {
                          json.state === '1' ? '' :
                          json.state === '2' ? " 구매확정" :
                          json.state === '3' ? " 반품요청" :
                          json.state === '4' ? " 반품거부" :
                          json.state === '5' ? " 반품승인" :
                          json.state === '6' ? " 환불완료" : ''
                          }</button>
                      ) :
                        work[i].work_INFO.transactionTb === null ?
                          <button className="bid-pay" onClick={() => pay(json)}>낙찰작품결제</button>
                          : (
                            <div style={{display: 'flex', gap: '10rem'}}>
                                <button className="do-pay" onClick={() => notice(work[i])}>취소/반품</button>
                                <button className="do-pay" onClick={() => action(work[i])}>구매확정</button>
                            </div>
                          )
                    }
                  </div>

                </div>
              </div>

            </div>


          </div>
        </div>
      )
    }

    setTableItem(result)

  }, [work])


  const tableSlideHeader = []
  tableSlideHeader.push(
    <ul className="container table-slider-header-container">
      <li>번호</li>
      <li>대표 이미지</li>
      <li>작가명</li>
      <li>작품명</li>
      <li>판매자정보</li>
      <li>구매/낙찰일자</li>
      <li>결제금액</li>
      <li>상태</li>
    </ul>
  )

  return (
    <>
      {
        pinModal &&
        <ModalPortal>
          <PinModal
            time={2}
            title={'간편패스워드 등록\n사용하실 간편패스워드를 입력해주세요.'}
            setModalOpen={setPinModal}
            setMyPin={setNewPin}
            afterAction={true}
            action={confirmed}
          />
        </ModalPortal>
      }
       {
        //선물확정
        presentModal &&
        <ModalPortal>
          <PinModal
            time={2}
            title={'간편패스워드 등록\n사용하실 간편패스워드를 입력해주세요.'}
            setModalOpen={setPresentModal}
            setMyPin={setNewPin}
            afterAction={true}
            action={confirmedPresent}
          />
        </ModalPortal>
      }
      {
        // 취소/환불 모달
        noticeModal && 
        <ModalPortal>
          <ReturnNoticeModal
          setModalOpen={setNoticeModal}
          callFunc={cancel}
          ></ReturnNoticeModal>
        </ModalPortal>
      }
      {
        // 배송완료 모달
        delModal && 
        <ModalPortal>
          <DeliveryReturnInfoModal
          setModalOpen={setDelModal}
          setData={setWork}
          work={work}
          data={data}
          ></DeliveryReturnInfoModal>
        </ModalPortal>
      }
      <div className="artist-wallet">
        <TableSlide
          slideHeader={tableSlideHeader}
          menu={menu}
          changeMenu={setMenu}
          nft={true}
          notLoading={true}
          // options={options}
          // items={menu === 0 ? tableItem : payItem}
          items={tableItem}
          rows={rows}
          breakRows={7}
          total={total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  )
}

export default ArtistWallet
