import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import './SignUpAuth.css'

import auth from '../../services/auth/auth'
import { emailCheck } from '../../hook/utils/validation'
import { SignupStepper } from './SignupStepper'

function SignUpAuth() {
  const navigate = useNavigate()

  const data = useLocation().state

  // const data = {
  //   token: 'token',
  //   next: '1',
  //   email: 'aksgml223@aver.com',
  //   phone: '01057039770',
  //   name: '이만희',
  //   type: 'LOCAL'
  // }

  const [email, setEmail] = useState(data.email)
  const [select, setSelect] = useState(0)
  const [emailLock, setEmailLock] = useState(false)
  const [code, setCode] = useState('')
  const [codeLock, setCodeLock] = useState(false)
  const [token, setToken] = useState("")


  const sendEmail = async() => {
    
    if(!emailCheck(email)){
      return
    }
    if(emailLock){
      return
    }
    setEmailLock(true)
    const res = await auth.sendEmail(email)
    if(res.resultCode) {
      alert("이메일로 보낸 인증코드를 입력해주세요.");
    }else{
      setEmailLock(false)
      switch(res.type){
        case 0:
          alert('이용제한된 이메일입니다.')
          break;
          case 1:
            alert("이미 가입한 이메일 입니다.");
            break;
            case 2: 
            alert("탈퇴된 이메일 입니다.\n일부 복구를 원할시, 관리자에게 문의해주세요.");
            break;
            default:
              break;
      }
    }
  }

  const checkCode = async() => {
    const fullEmail = email
    const res = await auth.checkCode(fullEmail, code)
    if(!emailLock){
      alert("먼저 이메일을 입력해주세요.")
      return
    }
    if(codeLock){
      alert('인증이 완료되었습니다.')
      return
    }
    if(res.resultCode){
      alert('이메일이 인증되었습니다.')
      setCodeLock(true)
      setToken(res.Email_token)
    }else{
      alert("인증번호를 확인해주세요.")
    }
  }

  const next = () => {
    const fullEmail = data.email === '' ? email : data.email;
    if(!emailLock || !codeLock){
      alert('이메일 인증을 먼저 해주세요.')
      return
    }
    

    navigate("/signUp/auth/phone", {
      state: {
        token: token,
        kyc: data.kyc,
        phone: data.phone,
        birth: data.birth,
        name: data.name,
        email: fullEmail,
        next: data.next,
        type: data.type
      },
    })

    return;
  }
  
  return (
    <div className="sign-auth">
      <Header />

      <section className="container sign-auth-container">
      <SignupStepper type={data?.type} active={1} />
        <div className="auth-list">
          <button className="auth-item" onClick={() => setSelect(0)}>
            <h1 className={select === 0 ? 'auth-select' : ''}>이메일 인증</h1>
            <span className={select === 0 ? 'auth-select' : ''}></span>
          </button>
        </div>
        <div className="auth-content">
          {select === 0 ? (
            <>
              <h2 className="email-title">이메일</h2>
              <div className="email-input-container">
                <input
                  autoComplete='off'
                  type="text"
                  className="email-input-num"
                  value={email}
                  readOnly={data.email !== ''}
                  onChange={e => setEmail(e.target.value)}
                  required></input>
                <button onClick={sendEmail}>인증하기</button>
              </div>
              <h2 className="email-title">인증번호</h2>
              <div className="email-input-container">
                <input
                  autoComplete='off'
                  type="text"
                  className="email-input-num"
                  value={code}
                  readOnly={codeLock || !emailLock}
                  onChange={e => setCode(e.target.value.toUpperCase())}
                  required></input>
                <button onClick={checkCode}>확인하기</button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="auth-next">
          <a href="/signUp">
            <button>이전</button>
          </a>
            <button onClick={next} className={codeLock ? "auth-next-button" : ''}>다음</button>
        </div>
      </section>
      <Footer />
    </div>
  )
}
export default SignUpAuth
