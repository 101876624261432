import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import '../../../../node_modules/slick-carousel/slick/slick.css'
import '../../../../node_modules/slick-carousel/slick/slick-theme.css'
import './BigTopArrow.css'
import WorkHeader from '../../WorkHeader/WorkHeader'
import MarketHeader from '../../../pages/Marketplace/Header/MarketHeader'
import { Link, useNavigate } from 'react-router-dom'
import { handleImgError } from '../../../services/imageRoute'
import publicWork from '../../../services/public/publicWork'
import { shallowEqual, useSelector } from 'react-redux'
import { KYCImageArtist } from '../../KYC/artist'
function BigTopArrow(props) {

  const navigate = useNavigate();
  const { login } = useSelector(v => v.auth, shallowEqual)

  const [rows, setRow] = useState(3)
  const [slidersPerRow, setSlidersPerRow] = useState(1)
  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,

    slidesToShow: rows,
    slidesToScroll: slidersPerRow,
    rows: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 480,
        settings: props.row2 ? {
          slidesToShow: props.breakRows ? props.breakRows : 1,
          slidesPerRow: props.breakSlidesPerRow ? props.breakSlidesPerRow : 2,
          arrows: true,
          rows: rows
        } : {
          slidesToShow: rows,
          slidesPerRow: slidersPerRow,
          arrows: true,
        },
      },
    ],
    // responsive: [
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       initialSlide: 2,
    //       slidesToShow: rows,
    //       slidesPerRow: slidersPerRow,
    //       arrows: true,
    //       infinite: true,
    //       rows: 1,
    //     },
    //   },
    // ],
  }

  // 리사이즈 이벤트를 감지하여 가로 길이에 따라 모바일 여부 결정
  const resizingHandler = () => {
    if (window.innerWidth <= 480) {
      setRow(props.breakRows ? props.breakRows : 2)
      setSlidersPerRow(props.breakSlidesPerRow ? props.breakSlidesPerRow : 1)
    }else{
      setRow(3)
      setSlidersPerRow(1)
    }
  }

  // 우선 맨 처음 480이하면 모바일 처리
  useEffect(() => {
    resizingHandler()
    window.addEventListener('resize', resizingHandler)
    return () => {
      // 메모리 누수를 줄이기 위한 removeEvent
      window.removeEventListener('resize', resizingHandler)
    }
  }, [])



  const setLike = async (data, setData, pk) => {
    if (!login) {
      alert('로그인이 필요한 서비스입니다.')
      navigate('/login', { replace: true })

      return
    }

    const res = await publicWork.setLike(pk)
    if (!res.resultCode) {
      return
    }

    setData(
      data.map(
        it => it.pk === pk
          ? { ...it, heart: res.message === 'ADD' }
          : it
      )
    )
  }
  const slider_items = items => {
    return items.map((item, i) => {
      return (
        <div key={item.pk} >
          <Link
            to={`${item.link}/${item.pk}`}
            className="big-top-item"
          >
            <div className="slider__item">
            <div style={{ position: 'relative', zIndex: 1 }}>
              <img src={item.info} alt="item" onError={handleImgError} onContextMenu={
                (e) => {
                    e.preventDefault();
                }
              }/>
              {
                  props.kyc && item.status === 2 && 
              <KYCImageArtist full={true} />
                }
                </div>
              <h3>
                {item.author} {item.author_e ? item.author_e : ''}{' '}
              </h3>
              <h2>{item.title !== undefined && item.title}</h2>
              <h4>{item.date !== undefined && item.date}</h4>
            </div>
          </Link>
          {item.price ? (
            <div className="market-item">
              <span className="market-item--price">{item.price}</span>
              <button href="#" className={item.heart ? "market-item--heart_active" : "market-item--heart"} onClick={() => setLike(props.items, props.setItems, item.pk)}>
                <span className="ir_pm">좋아요</span>
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      )
    })
  }
  return (
    <div className="big-top-arrow">
      {props.title_k && props.market ? (
        <MarketHeader title_e={props.title_e} title_k={props.title_k} />
      ) : props.title_k && !props.market ? (
        <WorkHeader title_e={props.title_e} title_k={props.title_k} />
      ) : (
        <></>
      )

      } 
      <section className="big-top-arrow-container" style={props?.i_style}>
      {/* <Slider {...settings} className="big-top-arrow-slider">
      {slider_items(props.items,props.hover)}
    </Slider> */}
        {props.items.length < 3 ?
        <div className='slick-slider big-top-arrow-slider' style={{display: 'flex'}}>
        {slider_items(props.items,props.hover)}
        </div>
      :  (<Slider {...settings} className="big-top-arrow-slider">
      {slider_items(props.items,props.hover)}
    </Slider>)}
       
      </section>
    </div>
  )
}
export default BigTopArrow
