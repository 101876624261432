import React from 'react'
import { Link } from 'react-router-dom'
import { handleImgError, workMainImage } from '../../services/imageRoute'
import WorkHeader from '../WorkHeader/WorkHeader'
import { useSelector, shallowEqual } from "react-redux";

import './ArtMoveDetail.css'
import { checkUseName } from '../../hook/utils/checkUseName';
import { artViewDate } from '../../hook/utils/artdbView';

function ArtMoveDetail(props) {

  const { lang } = useSelector(v => v.setting, shallowEqual)
  const [result, setResult] = React.useState([])
  const { items, type } = props;

  React.useEffect(() => {
    TableItem(items)
  }, [items])

  const TableItem = (items) => {
    const myItem = []

    if(items.length === 0){
      setResult(myItem)
      return
    }

    for (let i = 0; i < items.length; i++) {
      myItem.push(
        <Link
          key={i}
          to={`/artdb/work/${items[i].pk}`}
          className="art-md__list-item"
        >
          <img alt='' src={`${workMainImage}/${items[i].image}`} onError={handleImgError} onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img>
          {/* <h3>
          {checkUseName('ko', items[i].use_NAME ,items[i].ko_NAME, items[i].eng_NAME, items[i].ko_NICK_NAME, items[i].eng_NICK_NAME)}
              &nbsp;{checkUseName('en', items[i].use_NAME ,items[i].ko_NAME, items[i].eng_NAME, items[i].ko_NICK_NAME, items[i].eng_NICK_NAME)}
          </h3> */}
          {/* <h3>{items[i].user_TYPE === 1 ? checkUseName(lang, items[i].use_NAME ,items[i].ko_NAME, items[i].eng_NAME, items[i].ko_NICK_NAME, items[i].eng_NICK_NAME) : lang === 'ko' ? items[i].artist : items[i].eng_ARTIST}</h3> */}
          <h3>{lang === 'ko' ? items[i].artist : items[i].eng_ARTIST}</h3>

          <h2>{lang === 'ko' ? items[i].title : items[i].eng_TITLE}</h2>
          <h4> {artViewDate(items[i].start_CREATE, items[i].end_CREATE)}</h4>
        </Link>
      )
    }
    setResult(myItem)
  }

  return (
    <div className="art-md">
      <WorkHeader
        type={type}
        moreList={props.moreList}
        moreActive={props.moreActive}
        title_e={props.title_e}
        title_k={props.title_k}
        move={props.moveTo ? props.moveTo : '/artdb/work/detail'}
        items={props.items}
        options={props.options}
        breakRows={props.breakRows}
        breakSlidersPerRow={props.breakSlidersPerRow}
        moreMenu={props.moreMenu}
        moreListChange={props.moreListChange}
      ></WorkHeader>
      <section className="art-md--content">
        <div className="container">
          <section className="art-md--content__list">
            {props.RepresentingItems
              ? props.RepresentingItems
              : result }
          </section>
        </div>
      </section>
    </div>
  )
}
export default ArtMoveDetail
