import React, { useState } from 'react'
import DropDown from '../../../../components/DropDown/DropDown'
import Footer from '../../../../components/Footer/Footer'
import Header from '../../../../components/Header/Header'
import HeaderSmall from '../../../../components/Header/HeaderSmall/HeaderSmall'
import CheckModal from '../../../../components/Modal/CheckModal'
import ModalPortal from '../../../../components/Modal/ModalPortal'
import SectionHeader from '../../../../components/SectionHeader/SectionHeader'
import './GeneralProfile.css'
import question from '../../../../assets/question-line.png'

import auth from '../../../../services/auth/auth'
import { useDispatch } from 'react-redux'
import * as authAction from '../../../../store/reducers/auth/actions'
import { useSelector, shallowEqual } from 'react-redux'
import { handleImgError, userImage } from "../../../../services/imageRoute";
import { useNavigate } from "react-router-dom";
import { checkUserImageSize } from '../../../../hook/utils/fileSize'
import profile from '../../../../services/auth/profile'
import AddressModal from '../../../../components/Modal/AddressModal'
import { LoadingState } from '../../../../context/loadingStateProvider'
import SignModal from '../../../../components/Modal/SignModal'
import Tooltip from '../../../../components/Tooltip'
import { handleInputMaxLength } from '../../../../hook/utils/validation'

function GeneralProfile() {
  const loadingState = React.useContext(LoadingState);

  const ko_pattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; //한글
  const en_pattern = /[a-zA-Z]/; //영어

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
    React.useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    });
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { artist } = useSelector(v => v.auth, shallowEqual)
  const { countryList } = useSelector(v => v.setting, shallowEqual)

  const [picture, setPicture] = React.useState(artist.picture === null ? null : `${userImage}/${artist.picture}`)
  const [pictureShow, setPictureShow] = React.useState(artist.picture === null ? null : `${userImage}/${artist.picture}`)
  
  const [koFirstName, setKoFirstName] = React.useState(artist.first_NAME);
  const [koLastName, setKoLastName] = React.useState(artist.name);
  const [enFirstName, setEnFirstName] = React.useState(artist.first_ENG_NAME);
  const [enLastName, setEnLastName] = React.useState(artist.eng_NAME);


  const [koNickname, setKoNickname] = React.useState(artist.nickname)
  const [enNickname, setEnNickname] = React.useState(artist.eng_NICKNAME)
  const [country, setCountry] = React.useState(artist.country)
  const [birthday, setBirthday] = React.useState(artist.birthday)
  const [localNumber, setLocalNumber] = React.useState(artist.location_NUMBER);
  const [phone, setPhone] = React.useState(artist.phone_NUMBER)
  const [useName, setUseName] = React.useState(null)
  const [addressOpen, setAddressOpen] = useState(false)

  const [address, setAddress] = React.useState(artist.address)
  const [addressDetail, setAddressDetail] = React.useState(artist.address_DETAIL)

  const [uEmail, setUEmail] = React.useState(artist.add_EMAIL)
  const [sns, setSns] = React.useState(artist.sns)
  const [introduce, setIntroduce] = React.useState(artist.introduce)
  const [sign, setSign] = React.useState(null)

  const [withdrawalModal, setWithdrawalModal] = React.useState(false)
  const [kycInfo, setKycInfo] = React.useState(null)

  const [accountCompany, setAccountCompany] = React.useState()
  const [accountNumber, setAccountNumber] = React.useState()
  const [accountOwner, setAccountOwner] = React.useState()

  const saveImgFile = e => {
    e.preventDefault();
    const fileList = e.target.files
    const file = fileList[fileList.length - 1]

    const check = checkUserImageSize(file.size)
    if (!check) return

    setPicture(file);
    setPictureShow(URL.createObjectURL(file));
  }

  const withdrawalUser = async () => {
    loadingState.setText('잠시만 기다려주세요.')
    loadingState.setOpen(true)

    const res = await auth.withdrawalUser()

    if(!res.resultCode){
      if(res.message === 'rental or transaction'){
        alert('진행 중인 거래가 있습니다.\n완료 후 탈퇴를 진행해 주세요.')
        loadingState.setOpen(false)
        loadingState.setText('')
        return;
      }
      alert('관리자에게 문의해주세요.')
      loadingState.setOpen(false)
      loadingState.setText('')
      return
    }

    localStorage.removeItem('QART_ACCESS')
    dispatch(authAction.LOGOUT())
    alert('회원탈퇴를 완료했습니다.')
    
    loadingState.setOpen(false)
    loadingState.setText('')
    navigate('/', {replace: true})
  }


  const moreList = {
    menu: [
      {
        title: '프로필',
        link: '/mypage/general',
      },
      {
        title: 'My Archive',
        link: '/mypage/general/archive',
      },
      {
        title: '구매목록',
        link: '/mypage/general/wallet',
      },
      {
        title: '관심목록',
        link: '/mypage/general/like',
      },
      // {
      //   title: '거래하기',
      //   link: '/mypage/general/trade',
      // },
      {
        title: '알림',
        link: '/mypage/general/notice',
      },
      {
        title: '멤버십',
        link: '/mypage/general/membership',
      }
      
    ],
  }

  const [edit, setEdit] = useState(true)
  const infoList = ['본명으로 활동(Real Name)', '콜렉션명으로 활동(Collection Name)']
  const phonelist = ['+82', '+82', '+82', '+82', '+82', '+82']


    // 서명 모달창 노출 여부 state
    const [signOpen, setSignOpen] = useState(false)

    // 서명 모달창 노출
    const showSignModal = () => {
      setSignOpen(!signOpen)
    }

  // 모달창 노출 여부 state
  const [checkOpen, setCheckOpen] = useState(false)

  // 모달창 노출
  const showCheckModal = () => {
    setCheckOpen(true)
  }

  React.useEffect(() => {
    getInfo()
  }, [])

  const getInfo = async () => {
    const res = await auth.getArtistInfo()
      setKycInfo(res.kyc)
      setAccountCompany(res.kyc?.account_COMPANY)
      setAccountNumber(res.kyc?.account_NUMBER)
      setAccountOwner(res.kyc?.name)
      dispatch(authAction.SET_ARTIST_INFO(res.data))
  }

  const editInfo = async () => {
    
    const res = await auth.editGeneralInfo(
      artist.picture,
      picture === null ? '' : `${userImage}/${artist.picture}` === picture ? artist.picture : picture,
      koFirstName, koLastName, enFirstName, enLastName,
      koNickname,
      enNickname,
      useName === null ? artist.use_NAME : infoList.indexOf(useName),
      country,
      birthday,
      localNumber,
      phone,
      sign,
      0,
      sns,
      introduce,
      address,
      addressDetail,
      uEmail,
      accountCompany,
      accountNumber,
      accountOwner
    )
    if (!res.resultCode) {
      alert('저장에 실패했습니다.')
      return
    }
    setEdit(true)
    getInfo()
    // setPicture(null)
  }

  return (
    <div
      className={
        edit ? 'mypage-general' : 'mypage-general mypage-general-profile'
      }
    >
      <Header login={true} colored="black" />
      <nav className="search">
        <div className="container search__container">
          <h2 className="search__title">마이페이지</h2>
        </div>
      </nav>
      <HeaderSmall moreList={moreList} active={true} choice={0} />
      {edit ? <></> : <SectionHeader title="프로필 수정" />}
      <div className="general-profile gallery-profile">
        <section
          className={
            edit
              ? 'container g-profile-container cant-edit'
              : 'container g-profile-container'
          }
        >
          {edit ? (
            <div className="ap-edit-header">
              <div className="ap-edit-header-mb">
                <h1>프로필 관리</h1>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {/* <button onClick={() => showQrModal()}>QR 발급</button> */}
                  <button onClick={() => setEdit(false)}>수정하기</button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="ap-edit-content">
            {/*<div className="ap-edit-image">*/}
            {/*  <h2>프로필</h2>*/}
            {/*  <img*/}
            {/*    src={require('../../../../qart_test/김홍도 Kim, Hongdo, 대한민국, 1745-1806경.jpg')}*/}
            {/*    alt=""*/}
            {/*  ></img>*/}
            {/*  {edit ? <></> : <button className="edit-photo"></button>}*/}
            {/*</div>*/}
            <div className="ma-edit-image">
              <div className="container ma-edit-image-container" style={{ border: 'none' }}>
                <div className="main-image-container">
                  <img
                    className={picture ? 'ma-img-show' : 'ma-img-hide'}
                    style={edit ? { display: 'block' } : {}}
                    src={pictureShow ?? ''}
                    onError={handleImgError}
                    alt="이미지"
                    onContextMenu={
                      (e) => {
                          e.preventDefault();
                          
                      }
                    }
                  />
                  {edit ? <></> :
                    <button
                      className={picture ? 'edit-photo' : 'edit-photo ma-img-hide'}
                      onClick={() => { setPicture(null); setPictureShow(null); }}

                    ></button>
                  }
                  <form method="post" encType="multipart/form-data">
                    <div className="button">
                      <label className={edit ? "" : "image-button"} htmlFor="mainImage"></label>
                    </div>
                    <input
                      type="file"
                      id="mainImage"
                      name="mainImage"
                      accept="image/*"
                      onChange={saveImgFile}
                      style={{ display: 'none' }}
                    />
                  </form>
                </div>
              </div>
            </div>
            <ul className="g-profile-list">
              <li className="has-2-input">
                <h2>이름 (실명)</h2>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                <span>성</span>
                <input
                  type="text"
                  className="a-profile-input"
                  value={koFirstName}
                  // placeholder='성'
                  // readOnly={ko_pattern.test(artist.name)}
                  onChange={e => setKoFirstName(e.target.value)}
                  disabled={edit}
                ></input>
                </div>
                <div style={matches ? {marginTop: '10rem', display: 'flex', flexDirection: 'column'} : {display: 'flex', flexDirection: 'column'}}>
                <span>이름</span>
                <input
                  type="text"
                  className="a-profile-input"
                  value={koLastName}
                  // placeholder='이름'
                  // readOnly={en_pattern.test(artist.eng_NAME)}
                  onChange={e => setKoLastName(e.target.value)}
                  disabled={edit}
                ></input>
                </div>
              </li>
              <li className="has-2-input">
                <h2>Name</h2>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <span>Family Name</span>
                <input
                  type="text"
                  className="a-profile-input"
                  value={enFirstName}
                  // placeholder='Family Name'
                  onChange={e => setEnFirstName(e.target.value)}
                  disabled={edit}
                ></input>
                </div>
                <div style={matches ? {marginTop: '10rem', display: 'flex', flexDirection: 'column'} : {display: 'flex', flexDirection: 'column'}}>
                <span>Given Name</span>
                <input
                  type="text"
                  className="a-profile-input"
                  value={enLastName}
                  // readOnly={en_pattern.test(artist.eng_NAME)}
                  // placeholder='Given Name'
                  onChange={e => setEnLastName(e.target.value)}
                  disabled={edit}
                ></input>
                </div>
              </li>
              <li className="has-2-input">
                <h2>콜렉션명 (Collection Name)</h2>
                <input
                  type="text"
                  className="a-profile-input"
                  value={koNickname}
                    onChange={e => setKoNickname(e.target.value)}
                  disabled={edit}
                ></input>
                <input
                  type="text"
                  className="a-profile-input"
                  value={enNickname}
                    onChange={e => setEnNickname(e.target.value)}
                  disabled={edit}
                ></input>
              </li>
               <li className="ap-edit-drop">
                  <h2>콜렉션 정보 노출 (Please set your name to use for Qart)</h2>
                  <DropDown
                    disabled={edit}
                    options={infoList}
                    select={
                      useName === null
                        ? artist.use_NAME
                        : infoList.indexOf(useName)
                    }
                    setValue={setUseName}
                  />
                </li>
                </ul>
            <ul className="g-profile-list">
              <li className="ap-edit-drop">
                <h2>국적 (Nationality)</h2>
                <DropDown
                    disabled={edit}
                    options={countryList}
                    select={
                      countryList.indexOf(country)
                    }
                    setValue={setCountry}
                  />
              </li>
              <li>
                <h2>생년월일 (Birth Date)</h2>
                <input
                  // type={edit ? 'text' : 'date'}
                  type="text"
                  className="a-profile-input date-input"
                  value={birthday}
                  readOnly
                  onChange={e => setBirthday(e.target.value)}
                  disabled={edit}
                ></input>
              </li>
              <li>
                <h2>핸드폰 번호 (Contact Number)</h2>
                <div className="gp-drop">
                  <input
                    type="text"
                    className="a-profile-input__local-number"
                    value={localNumber}
                    onChange={e => setLocalNumber(e.target.value)}
                    placeholder='+82'
                    disabled={edit}
                  ></input>
                   <input
                    type="text"
                    className="a-profile-input__number"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    // maxLength={13}
                    disabled={edit}
                  ></input>
                </div>
              </li>
              
                
                <li>
                  <h2>KYC 인증 여부</h2>
                  {edit ? (
                    <input
                      type="text"
                      className="a-profile-input"
                      placeholder={
                        artist.status === 0
                          ? '미인증'
                          : artist.status === 1
                            ? '인증 대기'
                            : '인증 완료'
                      }
                      disabled={true}
                    ></input>
                  ) : (
                    <div className="ap-drop">
                      <input
                        type="text"
                        className="a-profile-input__number"
                        placeholder={
                          artist.status === 0
                            ? '미인증'
                            : artist.status === 1
                              ? '인증 대기'
                              : '인증 완료'
                        }
                        disabled={true}
                      ></input>

                      <button
                        className="drop-button"
                        style={{ marginLeft: '12rem', marginRight: '0px' }}
                        onClick={() => navigate("/cert/kyc")}
                      >
                        {artist.status === 2 ? '재인증': '인증'}
                      </button>
                    </div>
                  )}
                </li>
            </ul>
            {artist.status === 2 &&
              <ul className="ap-edit-bank">
              <div className="ap-edit-content-mb">
              <li>
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '10rem'}}>
                      <h2 style={{position: 'relative'}}>계좌번호</h2>
                      {/* {!edit &&<Tooltip
                      text={'변경을 원할시, KYC인증을 다시해주세요.'}>
                        <img src={question} alt='' className='a-profile-bank-question'/>
                      </Tooltip>} */}
                      </div>
                      <div className="ap-drop">
                        {/* <DropDown disabled={edit} options={banklist} /> */}
                        <input
                          type="text"
                          className="a-profile-input__bank"
                          value={accountCompany ? accountCompany : ''}
                          placeholder="-"
                          onChange={(e) => setAccountCompany(e.target.value)}
                          disabled={edit}
                        ></input>
                        <input
                          type="text"
                          value={accountNumber ? accountNumber : ''}
                          className="a-profile-input__bank_num"
                          placeholder="-"
                          onChange={(e) => setAccountNumber(e.target.value)}
                          disabled={edit}
                        ></input>
                      </div>
                    </li>
                <li>
                  <h2>소유주</h2>
                  <input
                    type="text"
                    value={accountOwner ? accountOwner : ''}
                    className="a-profile-input"
                    placeholder="-"
                    onChange={(e) => setAccountOwner(e.target.value)}
                    disabled={edit}
                  ></input>
                </li>
              </div>
            </ul>}
            <ul className={matches ? "ap-edit-personal" : "ap-edit-personal gp-edit-basic"}>
              <div className="ap-edit-content-mb">
              <li className="has-2-input">
                  <h2>주소</h2>
                  <div className="search__block">
                    <input
                      type="text"
                      value={address}
                      className="search__block"
                      disabled={edit}
                      readOnly
                    ></input>
                    <button className="search__block__button" onClick={() => !edit ? setAddressOpen(true) : null}>
                      <span className="ir_pm">검색</span>
                    </button>
                  </div>
                  <input
                    type="text"
                    value={addressDetail}
                    onChange={e => setAddressDetail(e.target.value)}
                    className="a-profile-input"
                    disabled={edit}
                  ></input>
                </li>

                {addressOpen && (
                  <ModalPortal>
                    <AddressModal setModalOpen={setAddressOpen} setAddress={setAddress} />
                  </ModalPortal>
                )}


                <li>
                  <h2>이메일</h2>
                  <input
                    type="text"
                    className="a-profile-input"
                    value={uEmail}
                    onChange={e => setUEmail(e.target.value)}
                    disabled
                  ></input>
                </li>
                <li>
                  <h2>웹 / SNS</h2>
                  <input
                    type="text"
                    className="a-profile-input"
                    value={sns}
                    maxLength={230}
                    onChange={e => handleInputMaxLength(setSns, e)}
                    disabled={edit}
                    placeholder={edit ? `${sns === null || sns === '' ? '-' : sns}` : '여러개 입력시, 구분자 ; 를 사용해주세요.'}
                  ></input>
                </li>
              </div>
            </ul>
            <ul className="ap-edit-intro">
              <li>
                <h2>소개(글자수는 최대 1000자로 제한됩니다.)</h2>
                <div className='content-text'>
                    <textarea
                      value={introduce}
                      onChange={e => handleInputMaxLength(setIntroduce, e)}
                      maxLength={1000}
                      readOnly={edit}></textarea>
                      {!edit && 
                        <span>{introduce === null ? '0/1000' : `${introduce.length}/1000`}</span>
                      }
                    {/* <span>{content.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length}/2000</span> */}
                  </div>
              </li>
            </ul>
          </div>
          {/* <div style={{width: '100%'}}>
              <span className='withdrawal' style={{marginBottom:20}} onClick={() => setWithdrawalModal(true)}>  탈퇴하기 </span>
            </div> */}
          {edit ? (
            <></>
          ) :
            (
              <section className="g-profile-button">
                <button onClick={showCheckModal}>저장하기</button>
                {checkOpen && (
                  <ModalPortal>
                    <CheckModal
                      setModalOpen={setCheckOpen}
                      setFunc={editInfo}
                      title={'저장하기'}
                      content={'프로필 저장 완료하시겠습니까?'}
                      isAuth={true}
                    />
                  </ModalPortal>
                )}
                {signOpen && (
                <ModalPortal>
                  <SignModal setModalOpen={setSignOpen} setFunc={setSign} />
                </ModalPortal>
              )}
              </section>
            )
          }
          
          {!edit &&
            <div style={{width: '100%'}}>
              <span className='withdrawal' onClick={() => setWithdrawalModal(true)}>탈퇴하기</span>
              <span className='withdrawal-sub'>*회원 탈퇴시 기존의 자료가 삭제됩니다.</span>
            </div>
          }

          {withdrawalModal &&
            <CheckModal
              setModalOpen={setWithdrawalModal}
              setFunc={withdrawalUser}
              title={'회원탈퇴'}
              content={'회원 탈퇴와 동시에 등록된 모든 정보와 자료는 삭제됩니다.\n복구가 불가하니 신중히 선택해주세요. 정말 탈퇴하겠습니까?'}
            />
          }
        </section>
      </div>
      <Footer />
    </div>
  )
}
export default GeneralProfile
