import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Footer from '../../../../components/Footer/Footer'
import Header from '../../../../components/Header/Header'
import SectionHeader from '../../../../components/SectionHeader/SectionHeader'
import { LoadingState } from '../../../../context/loadingStateProvider'
import excel from '../../../../services/myArchive/excel'
import './AddWorkToExcel.css'

function AddWorkToExcel() {
  const { type } = useSelector(v => v.auth, shallowEqual)
  // const type = 2
  const loadingState = React.useContext(LoadingState);

  const navigate = useNavigate()
  const [fileName, setFileName] = useState('')
  const [excelFile, setExcelFile] = useState(null)
  const changeFile = e => {
    setFileName(e.target.files[0].name)
    setExcelFile(e.target.files[0])
  }


  const saveWork = async() => {
    if(excelFile === null){
      alert('엑셀 파일을 추가해주세요.')
      return
    }

    loadingState.setText('엑셀파일로 작품추가중입니다.\n잠시만 기다려주세요.')
    loadingState.setOpen(true)

    let res;

    if(type === 1){
      res = await excel.ownImportArtist(excelFile)
    }else{
      res = await excel.ownImportGallery(excelFile)
    }

    console.log(res)
    if(!res.resultCode){
      if(res.message === 'over'){
        alert(`${res.membership}으로는 ${res.count}개의 작품을 추가할 수있습니다.\n${type ===0 ? '콜렉션' : type === 1 ? '작가' : '기관'}님은 총 ${res.count - res.remain}개의 작품을 등록하셨습니다.\n액셀로 ${res.remain}개까지 추가가능합니다.\n추가등록을 원하시면, 맴버십 업그레이드를 해주세요.`);
        loadingState.setOpen(false)
        loadingState.setText('')
        return
      }
      alert('작품 추가를 실패했습니다.\n관리자에게 문의해주세요.')
      loadingState.setOpen(false)
      loadingState.setText('')
      return
    }

    setExcelFile(null)
    setFileName('')
    alert('작품 추가에 성공했습니다.')
    loadingState.setOpen(false)
    loadingState.setText('')
    navigate(-1)
  }

  return (
    <div className="ma-excel-add">
      <Header login={true} colored="black" detail={true} />
      <SectionHeader title={'Add Work to Excel'} />
      <div className="ma-excel-content">
        <div className="container">
          <div className="excel-item-01">
            <h2>엑셀 파일 업로드</h2>
            <div className="filebox">
              <label for="file">파일 업로드</label>
              <input 
                type="file"
                id="file"
                accept=".xlsx"
                onChange={changeFile} />
              <input className="upload-name" value={fileName} readOnly />
            </div>
          </div>
          <div className="excel-item-02">
            <h2>양식 참고사항</h2>
            <div className="excel-info">
            <h3>
            <a href={type === 0 ? '/excel/workAddExcelForGeneral.xlsx' : type === 1 ? '/excel/workAddExcelForArtist.xlsx' : '/excel/workAddExcelForGallery.xlsx'}>
              엑셀 양식 파일.xlsx
            </a>
            </h3>
              <p>
                {type !== 1 && 
                <>
                <span># 작가 생일 ex) 미상 / 2000-01-02</span>
                <br></br>
                </>
                }
                # 사이즈 ex) 10x20 / 10x20x30 / 1x20x30(시간일때 자동으로 1h20m30s) <br></br>
                # 제작 연도 ex) 2000-01-02 / 2000 / 공백 <br></br>
                # 에디션 ex) 없을시, '1/1' 존재할 경우 '에디션 번호/총 발행 수' <br></br><br></br>
                <span style={{color: '#000', fontWeight: 600}}>
                ※ 맴버십에 따라 저장가능한 작품수까지만 저장이 가능합니다. <br></br>
                </span>
                <span style={{color: '#000', fontWeight: 600}}>
                ※ 2번째 줄은 예시 문장입니다. 추가시 삭제 후 진행해주세요. <br></br>
                </span>
                <span style={{color: '#000', fontWeight: 600}}>
                ※ 양식에 맞지 않게 입력할 시 저장에 실패할 수 있습니다.<br></br>
                </span>
                <span style={{color: '#000', fontWeight: 600}}>
                ※ 이미지는 작품 수정으로 각각 넣어줘야합니다.<br></br>
                </span>
                <span style={{color: '#000', fontWeight: 600}}>
                ※ 해당 프로세스 사용시, 가격은 "0원", 아트아카이브 "비공개"로 설정됩니다.<br></br>
                </span>
              </p>
            </div>
          </div>
          <div className="ma-add--button">
            <button onClick={() => navigate(-1)}>취소</button>
            <button onClick={saveWork}>저장</button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default AddWorkToExcel
