import { add } from 'date-fns';
import client from '../index'

/**
  이메일 인증
   * @param {String} token 
*/
const naverCallback = async(token) => {
    const res = await client.json.get('/user/login/naver', {headers: {'naver-token': token}});
    // 이미 존재하는 메일일 경우
    // message로 already exist email 에러 
    return res.data;
}


/**
  이메일 인증
   * @param {String} email 이메일
*/
const sendEmail = async(email) => {
    const postData = {
        EMAIL: email
    };
    const res = await client.json.post('/api/user/register/sendmail', postData);
    // 이미 존재하는 메일일 경우
    // message로 already exist email 에러 
    return res.data;
}


/**
  이메일 인증 확인
   * @param {String} email 이메일
   * @param {String} code 인증코드
*/
const checkCode = async(email, code, token) => {
    const postData = {
        EMAIL: email,
        CODE: code
    };
    const res = await client.json.put('/api/user/register/sendmail', postData);
    return res.data;
}

/**
  아이디 찾기 - 코드 확인
  * @param {String} phone 휴대폰번호
   * @param {String} code 인증코드
*/
const checkMyEmail = async(phone, code) => {
    const postData = {
        auth_code: code,
        phone_number: phone
    };
    const res = await client.json.post('/user/email', postData);
    return res.data;
}


/**
  비밀번호 찾기 - 이메일 요청
   * @param {String} email 이메일
*/
const sendEmailPassword = async(email) => {
    const postData = {
        email: email
    };
    const res = await client.json.put('/user/password', postData);
    // 이미 존재하는 메일일 경우
    // message로 already exist email 에러 
    return res.data;
}


/**
  비밀번호 찾기 - 코드 확인
   * @param {String} email 이메일
   * @param {String} code 인증코드
*/
const checkCodePassword = async(email, code) => {
    const postData = {
        email: email,
        code: code
    };
    const res = await client.json.post('/user/password', postData);
    return res.data;
}

/**
  비밀번호 찾기 - 비밀번호 적용
   * @param {String} token checkCodePassword 에서 받은 토큰
   * @param {String} password 사용할 패스워드
*/
const changePassword = async(token, password) => {
    const postData = {
        password: password
    };
    const res = await client.json.patch('/user/password', postData, { headers: { 'find-token': token } });
    return res.data;
}



/**
  SMS 인증
   * @param {String} locale 국가번호
   * @param {String} phone 폰 번호
*/
const sendSMS = async(locale, phone) => {
    const postData = {
        country_number: locale,
        phone_number: phone
    };
const res = await client.json.post('/user/smsAuth/send', postData, { headers: { 'x-access-token': 'QART_SMS' } });
    return res.data;
}


/**
  SMS 인증 확인
   * @param {String} locale 국가번호
   * @param {String} phone 폰 번호
   * @param {String} code 인증코드
*/
const checkSMS = async(locale, phone, code) => {
    const postData = {
        country_number: locale,
        phone_number: phone,
        auth_code: code
    };
    const res = await client.json.post('/user/smsAuth/check', postData, { headers: { 'x-access-token': 'QART_SMS' } });
    return res.data;
}





/**
  일반 회원가입
   * @param {String} token 이메일 인증 후 받은 토큰
   * @param {String} password 비밀번호
   * @param {String} ko_first_name 한글 성
   * @param {String} ko_last_name 한글 이름
   * @param {String} en_first_name 영어 성
   * @param {String} en_last_name 영어 이름
   * @param {String} phone_num 폰 번호
   * @param {String} country_phone_num 국가 번호
   * @param {String} birthday 생일
   * @param {String} kyc kyc인증 토큰
*/
const generalSignUp = async(token, password, ko_first_name, ko_last_name, en_first_name, en_last_name, phone_num, country_phone_num, birthday, kyc) => {

    // let koName;
    // let enName;

    // if(ko_name === '' || en_name === ''){
    //     if(ko_name === ''){
    //         koName = en_name;
    //         enName = en_name;
    //     }

    //     if(en_name === ''){
    //         koName = ko_name;
    //         enName = ko_name;
    //     }
    // }else{
    //     koName = ko_name;
    //     enName = en_name;
    // }


    const postData = {
        PASSWORD: password,
        FIRST_KOR_NAME: ko_first_name,
        KOR_NAME: ko_last_name,
        FIRST_ENG_NAME: en_first_name,
        ENG_NAME: en_last_name,
        PHONE_NUM: phone_num,
        COUNTRY_NUMBER: country_phone_num,
        BIRTHDAY: birthday,
        KYC: kyc
    };
    const res = await client.json.post('/api/user/register/general', postData, { headers: { 'x-access-token': token } });
    return res.data;
}

/**
    oauth 일반 회원가입
   * @param {String} email 이메일
   * @param {String} platform 로그인 플랫폼
   * @param {String} ko_first_name 한글 성
   * @param {String} ko_last_name 한글 이름
   * @param {String} en_first_name 영어 성
   * @param {String} en_last_name 영어 이름
   * @param {String} phone_num 폰 번호
   * @param {String} country_phone_num 국가 번호
   * @param {String} birthday 생일
   * @param {String} kyc kyc인증 토큰
*/
const generalOauthSignUp = async(email, platform, ko_first_name, ko_last_name, en_first_name, en_last_name, phone_num, country_phone_num, birthday, kyc) => {

    // let koName;
    // let enName;

    // if(ko_name === '' || en_name === ''){
    //     if(ko_name === ''){
    //         koName = en_name;
    //         enName = en_name;
    //     }

    //     if(en_name === ''){
    //         koName = ko_name;
    //         enName = ko_name;
    //     }
    // }else{
    //     koName = ko_name;
    //     enName = en_name;
    // }


    
    const postData = {
        EMAIL: email,
        PLATFORM: platform,
        FIRST_KOR_NAME: ko_first_name,
        KOR_NAME: ko_last_name,
        FIRST_ENG_NAME: en_first_name,
        ENG_NAME: en_last_name,
        PHONE_NUM: phone_num,
        COUNTRY_NUMBER: country_phone_num,
        BIRTHDAY: birthday,
        KYC: kyc
    };
    const res = await client.json.post('/api/user/register/simple/general', postData);
    return res.data;
}





/**
  작가 회원가입
   * @param {String} token 이메일 인증 후 받은 토큰
   * @param {String} password 비밀번호
   * @param {String} ko_first_name 한글 성
   * @param {String} ko_last_name 한글 이름
   * @param {String} en_first_name 영어 성
   * @param {String} en_last_name 영어 이름
   * @param {String} ko_nickname 한글 닉네임
   * @param {String} en_nickname 영어 닉네임
   * @param {String} birthday 생일
   * @param {String} phone_num 폰 번호
   * @param {String} country_phone_num 국가 번호
   * @param {String} country 국가
   * @param {String} kyc kyc인증 토큰
*/
const artistSignUp = async(token, password, ko_first_name, ko_last_name, en_first_name, en_last_name, ko_nickname, en_nickname, birthday, phone_num, country_phone_num, country, kyc) => {
    // let koName;
    // let enName;

    // if(ko_name === '' || en_name === ''){
    //     if(ko_name === ''){
    //         koName = en_name;
    //         enName = en_name;
    //     }

    //     if(en_name === ''){
    //         koName = ko_name;
    //         enName = ko_name;
    //     }
    // }else{
    //     koName = ko_name;
    //     enName = en_name;
    // }

    const postData = {
        PASSWORD: password,
        // KOR_NAME: koName,
        // ENG_NAME: enName,
        FIRST_KOR_NAME: ko_first_name,
        KOR_NAME: ko_last_name,
        FIRST_ENG_NAME: en_first_name,
        ENG_NAME: en_last_name,
        KOR_NICK_NAME: ko_nickname,
        ENG_NICK_NAME: en_nickname,
        BIRTHDAY: birthday,
        PHONE_NUM: phone_num,
        COUNTRY: country,
        COUNTRY_NUM: country_phone_num,
        KYC: kyc
    }
    const res = await client.json.post('/api/user/register/artist', postData, { headers: { 'x-access-token': token } })
    return res.data
}

/**
  oauth 작가 회원가입
   * @param {String} email  이메일
   * @param {String} platform 로그인 플랫폼
   * @param {String} ko_first_name 한글 성
   * @param {String} ko_last_name 한글 이름
   * @param {String} en_first_name 영어 성
   * @param {String} en_last_name 영어 이름
   * @param {String} ko_nickname 한글 닉네임
   * @param {String} en_nickname 영어 닉네임
   * @param {String} birthday 생일
   * @param {String} phone_num 폰 번호
   * @param {String} country_phone_num 국가 번호
   * @param {String} country 국가
   * @param {String} kyc kyc인증 토큰
*/
const artistOauthSignUp = async(email, platform, ko_first_name, ko_last_name, en_first_name, en_last_name, ko_nickname, en_nickname, birthday, phone_num, country_phone_num, country, kyc) => {
    // let koName;
    // let enName;

    // if(ko_name === '' || en_name === ''){
    //     if(ko_name === ''){
    //         koName = en_name;
    //         enName = en_name;
    //     }

    //     if(en_name === ''){
    //         koName = ko_name;
    //         enName = ko_name;
    //     }
    // }else{
    //     koName = ko_name;
    //     enName = en_name;
    // }


    const postData = {
        PLATFORM: platform,
        // KOR_NAME: koName,
        // ENG_NAME: enName,
        FIRST_KOR_NAME: ko_first_name,
        KOR_NAME: ko_last_name,
        FIRST_ENG_NAME: en_first_name,
        ENG_NAME: en_last_name,
        KOR_NICK_NAME: ko_nickname,
        ENG_NICK_NAME: en_nickname,
        BIRTHDAY: birthday,
        PHONE_NUM: phone_num,
        COUNTRY: country,
        COUNTRY_NUM: country_phone_num,
        KYC: kyc,
        EMAIL: email
    }
    const res = await client.json.post('/api/user/register/simple/artist', postData)
    return res.data
}





/**
  기관 회원가입
   * @param {String} token 이메일 인증 후 받은 토큰
   * @param {String} ko_name 유저 한글 이름
   * @param {String} en_name 유저 영어 이름
   * @param {String} phone_num 핸드폰 번호
   * @param {String} country_phone_num 국가 번호
   * @param {String} birthday 생일
   * @param {String} password 비밀번호
   * @param {String} agency_ko_name 기관 한국 이름
   * @param {String} agency_en_name 기관 영어 이름
   * @param {String} since 설립연원일
   * @param {String} address 기관 주소
   * @param {String} addressDetail 기관 상세 주소 
   * @param {String} contact1 기관 연락처1
   * @param {String} contact2 기관 연락처2
   * @param {String} manager_mail 기관 이메일
   * @param {String} home_page 기관 홈페이지 주소
   * @param {File} image 이미지
   * @param {String} kyc kyc인증 토큰
*/
const agencySignUp = async(token, ko_name, en_name, phone_num, country_phone_num, birthday, password, agency_ko_name, agency_en_name, since, address, addressDetail, contact1, contact2, manager_mail, home_page, image, kyc) => {

    // let koName;
    // let enName;
    // let agencyKoName;
    // let agencyEnName;

    // if(ko_name === '' || en_name === ''){
    //     if(ko_name === ''){
    //         koName = en_name;
    //         enName = en_name;
    //     }

    //     if(en_name === ''){
    //         koName = ko_name;
    //         enName = ko_name;
    //     }
    // }else{
    //     koName = ko_name;
    //     enName = en_name;
    // }


    // if(agency_ko_name === '' || agency_en_name === ''){
    //     if(ko_name === ''){
    //         agencyKoName = agency_en_name;
    //         agencyEnName = agency_en_name;
    //     }

    //     if(en_name === ''){
    //         agencyKoName = agency_ko_name;
    //         agencyEnName = agency_ko_name;
    //     }
    // }else{
    //     agencyKoName = agency_ko_name;
    //     agencyEnName = agency_en_name;
    // }

    const formData = new FormData();
    formData.append("KOR_NAME", ko_name);
    formData.append("ENG_NAME", en_name);
    formData.append("PHONE_NUM", phone_num);
    formData.append("COUNTRY_NUMBER", country_phone_num);
    formData.append("BIRTHDAY", birthday);
    formData.append("PASSWORD", password);
    formData.append("AGENCY_NAME", agency_ko_name);
    formData.append("AGENCY_ENG_NAME", agency_en_name);
    formData.append("SINCE", since);
    formData.append("ADDRESS", address);
    formData.append("ADDRESS1", addressDetail);
    formData.append("CONTACT1", contact1);
    formData.append("CONTACT2", contact2);
    formData.append("MANAGER_MAIL", manager_mail);
    formData.append("HOME_PAGE", home_page);
    if(image !== undefined){
        formData.append("IMAGE", image);
    }
    formData.append("KYC", kyc);
    
    const res = await client.formData.post('/api/user/register/agency', formData, { headers: { 'x-access-token': token } })
    return res.data;
}


/**
  oauth 기관 회원가입
   * @param {String} email 이메일
   * @param {String} platform 로그인 플랫폼
   * @param {String} ko_name 유저 한글 이름
   * @param {String} en_name 유저 영어 이름
   * @param {String} phone_num 핸드폰 번호
   * @param {String} country_phone_num 국가 번호
   * @param {String} birthday 생일
   * @param {String} agency_ko_name 기관 한국 이름
   * @param {String} agency_en_name 기관 영어 이름
   * @param {String} since 설립연원일
   * @param {String} address 기관 주소
   * @param {String} addressDetail 기관 상세 주소 
   * @param {String} contact1 기관 연락처1
   * @param {String} contact2 기관 연락처2
   * @param {String} manager_mail 기관 이메일
   * @param {String} home_page 기관 홈페이지 주소
   * @param {File} image 이미지
   * @param {String} kyc kyc인증 토큰
*/
const agencyOauthSignUp = async(email, platform, ko_name, en_name, phone_num, country_phone_num, birthday, agency_ko_name, agency_en_name, since, address, addressDetail, contact1, contact2, manager_mail, home_page, image, kyc) => {



    // let koName;
    // let enName;
    // let agencyKoName;
    // let agencyEnName;

    // if(ko_name === '' || en_name === ''){
    //     if(ko_name === ''){
    //         koName = en_name;
    //         enName = en_name;
    //     }

    //     if(en_name === ''){
    //         koName = ko_name;
    //         enName = ko_name;
    //     }
    // }else{
    //     koName = ko_name;
    //     enName = en_name;
    // }


    // if(agency_ko_name === '' || agency_en_name === ''){
    //     if(ko_name === ''){
    //         agencyKoName = agency_en_name;
    //         agencyEnName = agency_en_name;
    //     }

    //     if(en_name === ''){
    //         agencyKoName = agency_ko_name;
    //         agencyEnName = agency_ko_name;
    //     }
    // }else{
    //     agencyKoName = agency_ko_name;
    //     agencyEnName = agency_en_name;
    // }

    const formData = new FormData();
    formData.append("EMAIL", email);
    formData.append("PLATFORM", platform);
    formData.append("KOR_NAME", ko_name);
    formData.append("ENG_NAME", en_name);
    formData.append("PHONE_NUM", phone_num);
    formData.append("COUNTRY_NUMBER", country_phone_num);
    formData.append("BIRTHDAY", birthday);
    formData.append("AGENCY_NAME", agency_ko_name);
    formData.append("AGENCY_ENG_NAME", agency_en_name);
    formData.append("SINCE", since);
    formData.append("ADDRESS", address);
    formData.append("ADDRESS1", addressDetail);
    formData.append("CONTACT1", contact1);
    formData.append("CONTACT2", contact2);
    formData.append("MANAGER_MAIL", manager_mail);
    formData.append("HOME_PAGE", home_page);
    if(image !== undefined){
        formData.append("IMAGE", image);
    }
    formData.append("KYC", kyc);
    
    const res = await client.formData.post('/api/user/register/simple/agency', formData)
    return res.data;
}




/**
  로그인
   * @param {String} email 이메일
   * @param {String} password 비밀번호
*/
const login = async(email, password) => {
    const postData = {
        EMAIL: email,
        PASSWORD: password
    };
    const res = await client.json.post('/api/user/login', postData);
    return res.data;
}

/**
  oauth 로그인
   * @param {String} email 이메일
   * @param {String} platform KAKAO/NAVER/GOOGLE
*/
const oauthLogin = async(email, platform) => {
    const postData = {
        EMAIL: email,
        PLATFORM: platform
    };
    const res = await client.json.post('/api/user/simple/login', postData);
    return res.data;
}


/**
  (작가)내정보 가져오기
*/
const getArtistInfo = async() => {
    const res = await client.json.get('/user/account');
    return res.data;
}

/**
 (기관)내정보 가져오기
 */
const getAgencyInfo = async() => {
    const res = await client.json.get('/user/agency/profile');
    return res.data;
}

/**
 (일반) 내정보 수정하기
 * @param {String} origin 기존 이미지
 * @param {File} picture 프로필 이미지
 * @param {String} ko_first_name 한글 성
   * @param {String} ko_last_name 한글 이름
   * @param {String} en_first_name 영어 성
   * @param {String} en_last_name 영어 이름
   
 * @param {String} ko_nickname 한글 닉네임
 * @param {String} en_nickname 영어 닉네임
 * @param {String} use_name 이름 / 활동명 표출 선택   
 * @param {String} country 국가
 * @param {String} birthday 생일
 * @param {String} local_number 폰 번호
 * @param {String} phone_num 폰 번호
 * @param {File} sign 서명
 * @param {number} user_type 유저타입
 * @param {String} sns 웹/sns
 * @param {String} introduce 소개
 * @param {String} address 주소
 * @param {String} addressDetail 상세 주소
 * @param {String} email 이메일
 * @param {String} ACCOUNT_COMPANY 은행
 * @param {String} ACCOUNT_NUMBER 계좌번호
 * @param {String} ACCOUNT_OWNER 소유주
 */
 const editGeneralInfo = async(origin, picture, ko_first_name,
    ko_last_name,
    en_first_name,
    en_last_name, ko_nickname, en_nickname, use_name, country, birthday, local_number, phone_num, sign, user_type, sns, introduce, address, addressDetail, email,
    accountCompany, accountNumber, accountOwner) => {
    
    if(origin !== null && picture === ''){
        await imageDelete()
    }
    const formData = new FormData();
    formData.append("ORIGIN", origin); 
    formData.append("PICTURE", picture); 
    formData.append("FIRST_NAME", ko_first_name ?? ''); 
    formData.append("NAME", ko_last_name ?? ''); 
    formData.append("FIRST_ENG_NAME", en_first_name ?? ''); 
    formData.append("ENG_NAME", en_last_name ?? ''); 
    formData.append("NICKNAME", ko_nickname ?? ''); 
    formData.append("ENG_NICKNAME", en_nickname ?? ''); 
    formData.append("USE_NAME", use_name); 
    formData.append("COUNTRY", country); 
    formData.append("BIRTHDAY", birthday);
    formData.append("LOCATION_NUMBER", local_number); 
    formData.append("PHONE_NUMBER", phone_num); 
    formData.append("SIGN", sign); 
    formData.append("USER_TYPE", user_type); 
    formData.append("SNS", sns ?? ''); 
    formData.append("INTRODUCE", introduce ?? ''); 
    formData.append("ADDRESS", address ?? ''); 
    formData.append("ADDRESS_DETAIL", addressDetail ?? ''); 
    formData.append("ADD_EMAIL", email ?? ''); 
    formData.append("ACCOUNT_COMPANY", accountCompany ?? ''); 
    formData.append("ACCOUNT_NUMBER", accountNumber ?? ''); 
    formData.append("ACCOUNT_OWNER", accountOwner ?? ''); 
    
    const res = await client.formData.post('/user/account/personal', formData);
    return res.data;
}


/**
 (작가) 내정보 수정하기
 * @param {String} origin 기존 이미지
 * @param {File} picture 프로필 이미지
 * @param {String} ko_first_name 한글 성
 * @param {String} ko_last_name 한글 이름
 * @param {String} en_first_name 영어 성
 * @param {String} en_last_name 영어 이름  
 * @param {String} ko_nickname 한글 닉네임
 * @param {String} en_nickname 영어 닉네임
 * @param {String} use_name 이름 / 활동명 표출 선택   
 * @param {String} country 국가
 * @param {String} birthday 생일
 * @param {String} local_number 폰 번호
 * @param {String} phone_num 폰 번호
 * @param {File} sign 서명
 * @param {number} user_type 유저타입
 * @param {String} sns 웹/sns
 * @param {String} introduce 소개
 * @param {String} address 주소
 * @param {String} addressDetail 상세 주소
 * @param {String} email 이메일
 * @param {String} ACCOUNT_COMPANY 은행
 * @param {String} ACCOUNT_NUMBER 계좌번호
 * @param {String} ACCOUNT_OWNER 소유주
 */
const editArtistInfo = async(origin, picture, ko_first_name,
    ko_last_name,
    en_first_name,
    en_last_name, ko_nickname, en_nickname, use_name, country, birthday, local_number, phone_num, sign, user_type, sns, introduce, address, addressDetail, email,
    accountCompany, accountNumber, accountOwner) => {
    
    if(origin !== null && picture === ''){
        await imageDelete()
    }
    const formData = new FormData();
    formData.append("ORIGIN", origin); 
    formData.append("PICTURE", picture); 
    formData.append("FIRST_NAME", ko_first_name ?? ''); 
    formData.append("NAME", ko_last_name ?? ''); 
    formData.append("FIRST_ENG_NAME", en_first_name ?? ''); 
    formData.append("ENG_NAME", en_last_name ?? ''); 
    formData.append("NICKNAME", ko_nickname ?? ''); 
    formData.append("ENG_NICKNAME", en_nickname ?? ''); 
    formData.append("USE_NAME", use_name); 
    formData.append("COUNTRY", country); 
    formData.append("BIRTHDAY", birthday); 
    formData.append("LOCATION_NUMBER", local_number); 
    formData.append("PHONE_NUMBER", phone_num); 
    formData.append("SIGN", sign); 
    formData.append("USER_TYPE", user_type); 
    formData.append("SNS", sns ?? ''); 
    formData.append("INTRODUCE", introduce ?? ''); 
    formData.append("ADDRESS", address ?? ''); 
    formData.append("ADDRESS_DETAIL", addressDetail ?? ''); 
    formData.append("ADD_EMAIL", email ?? ''); 
    formData.append("ACCOUNT_COMPANY", accountCompany ?? ''); 
    formData.append("ACCOUNT_NUMBER", accountNumber ?? ''); 
    formData.append("ACCOUNT_OWNER", accountOwner ?? ''); 
    
    const res = await client.formData.post('/user/account', formData);
    return res.data;
}

const imageDelete = async() => {
    await client.json.delete('/user/profile-image');
}

/**
 (기관) 내정보 수정하기
 * @param {String} origin 기존 이미지
 * @param {File} picture 프로필 이미지
 * @param {String} ko_name 한글 이름
 * @param {String} en_name 영어 이름
 * @param {String} address 주소
 * @param {String} addressDetail 상세 주소
 * @param {String} manager_mail 기관 이메일
 * @param {String} ko_manager 담당자 한글
 * @param {String} en_manager 담당자 영어
 * @param {String} localNumber 담당자 번호
 * @param {String} contact 담당자 번호
 * @param {String} contact1 추가 연락처1
 * @param {String} contact2 추가 연락처2
 * @param {String} since 설립입
 * @param {String} homepage 설립입
 * @param {File} sign 서명
 * @param {number} user_type 유저타입
 * @param {String} introduce 작가 소개
 * @param {String} ACCOUNT_COMPANY 은행
 * @param {String} ACCOUNT_NUMBER 계좌번호
 * @param {String} ACCOUNT_OWNER 소유주
 */
const editAgencyInfo = async(origin, picture, ko_name, en_name, address, addressDetail, manager_mail, ko_manager, en_manager, localNumber, contact, contact1, contact2, since, homepage, sign, user_type, introduce, accountCompany, accountNumber, accountOwner) => {
    if(origin !== null && picture === ''){
        await imageDelete()
    }
    const formData = new FormData();
    formData.append("IMAGE", picture); 
    formData.append("NAME", ko_name ?? ''); 
    formData.append("ENG_NAME", en_name ?? ''); 
    formData.append("ADDRESS", address ?? ''); 
    formData.append("ADDRESS1", addressDetail ?? ''); 
    formData.append("MANAGER_MAIL", manager_mail ?? ''); 
    formData.append("MANAGER_NAME", ko_manager ?? '');
    formData.append("MANAGER_ENG_NAME", en_manager ?? '');

    formData.append("COUNTRY_NUMBER", localNumber); 
    formData.append("PHONE_NUM", contact); 

    formData.append("CONTACT1", contact1 ?? ''); 
    formData.append("CONTACT2", contact2 ?? ''); 
    formData.append("SINCE", since ?? ''); 
    formData.append("HOME_PAGE", homepage ?? ''); 
    if(sign !== null){
        formData.append("SIGN", sign); 
    }
    formData.append("USER_TYPE", user_type); 
    formData.append("INTRODUCE", introduce ?? ''); 
    formData.append("ACCOUNT_COMPANY", accountCompany ?? ''); 
    formData.append("ACCOUNT_NUMBER", accountNumber ?? ''); 
    formData.append("ACCOUNT_OWNER", accountOwner ?? ''); 
    
    const res = await client.formData.post('/user/agency/profile', formData);
    return res.data;
}


/**
 eKYC 인증(회원가입중)
 * @param {JSON} kyc kyc인증 데이터
 */
const certifiedKYC = async(kyc) => {
    
    const res = await client.json.post('/api/user/register/kyc', kyc);
    return res.data;
}

/**
 eKYC 인증(회원가입 이후)
 * @param {JSON} kyc kyc인증 데이터
 */
 const afterCertifiedKYC = async(kyc) => {
    const res = await client.json.post('/api/admin/kyc/state', kyc);
    return res.data;
}

/**
  회원 탈퇴
*/
const withdrawalUser = async() => {
    const res = await client.json.delete('/user/account');
    return res.data;
}


/**
 artsynapse.art 회원 최초 비밀번호 변경

 * @param {String} pw 변경할 비밀번호
 */
 const artsynapseChangedPw = async(pw) => {
    
    const postData = {
        PW: pw
    }
    
    const res = await client.json.post('/user/artsynapse/password', postData);
    return res.data;
}


/**
 artsynapse.art 회원 최초 이메일 변경(이메일 요청)

 * @param {String} email 변경할 이메일
 */
 const artsynapseChangeEmail = async(email) => {
    
    const postData = {
        email: email
    }
    
    const res = await client.json.put('/artsynapse/email', postData);
    return res.data;
}

/**
 artsynapse.art 회원 최초 이메일 변경(인증코드 확인)

 * @param {String} token 전단계 토큰
 * @param {String} code 인증 코드
 */
 const artsynapseChangeEmailCode = async(token, code) => {
    
    const postData = {
        code: code
    }
    
    const res = await client.json.post('/artsynapse/email', postData, {headers: {'art-token': token}});
    return res.data;
}


const auth = {
    naverCallback,
    sendEmail, checkCode,
    sendSMS, checkSMS, checkMyEmail,
    sendEmailPassword, checkCodePassword, changePassword,
    login, generalSignUp, artistSignUp, agencySignUp,
    oauthLogin, generalOauthSignUp, artistOauthSignUp, agencyOauthSignUp,
    getArtistInfo, editArtistInfo, editGeneralInfo,
    getAgencyInfo, editAgencyInfo,
    certifiedKYC, afterCertifiedKYC,
    withdrawalUser,
    artsynapseChangedPw,
    artsynapseChangeEmail, artsynapseChangeEmailCode
}
export default auth
