import React, {useState} from 'react'
import DropDown from '../DropDown/DropDown'
import './HistoryModal.css'
import { shallowEqual, useSelector } from 'react-redux'
import { checkDate, handleInputMaxLength } from '../../hook/utils/validation'
import {v4 as uuidv4} from 'uuid';


function HistoryModal({ setModalOpen, data, setData }) {
  const { min, today, max } = useSelector(v => v.setting.date, shallowEqual)

  const { countryList } = useSelector(v => v.setting, shallowEqual)
  const { typeList } = useSelector(v => v.setting.exhibition, shallowEqual)
  const [title, setTitle] = useState('')
  const [ place, setPlace] = useState('')
  const [ country, setCountry] = useState(null)
  const [ type, setType] = useState(null)
  const [ date, setDate] = useState('')
  const [ end, setEnd] = useState('')
  const [ publisher, setPublisher] = useState('') // 주관자
  const [ hoster, setHoster] = useState('') // 주최자
  const [ planner, setPlanner] = useState('') // 기획자
  const [ reference, setReference] = useState('')

  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false)
    //document.body.style.overflow = 'unset' // 모달 창 종료 시 스크롤 가능
  }

  const done = async () => {
    if (title === '') {
      alert('전시명을 입력해주세요.')
      return
    }
    if (place === '') {
      alert('개최장소를 입력해주세요.')
      return
    }

    if (date === '' || end === '') {
      alert('개최기간을 입력해주세요.')
      return
    }
    // if (publisher === '') {
    //   alert('주관을 입력해주세요.')
    //   return
    // }
    // if (hoster === '') {
    //   alert('주최를 입력해주세요.')
    //   return
    // }
    // if (planner === '') {
    //   alert('기획자를 입력해주세요.')
    //   return
    // }
    // if (reference === '') {
    //   alert('설명을 입력해주세요.')
    //   return
    // }

    const sendCountry = country ?? countryList[0]
    const sendType = type ?? typeList[0]


    const json = {
      pk: uuidv4(),
      exhi_NAME: title,
      country: sendCountry,
      place: place,
      type: sendType,
      start_DATE: date,
      end_DATE: end,
      hoster: hoster,
      planner: planner,
      publisher: publisher,
      note: reference
    }

    setData([...data, json])
    closeModal()
  }



  return (
    <div className="history-modal">
      <div className="modal-container">
        <div className="modal-body" onClick={e => e.stopPropagation()}>
          <header className="history-header">
            <h1>전시이력 추가</h1>
            <button className="history-close" onClick={closeModal}></button>
          </header>
          <ul className="history-content">
            <li>
              <h2>전시명 *</h2>
            <input type="text" value={title}  onChange={e => setTitle(e.target.value)}></input>
            </li>
            <li>
              <h2>개최장소 *</h2>
            <input type="text" value={place}  onChange={e => setPlace(e.target.value)}></input>
            </li>
            <li>
              <h2>국가 *</h2>
            <DropDown options={countryList} setValue={setCountry} onChange={e => setCountry(e.target.value)}/>
            </li>
            <li>
              <h2>전시유형 *</h2>
            <DropDown options={typeList} setValue={setType} onChange={e => setType(e.target.value)}/>
            </li>
            <li>
              <h2>개최기간 * </h2>
   <input
                type="date"
                id="date"
                className="date-input"
                min={min}
                max={today}
                value={date}
                onChange={e => checkDate(setDate, e)}
              ></input>
              <span className="date-bar">-</span>
              <input
                type="date"
                id="date"
                className="date-input"
                min={min}
                max={max}
                value={end}
                onChange={e => setEnd(e.target.value)}
              ></input>
            </li>
      <li>
            <h2>주최</h2>
            <input type="text" value={hoster} onChange={e => setHoster(e.target.value)}></input>
          </li>
          <li>
            <h2>주관</h2>
            <input type="text" value={publisher} onChange={e => setPublisher(e.target.value)}></input>
          </li>
          <li>
            <h2>기획자</h2>
            <input type="text" value={planner} onChange={e => setPlanner(e.target.value)}></input>
          </li>
          <li>
            <h2>전시 설명</h2>
            <textarea
              placeholder="한글 500자 이내(공백 포함)"
              maxLength={500}
              value={reference}
              onChange={e => handleInputMaxLength(setReference, e)}></textarea>
          </li>
          </ul>
          <button className="history-add" onClick={done}>
            <span>저장</span>
          </button>
        </div>
      </div>
    </div>
  )
}
export default HistoryModal
