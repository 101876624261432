import React, { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import TableSlide from '../../../components/TableSlide/TableSlide';

import QueryString from 'qs';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { artViewDate, tableDate } from '../../../hook/utils/artdbView';
import { getDateStr } from '../../../hook/utils/getDateStr';
import { workMainImage } from '../../../services/imageRoute';
import publicWork from '../../../services/public/publicWork';
import './QCoAList.css';

import useMobileDetect from 'use-mobile-detect-hook';
import ModalPortal from '../../../components/Modal/ModalPortal';
import PDFViewerModal from '../../../components/Modal/PDFViewerModal';



function QCoAList() {
  const [ open, setOpen ] = React.useState(false);
  const [ openLink, setOpenLink ] = React.useState(null);


  const { login } = useSelector(v => v.auth, shallowEqual);
  const detectMobile = useMobileDetect();


  const navigate = useNavigate()
  const location = useLocation();
  const queryData = QueryString.parse(location.search, { ignoreQueryPrefix: true });
  

  const [items, setItems] = useState([]);
  const [tableItem, setTableItem] = useState([]);


  const [total, setTotal] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState(queryData.search ?? '');
  const row = detectMobile.isMobile() ? 7 : 9;


  React.useEffect(() => {
    getList(currentPage, row, search)
  }, [currentPage, search])

  const getList = async (currentPage, row, search) => {
    const res = await publicWork.getQcoAList(currentPage, row, search)
    setTotal(res.total)
    setItems(res.work_list)

    if(queryData.search !== null || queryData.search !== undefined || queryData.search !== ""){
      if(res.work_list[0].unique_KEY === queryData.search){
        setOpenLink(res.work_list[0].unique_KEY);
        setOpen(true)
      }
    }
  }

  const viewCoA = (uniqueKey) => {
    if(!login){
      alert('로그인이 필요한 서비스입니다.')
      navigate('/login', {replace:true})

      return
    }
    setOpenLink(uniqueKey);
    setOpen(true)
  }
  
  React.useEffect(() => {
    const changeItem = []
    items.map((item, i) => {
      changeItem.push(
        <div className="table-slider-item">
          <ul style={{cursor: 'pointer'}} onClick={() => {viewCoA(item.unique_KEY)}}>
            {/* <li>
              <label className="login-option">
                <input
                  type="checkbox"
                  onChange={e => checkHandler(e.target.checked, i)}
                  checked={checkItems.indexOf(i) >= 0 ? true : false}
                />
                <span className="login-option__check" />
              </label>
            </li> */}
            <li>{(total) - (row * currentPage + i) }</li>
            <li>
            <img src={`${workMainImage}/${item.image}`} alt='' onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img>
            </li>
            <li>{ item.artist }</li>
            <li>{ item.title }</li>
            <li>{ item.material }</li>
            <li>{ tableDate(item.start_CREATE, item.end_CREATE) }</li>
            <li>{ item.owner_USER_CODE }</li>
            <li>
            <span className="fz-c-blue">{getDateStr(item.coa_CREATE_TIME, '-')}</span>
            </li>
          </ul>
          <div className="work-item-mb">
          <div className="work-item-mb-container">
            {/* <label className="login-option">
              <input
                type="checkbox"
                onChange={e => checkHandler(e.target.checked, i)}
                checked={checkItems.indexOf(i) >= 0 ? true : false}
              />
              <span className="login-option__check" />
            </label> */}
            <div className="work-item-mb-content" onClick={() => viewCoA(item.unique_KEY)}>
              <div className="work-item-mb__info">
              <img src={`${workMainImage}/${item.image}`} alt='' onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img>
                <div className="work-item-mb__detail">
                <h4>[ {item.owner_USER_CODE} ]</h4>
                  <h1>{item.title}</h1>
                  <h3>{item.artist}</h3>
                  <h3>{artViewDate(item.start_CREATE, item.end_CREATE)}</h3>
                  <h3>Q-CoA 등록일 : {getDateStr(item.coa_CREATE_TIME, '-')}</h3>
                </div>
                {/* <button className="work-item-mb__delete"></button> */}
              </div>
            </div>
          </div>
        </div>
        </div>
      )
    })

    setTableItem([...changeItem])
  },[items])

  const titleItem = []

  titleItem.push(
    <div className="table-header-search">
      <div className="th-search-container">
        <h2>Q-CoA List</h2>
        <section className="search__block">
          <DebounceInput
            minLength={1}
            debounceTimeout={500}
            type={'text'}
            placeholder={'작품을 검색해주세요'}
            value={search}
            onChange={e => { setSearch(e.target.value); setCurrentPage(0) }}
            className="search__block__input"
          />
        </section>
      </div>
    </div>
  )
  const tableSlideHeader = []
  tableSlideHeader.push(
    <ul className="container table-slider-header-container">
      <li>번호</li>
      <li>대표 이미지</li>
      <li>작가명</li>
      <li>작품명</li>
      <li>재질</li>
      <li>제작일</li>
      <li>소장처</li>
      <li>Q-CoA 등록일</li>
    </ul>
  )
  return (
    <>
    {open && openLink && (
        <ModalPortal>
          <PDFViewerModal
            path={openLink}
            setPath={setOpenLink}
            setModalOpen={setOpen}
          />
        </ModalPortal>
      )}
      <div className="my-qcoa-list qcoa-list">
        <TableSlide
          title={titleItem}
          slideHeader={tableSlideHeader}
          items={tableItem}
          rows={row}
          breakRows={7}
          total={total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  )
}

export default QCoAList