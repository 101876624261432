import React from 'react'
import './DateModal.css'
import { LoadingState } from '../../context/loadingStateProvider';
import { shallowEqual, useSelector } from 'react-redux';
import { checkDate } from '../../hook/utils/validation';

function DateModal({ title, setModalOpen, setDate, date, sDate, setSDate, afterAction, action, extension, reason, setReason, exName, setExName, exPlace, setExPlace }) {

  const loadingState = React.useContext(LoadingState);
  const { today } = useSelector(v => v.setting.date, shallowEqual)

  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false)
  }

  React.useEffect(() => {
    clear()
  },[])

  const clear = () => {
    if(setDate){
      setDate('')
    }
    if(setSDate){
      setSDate('')
    }
    if(setExName){
      setExName('')
    }
    if(setExPlace){
      setExPlace('')
    }
    if(setReason){
      setReason('')
    }
  }

  const onOk = async () => {

    loadingState.setText('잠시만 기다려주세요.')
    loadingState.setOpen(true)
    closeModal()
    if (afterAction) {
      action()
    }
    loadingState.setOpen(false)
    loadingState.setText('')
  }

  return (
    <div className="date-modal">
      <div className="modal-container">
        <div className="modal-body" onClick={e => e.stopPropagation()}>
          <h1>
            {title}
          </h1>
          <div className='my__center'>
            {!extension ?
              <>
                <div className='my__flex'>
                  <h2>
                    대여일
                  </h2>
                  <div className="ma-add-date-mb field">
                    <input
                      autoComplete='off'
                      type="datetime-local"
                      id="date"
                      className="my__input"
                      value={sDate}
                      onChange={e => checkDate(setSDate, e)}
                    ></input>
                  </div>
                </div>
                <div className='my__flex'>
                  <h2>
                    반납일
                  </h2>
                  <div className="ma-add-date-mb field">
                    <input
                      autoComplete='off'
                      type="datetime-local"
                      id="date"
                      min={today}
                      className="my__input"
                      value={date}
                      onChange={e => checkDate(setDate, e)}
                    ></input>
                  </div>
                </div>
                <div className='my__flex'>
                  <h2>
                    전시/대여 장소명
                  </h2>
                  <div className="ma-add-date-mb field">
                    <input
                      autoComplete='off'
                      className="my__input"
                      style={{width: '100%'}}
                      value={exName}
                      placeholder='전시/대여 장소명'
                      onChange={e => setExName(e.target.value)}
                    ></input>
                  </div>
                </div>
                <div className='my__flex'>
                  <h2>
                    전시명
                  </h2>
                  <div className="ma-add-date-mb field">
                    <input
                      autoComplete='off'
                      className="my__input"
                      style={{width: '100%'}}
                      value={exPlace}
                      placeholder='대제목만, 부제 생략'
                      onChange={e => setExPlace(e.target.value)}
                    ></input>
                  </div>
                </div>
              </>
              : <div style={{ width: '100%' }}>
                <div className="ma-add-date-mb">
                  <input
                    autoComplete='off'
                    type="datetime-local"
                    id="date"
                    min={today}
                    className="my__input"
                    value={date}
                    onChange={e => checkDate(setDate, e)}
                  ></input>
                </div>
                <h1 style={{ textAlign: 'center', marginTop: '20rem' }}>
                  연장 이유를 입력해주세요.
                </h1>
                <textarea
                  style={{ width: '100%', resize: 'none', marginTop: '20rem' }}
                  className='my__textarea'
                  maxlength="50"
                  placeholder='50자 이내로 입력해주세요.'
                  autoComplete='off'
                  value={reason}
                  onChange={e => setReason(e.target.value)}
                ></textarea>

              </div>}
          </div>
          <div className="date-btn">
            <div style={{ position: 'absolute', bottom: '30rem', display: 'flex', justifyContent: 'space-around', width: '100%' }}>
              <button onClick={closeModal}>취소</button>
              <button onClick={onOk}>신청</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DateModal
