import React, { useState } from 'react'
import Footer from '../../../../components/Footer/Footer'
import Header from '../../../../components/Header/Header'
import SectionHeader from '../../../../components/SectionHeader/SectionHeader'
import './GalleryTeam.css'
import profile from '../../../../services/auth/profile'
import { checkUseName } from '../../../../hook/utils/checkUseName'
import { shallowEqual, useSelector } from 'react-redux'
import { emailCheck } from '../../../../hook/utils/validation'
import { useNavigate } from 'react-router-dom'
function GalleryTeam() {

  const { lang } = useSelector(v => v.setting, shallowEqual)
  const { gallery } = useSelector(v => v.auth, shallowEqual)
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [lockEmail, setLockEmail] = useState('')
  const [koName, setKoName] = useState('')
  const [enName, setEnName] = useState('')
  const [job, setJob] = useState('')

  const search = async () => {
    if(!emailCheck(email)){
      return
    }

    if(gallery.email === email){
      alert('본인은 추가할 수 없습니다.')
      return
    }

    const res = await profile.searchTeam(email)

    if(!res.resultCode){
      if(res.message === "EmptyUser" ){
        alert('존재하지 않는 유저입니다.')
        return
      }
      if(res.message === "" ){
        alert('다른팀에 소속된 유저입니다.')
        return
      }
    }else{
      setLockEmail(email)
      setKoName(checkUseName(lang, res.USE_NAME, res.KO_NAME, res.KO_NAME, res.NICKNAME, res.NICKNAME))
      setEnName(checkUseName(lang, res.USE_NAME, res.ENG_NAME, res.ENG_NAME, res.ENG_NICKNAME, res.ENG_NICKNAME))
    }
  }

  const addTeam = async() => {
    if(koName === '' || enName === ''){
      alert('이메일을 검색해주세요.')
      return
    }
    if(job === ''){
      alert('직무를 입력해주세요.')
      return
    }

    const res = await profile.addTeam(lockEmail, job)

    if(!res.resultCode){
      if(res.message === "already" ){
        alert('다른팀에 소속된 유저입니다.')
        return
      }
      return
    }
    alert('팀원이 추가되었습니다.')
    navigate(-1)
  }


  return (
    <div className="gallery-team">
      <Header login={true} colored="black" detail={true} />
      <nav className="search">
        <div className="container search__container">
          <h2 className="search__title">마이페이지</h2>
        </div>
      </nav>
      <SectionHeader title={'팀원 추가'} />
      <div className="gallery-team-content">
        <div className="container">
          <ul>
            <li>
            <h2>이메일 *</h2>
            <div className="search__block">
                      <input
                        type="text"
                        value={email}
                        className="search__block"
                        onChange={e => setEmail(e.target.value)}
                      ></input>
                      <button className="search__block__button" onClick={search}>
                        <span className="ir_pm">검색</span>
                      </button>
                    </div>
            </li>
            <li>
              <h2>이름</h2>
              <input
                value={koName}
                readOnly
                type="text"></input>
            </li>
            <li>
              <h2> </h2>
              <input
                value={enName}
                readOnly
                type="text"></input>
            </li>
            <li>
              <h2>직무 *</h2>
              <input 
                value={job}
                onChange={e => setJob(e.target.value)}
                type="text"></input>
            </li>
          </ul>
          <div className="a-ex-edit--button">
            <button onClick={() => navigate(-1)} >취소</button>
            <button onClick={addTeam}>저장</button>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}
export default GalleryTeam
