export const addComma = (price) => {
  if(price === '') return ''

    const findDot = price?.toString().indexOf('.');
    const priceLength = price?.toString().length;

    let num = price?.toString().substring(0,findDot)
    let decimal = price?.toString().substring(findDot, priceLength)
    if( findDot === -1){
      num = price.toString()
      decimal = ''
    }else{
      num = price?.toString().substring(0,findDot)
      decimal = price?.toString().substring(findDot, priceLength)
    }

    const returnNum = num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const returnString = returnNum + decimal;
    
    return returnString;
  }

  export const setComma = (price) => {
    return addComma(price) || ''
  }

  export const checkNumber = e => {
    
    if (/[^-\.0-9]/.test(e.key)) {
    // if (!/[0-9]/.test(e.key)) {
    // if (!/[0-9]*\.?[0-9]*/.test(e.key)) {
      e.preventDefault();
    }
  }


export const onChangePoints = (e) => {
    const { value } = e.target;
    let str = value.replaceAll(",", "");
    return str
  };