import React, { useContext, useState } from 'react'
import DropDown from '../../../../../components/DropDown/DropDown'
import Footer from '../../../../../components/Footer/Footer'
import Header from '../../../../../components/Header/Header'
import SectionHeader from '../../../../../components/SectionHeader/SectionHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import { setComma, onChangePoints, checkNumber } from '../../../../../hook/utils/comma'
import './MyNftManage.css'

import { WalletState } from '../../../../../context/walletStateProvider'
import { web3Net } from '../../../../../services/web3/abi'
import { checkDate } from '../../../../../hook/utils/validation'
import nft from '../../../../../services/myArchive/work/nft'
import { getDateTimeStr } from '../../../../../hook/utils/getDateStr'
import metamask from '../../../../../services/web3/metamask'


function MyNFTManage() {

  const walletState = useContext(WalletState);

  const { item } = useLocation().state;

  React.useEffect(() => {
    if (walletState.address === null) {
      navigate(-1)
    }
  }, [])

  const navigate = useNavigate();
  const { saleTypeList, nftSaleTypeList } = useSelector(v => v.setting.work, shallowEqual)
  const { min, today } = useSelector(v => v.setting.date, shallowEqual)
  const { lang } = useSelector(v => v.setting, shallowEqual)

  // const [saleType, setSaleType] = React.useState(saleTypeList[0])
  const [price, setPrice] = React.useState(item.price)
  const [nftSaleType, setNftSaleType] = React.useState(nftSaleTypeList[+item.isSale -1 > 0 ? +item.isSale -1 : 0])
  const [start, setStart] = React.useState(item.start)
  const [end, setEnd] = React.useState(item.end)

  const register = async () => {

    const toTimeStamp = (str) => {
      const date = new Date(str);
      const timestamp = date.getTime();
      return timestamp.toString()
    }

    let sendStart = '';
    let sendEnd = '';

    if (nftSaleTypeList.indexOf(nftSaleType) === 1) {
      if (start === '') {
        alert('경매 시작시간을 입력해주세요.')
        return
      }
      if (end === '') {
        alert('경매 마감시간을 입력해주세요.')
        return
      }
      sendStart = toTimeStamp(start);
      sendEnd = toTimeStamp(end);
    }

    if(price === ''){
      alert(nftSaleTypeList.indexOf(nftSaleType) === 1 ? '시작가를 입력해주세요.' : '가격을 입력해주세요.')
      return
    }

    const action = async() => {
      const res = await nft.setNFT(item.uri, walletState.address, item.contract, item.tokenId, (nftSaleTypeList.indexOf(nftSaleType) + 1).toString(), price, sendStart, sendEnd);

      if (!res.resultCode) {
        alert('판매중 혹은 경매중입니다.')
        return
      }
  
      alert(nftSaleTypeList.indexOf(nftSaleType) === 1 ? '경매 등록됬습니다.' : '판매 등록했습니다.')
      navigate(-1)
    }

    await metamask.approve(walletState, item.tokenId, action)
  }

  const cancelRegister = async() =>{
    const res = await nft.cancelNFT(item.pk);

    if (!res.resultCode) {
      alert('관리자에게 문의해주세요.');
      return
    }

    alert(item.isSale === '1' ? '판매가 취소되었습니다.' : '경매가 취소되었습니다.');
    navigate(-1)
  }

  return (
    <>
      {/* {pinOpen && (
        <ModalPortal>
          <PinModal
            time={1}
            title={'작품의 간편패스워드를 입력해주세요.'}
            setModalOpen={showPinModal}
            setMyPin={setMyPin}
            afterAction={true}
            action={register}
          />
        </ModalPortal>
      )} */}

      <div className="ma-work-add">
        <Header login={true} colored="black" detail={true} />
        <nav className="search">
          <div className="container search__container">
            <h2 className="search__title">My Archive</h2>
          </div>
        </nav>
        <SectionHeader title={'Manage NFT'} />
        <div className="ma-edit-image">
          <div className="container ma-edit-image-container">
            <h2 className="ma-img-tlt">이미지</h2>
            <div className="main-image-container">
              <img
                className='ma-img-show'
                src={item.image}
                alt="NFT"
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                      
                  }
                }
              />
            </div>
          </div>
        </div>
        <ul className="ma-add-info ma-add-info">
          <div className="container">
            <li>
              <h2>NFT 명</h2>
              <input autoComplete='off' type="text" value={item.name} disabled={true} />
            </li>
            {(item.contract !== undefined || item.contract !== null) &&
              <li>
                <h2>컨트랙트 주소</h2>
                <input autoComplete='off' type="text" value={item.contract} disabled={true} />
              </li>
            }
            {(item.tokenId !== undefined || item.tokenId !== null) &&
              <li>
                <h2>NFT ID</h2>
                <input autoComplete='off' type="text" value={item.tokenId} disabled={true} />
              </li>
            }
            {(item.issue !== undefined || item.issue !== null) &&
              <li>
                <h2>발행일</h2>
                <input autoComplete='off' type="text" value={item.issue} disabled={true} />
              </li>
            }

          </div>
        </ul>
        {item.contract === web3Net.contractAddress
          &&
          <ul className="ma-add-info ma-add-info-02">
            <div className="container">
              <li>
                <h2>작품 명</h2>
                <input autoComplete='off' type="text" value={item.ko_title} disabled={true} />
              </li>
              <li>
                <h2> </h2>
                <input autoComplete='off' type="text" value={item.en_title} disabled={true} />
              </li>
              <li>
                <h2>작가</h2>
                <input autoComplete='off' type="text" value={item.ko_artist} disabled={true} />
              </li>
              <li>
                <h2> </h2>
                <input autoComplete='off' type="text" value={item.en_artist} disabled={true} />
              </li>
              <li>
                <h2>제작일</h2>
                <input autoComplete='off' type="text" value={item.made_in} disabled={true} />
              </li>
              <li>
                <h2>재질</h2>
                <input autoComplete='off' type="text" value={item.material} disabled={true} />
              </li>
              <li>
                <h2>사이즈</h2>
                <input autoComplete='off' type="text" value={item.size} disabled={true} />
              </li>
            </div>
          </ul>
        }
        {
          item.isSale === '0' &&
          <ul className="ma-add-info-03 ma-add-info">
            <div className="container">
              <li>
                <h2>판매 방식 *</h2>
                <div className="ma-add-date-mb">
                  <DropDown options={nftSaleTypeList} select={nftSaleTypeList.indexOf(nftSaleType)} setValue={setNftSaleType} />
                </div>
              </li>
              {nftSaleType === nftSaleTypeList[1] &&
                <>
                  <li>
                    <h2>경매 기간 *</h2>
                    <div className="ma-add-date-mb" style={{ flex: 1 }}>
                      <input
                        autoComplete='off'
                        type="datetime-local"
                        id="date"
                        min={today}
                        className="date-input-nft"
                        value={start}
                        onChange={e => checkDate(setStart, e)}
                      ></input>
                    </div>
                  </li>
                  <li>
                    <h2> </h2>
                    <div className="ma-add-date-mb" style={{ flex: 1 }}>
                      <input
                        autoComplete='off'
                        type="datetime-local"
                        id="date"
                        min={today}
                        className="date-input-nft"
                        value={end}
                        onChange={e => checkDate(setEnd, e)}
                      ></input>
                    </div>
                  </li>
                </>
              }
              <li>
                <h2>{nftSaleType === nftSaleTypeList[1] ? '시작가' : '가격'} *</h2>
                <div className="ma-info-02-input">
                  <input autoComplete='off'
                    style={{ textAlign: 'end' }}
                    type="text"
                    onKeyPress={checkNumber}
                    inputMode="numeric"
                    placeholder="1,000,000"
                    value={setComma(price)}
                    onChange={e => setPrice(onChangePoints(e))}></input>
                  {/* <span>{saleType === saleTypeList[0] ? '원' : 'USD'}</span> */}
                  <span>원</span>
                </div>
              </li>
            </div>
          </ul>
        }
        {
          item.isSale !== '0' &&
          <ul className="ma-add-info-03 ma-add-info">
            <div className="container">
              <li>
                <h2>판매 방식 *</h2>
                <div className="ma-add-date-mb">
                  <DropDown disabled options={nftSaleTypeList} select={nftSaleTypeList.indexOf(nftSaleType)} setValue={setNftSaleType} />
                </div>
              </li>
              {nftSaleType === nftSaleTypeList[1] &&
                <>
                  <li>
                    <h2>경매 기간 *</h2>
                    <div className="ma-add-date-mb" style={{ flex: 1 }}>
                      <input
                        readOnly
                        autoComplete='off'
                        type="datetime-local"
                        id="date"
                        min={today}
                        className="date-input-nft"
                        value={start}
                        onChange={e => checkDate(setStart, e)}
                      ></input>
                    </div>
                  </li>
                  <li>
                    <h2> </h2>
                    <div className="ma-add-date-mb" style={{ flex: 1 }}>
                      <input
                        readOnly
                        autoComplete='off'
                        type="datetime-local"
                        id="date"
                        min={today}
                        className="date-input-nft"
                        value={end}
                        onChange={e => checkDate(setEnd, e)}
                      ></input>
                    </div>
                  </li>
                </>
              }
              <li>
                <h2>{nftSaleType === nftSaleTypeList[1] ? '시작가' : '가격'} *</h2>
                <div className="ma-info-02-input">
                  <input
                    autoComplete='off'
                    readOnly
                    style={{ textAlign: 'end' }}
                    type="text"
                    onKeyPress={checkNumber}
                    inputMode="numeric"
                    placeholder="1,000,000"
                    value={setComma(price)}
                    onChange={e => setPrice(onChangePoints(e))}></input>
                  {/* <span>{saleType === saleTypeList[0] ? '원' : 'USD'}</span> */}
                  <span>원</span>
                </div>
              </li>
            </div>
          </ul>
        }

        <div className="ma-add--button">
          <div className="container">
            <button onClick={() => navigate(-1)}>취소</button>
            {item.isSale === '0' ?
              <button onClick={() => register()}>저장</button>
              :
              <button onClick={() => cancelRegister()}>{item.isSale === '1'? '판매 취소' : '경매 취소'}</button>
            }
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
export default MyNFTManage
