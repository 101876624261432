import React, { useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Footer from '../../../../components/Footer/Footer'
import Header from '../../../../components/Header/Header'
import AuctionModal from '../../../../components/Modal/AuctionModal'
import ModalPortal from '../../../../components/Modal/ModalPortal'
import './AuctionDetail.css'
import publicNFT from '../../../../services/public/publicNFT'
import { artViewPrice } from '../../../../hook/utils/artdbView'
import { shallowEqual, useSelector } from 'react-redux'
import useInterval from '../../../../hook/useInterval'
import SectionHeader from '../../../../components/SectionHeader/SectionHeader'
import { handleImgError, userImage } from '../../../../services/imageRoute'
import { checkUseName } from '../../../../hook/utils/checkUseName'

function AuctionNFTDetail() {
  const { login, type, artist, gallery } = useSelector(v => v.auth, shallowEqual)
  const { lang } = useSelector(v => v.setting, shallowEqual)

  const [data, setData] = React.useState(null);
  const [bidList, setBidList] = React.useState([]);
  const [topBid, setTopBid] = React.useState(null);
  const [active, setActive] = React.useState(1);


  const [leftTime, setLeftTime] = React.useState('');
  const navigate = useNavigate();
  const { itemId } = useParams();
  React.useEffect(() => { getData(itemId) }, [itemId])
  const getData = async (pk) => {
    const res = await publicNFT.getDetail(pk)
    setData(res.nft.nft_detail)
    setBidList(res.nft.bid)
    setTopBid(res.nft.top_bid?.bid_PRICE ?? res.nft.nft_detail.price)
  }

  // 모달창 노출 여부 state
  const [aucOpen, setAucOpen] = useState(false)

  // 모달창 노출
  const showAucModal = () => {

    const _email =  type === 2 ? gallery.email : artist.email

    if(_email === data.user_EMAIL){
      alert('본인이 올린 경매는 참여 불가능합니다.')
      return
    }

    if(type === 1){
      alert('작가는 창작한 작품 판매만 가능합니다.')
      return
    }
    
    if(active === 0){
      alert('종료된 경매입니다.')
      return
    }
    if(active === 2){
      alert('경매 시작전입니다.')
      return
    }
    if(leftTime === '마감'){
      alert('종료된 경매에 참여는 불가능합니다.');
      navigate('/market/auction/now')
      return;
    }
    setAucOpen(true)
  }

  const setLike = async () => {
    if (!login) {
      alert('로그인이 필요한 서비스입니다.')
      navigate('/login', {replace:true})

      return
    }

      const res = await publicNFT.setLike(data.nft_PK)
      if (!res.resultCode) {
        return
      }
      setData({...data, heart: !data.heart})
  }

  const diffDay = () => {
    const startTime = data.start_TIME

    const masTime = data.end_TIME
    const todayTime = new Date();
    
    const diff = masTime - todayTime;

    const check = todayTime - startTime;
    
    if(check <= 0){
      setActive(2)
      setLeftTime('시작전')
      return
    }

    if(diff <= 0){
      setActive(0)
      setLeftTime('마감')
      return
    }
    
    const diffDay = Math.floor(diff / (1000*60*60*24));
    const diffHour = Math.floor((diff / (1000*60*60)) % 24);
    const diffMin = Math.floor((diff / (1000*60)) % 60);
    const diffSec = Math.floor(diff / 1000 % 60);
    
    setLeftTime(`${diffDay}일 ${diffHour}시간 ${diffMin}분 ${diffSec}초`);
}

useInterval(() => {
  diffDay();
}, 1000);

  if(data === null){
    return <></>
  }
  return (
    <div className="autcion-detail">
      <section className="market-detail">
        <Header active="2" colored="black" detail={true} />
        <header className="auction-detail-header">
          <div className="container ad-header-container">
            <div className="ad-haeder__route">
              <Link to="/market">
                <span>Marketplace</span>
              </Link>
              <span className="next"></span>
              <Link to="/market/auction">
                <span>Auction</span>
              </Link>
              <span className="next"></span>
            </div>
            <h1>Now</h1>
          </div>
        </header>
        <div className="container">
          <div className="market-art">
            <section className="market-art-image">
              <img
                className="market-image__image"
                src={data.image}
                alt=''
                onContextMenu={
                  (e) => {
                      e.preventDefault();
                  }
                }
              ></img>
            </section>
            <section className="market-art-info">
            <div className="action-nft__title">
              <h1>{data.name}</h1>
              <button
                onClick={setLike}
               className={data.heart ? 'action-heart-button' : 'heart-button'}> 
                <span className="ir_pm">좋아요</span>
              </button>
            </div>
              <div className="market-art-sell">
                <p className="market-sell__price">
                  <span>시작가</span>
                  <span>{artViewPrice(1, data.price)}</span>
                </p>
                <p className="market-sell__price color-red">
                  <span>현재가</span>
                  <span>{artViewPrice(1, topBid)}</span>
                </p>
                <span className="market-sell__time">
                {active === 1 ? `남은 시간: ${leftTime}` : leftTime}
                  
                </span>
                <div className="market-sell__button">
                  <button onClick={showAucModal}>응찰하기</button>
                </div>
                {aucOpen && (
                  <ModalPortal>
                    <AuctionModal
                      setModalOpen={setAucOpen}
                      isNft={true}
                      pk={data.nft_PK}
                      leftTime={leftTime}
                      bidList={bidList}
                      setBidList={setBidList}
                      topBid={topBid}
                      setTopBid={setTopBid} />
                  </ModalPortal>
                )}
                <span className="market-sell__warn">
                  * 구매 수수료와 배송비는 별도입니다.
                </span>
              </div>
            </section>
          </div>
        </div>
        <SectionHeader title={'크리에이터 정보'} />
        <div className="market-nft-header">
          <div className="container m-nft-header-container">
            <img
              className="m-nft__image"
              src={`${userImage}/${data.origin_IMAGE}`}
              onError={handleImgError}
              alt=''
              onContextMenu={
                (e) => {
                    e.preventDefault();
                }
              }
            ></img>
            <div className="m-nft__creator">
              <h1>{checkUseName(lang, data.origin_USE_NAME, data.origin_KO_NAME, data.origin_KO_NAME, data.origin_KO_NICK_NAME,data.origin_KO_NICK_NAME)}</h1>
              <span>{checkUseName(lang === 'ko' ? 'en' : 'ko', data.origin_USE_NAME, data.origin_ENG_NAME, data.origin_ENG_NAME, data.origin_ENG_NICK_NAME,data.origin_ENG_NICK_NAME)}</span>
            </div>
          </div>
        </div>
        <div className="m-nft-detail">
          <div className="container">
            <ul className="m-nft-detail__list">
              <li>
                {data.description}
              </li>
            </ul>
            <div className="m-nft-detail__info">
              <h1>NFT 정보</h1>
              <ul className="m-nft-info__list">
                <li>
                  <span className="info-title">소유자</span>
                  <span className="info-content">{data.owner}</span>
                </li>
                <li>
                  <span className="info-title">컨트렉트 주소</span>
                  <span className="info-content">
                    {data.contract}
                  </span>
                </li>
                <li>
                  <span className="info-title">토큰 ID</span>
                  <span className="info-content">{data.token_ID}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="market-nft-deal-container">
        <SectionHeader
          title={'최근 거래 체결가'}
          moreList={moreList}
          active={menu}
          setMenu={setMenu}
        />
        {menu === 0 &&
        <div className="m-nft-deal">
          <div className="container m-nft-deal-container">
            <ul className="m-nft-deal__table">
              <li className="m-nft-table__thead">
                <div>
                  <span>거래일시</span>
                  <span>에디션</span>
                  <span>체결가</span>
                </div>
              </li>
              <li className="m-nft-table__body">
                <div>
                  <span>2000.00.00</span>
                  <span>#7</span>
                  <span>0.000ETH</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        }
      </section> */}
      <Footer />
    </div>
  )
}
export default AuctionNFTDetail
