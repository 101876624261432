import React, { useContext } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../../../components/Footer/Footer'
import Header from '../../../components/Header/Header'
import WorkHeader from '../../../components/WorkHeader/WorkHeader'
import { LoadingState } from '../../../context/loadingStateProvider'
import { WalletState } from '../../../context/walletStateProvider'
import { artViewPrice } from '../../../hook/utils/artdbView'
import { checkUseName } from '../../../hook/utils/checkUseName'
import { setComma } from '../../../hook/utils/comma'
import { workMainImage } from '../../../services/imageRoute'
import market from '../../../services/public/market/market'
import * as payAction from '../../../store/reducers/pay/actions'

import './Payment.css'

function PaymentDone() {
  const loadingState = React.useContext(LoadingState);
  const dispatch = useDispatch();

  const navigate = useNavigate()
  const walletState = useContext(WalletState);

  const { data } = useSelector(v => v.pay, shallowEqual);
  const { item, nft, bid_nft, point, delivery, order, token, orderNo } = data;
  const { lang } = useSelector(v => v.setting, shallowEqual)


  React.useEffect(() => {
    if (orderNo === ''){
      alert('잘못된 접근입니다.')
      return navigate('/')
    }
    buy()
    return () => {
      loadingState.setText('')
      loadingState.setOpen(false)
      dispatch(payAction.RESET());
    }
  }, [])

  const buy = async () => {

    loadingState.setText('결제중입니다.\n잠시만 기다려주세요.')
    loadingState.setOpen(true)

    const check = await market.certificateBuy(orderNo, token)

    if(check.RESULTCODE === undefined){
      alert('잘못된 접근입니다.');
      navigate(-1);
      return
    }

    if(check.RESULTCODE !== '0000'){
      alert(check.RESULTMSG);
      navigate(-1);
      return
    }
    
    const uniqueKey = item.unique_KEY;
    const res = await market.buy(order, delivery, uniqueKey, +point)

    if (!res.data) {
      alert('관리자에게 문의해주세요.')
      navigate('/')
      return
    }

    loadingState.setText('')
    loadingState.setOpen(false)
  }

  // const buyNft = async () => {
  //   // 이니시스
  //   loadingState.setText('결제중입니다.\n잠시만 기다려주세요.')
  //   loadingState.setOpen(true)
  //   let res;
  //   if (item.sell_TYPE === '2') {
  //     res = await market.nftBidBuy(item.nft_PK, walletState.address, +point);
  //   } else {
  //     res = await market.nftBuy(item.nft_PK, walletState.address, +point);
  //   }

  //   loadingState.setOpen(false)
  //   loadingState.setText('')

  //   if (!res.resultCode) {
  //     alert('관리자에게 문의해주세요.')
  //     return
  //   }
  //   // setPay(1)
  // }

  return (
    <div className="payment">
      <Header active="2" colored="black" detail={true} />
      <WorkHeader title_k={'주문/결제'} />
      <div className="container">
        <section className="payment-item">
          <img src={
            nft
              ? item?.image
              : `${workMainImage}/${item?.image}`
          } alt=''
          onContextMenu={
            (e) => {
                e.preventDefault();
                
            }
          }></img>
          <ul className="payment-item-info">
            <li>
              <span>작품명</span>
              <span>
                {nft
                  ? item?.name
                  // ? `${item.name.split('(')[0]}\n(${item.name.split('(')[1]}`
                  : lang === 'ko' ? item?.title : item?.eng_TITLE
                }

              </span>
            </li>
            <li>
              <span>작가명</span>
              <span>
                {
                  nft
                    ? checkUseName(lang, item?.origin_USE_NAME, item?.origin_KO_NAME, item?.origin_ENG_NAME, item?.origin_KO_NICK_NAME, item?.origin_ENG_NICK_NAME)
                    : lang === 'ko' ? item?.artist : item?.eng_ARTIST
                }
              </span>
            </li>
            {/* <li>
              <span>소장처</span>
              <span>{item.collection}</span>
            </li> */}
            <li>
              <span>판매가</span>
              <span>{artViewPrice(item?.price_UNIT, bid_nft ? item?.bid_PRICE : item?.price)}</span>
            </li>
            {!nft &&
              <li>
                <span>배송비</span>
                <span>0원</span>
              </li>
            }
            <li>
              <span>사용 포인트</span>
              <span>{setComma(point)}</span>
            </li>
            <li>
              <span>결제금액</span>
              <span>{artViewPrice(item?.price_UNIT, bid_nft ? item?.bid_PRICE : +item?.price - point)}</span>
            </li>
            {/* <li>
              <span>적립포인트</span>
              <span>000,000원</span>
            </li> */}
          </ul>
        </section>
      </div>
      <div className="payment-complete-container">
        <div className="container">
          <div className="payment-complete">
            <h1>주문이 완료되었습니다</h1>
            <h3>주문번호: {orderNo?.split('_')[2]}</h3>
            <Link style={{marginTop: '20rem'}} to={'/'} onClick={() => dispatch(payAction.RESET())}>홈으로 돌아가기</Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default PaymentDone
