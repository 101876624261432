import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import BigTopArrow from '../../../components/TopArrowSlider/BigTopArrow/BigTopArrow'
import WorkHeader from '../../../components/WorkHeader/WorkHeader'
import './MarketAll.css'
import '../../../components/ArtDetailOption/ArtDetailOption.css'
import ArtMarketDetail from '../../../components/ArtMarketDetail/ArtMarketDetail'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import * as settingAction from '../../../store/reducers/settings/actions'
import { setComma } from '../../../hook/utils/comma'
import market from '../../../services/public/market/market'

import useMobileDetect from 'use-mobile-detect-hook';
import { handleImgError, workMainImage } from '../../../services/imageRoute'
import { checkUseName } from '../../../hook/utils/checkUseName'
import { artViewDate, artViewPrice } from '../../../hook/utils/artdbView'
import publicWork from '../../../services/public/publicWork'
import publicNFT from '../../../services/public/publicNFT'

function MarketAll() {

  const dispatch = useDispatch();
  const navigate = useNavigate()

  React.useEffect(() => {
    dispatch(settingAction.SET_WORK_TYPE_LIST())
  }, [])


  const detectMobile = useMobileDetect();

  const rows = detectMobile.isMobile() ? 4 : 3
  const slidesPerRow = detectMobile.isMobile() ? 2 : 3

  const { login } = useSelector(v => v.auth, shallowEqual)
  const { rowWorkTypeList } = useSelector(v => v.setting.work, shallowEqual)
  const { lang, marketOrderList } = useSelector(v => v.setting, shallowEqual)
  const [order, setOrder] = React.useState(marketOrderList[0])
  const [marketTypeList, setMarketTypeList] = React.useState([])
  const [marketList, setMarketList] = React.useState([])
  const [resentList, setResentList] = React.useState([])

  const [saleList, setSaleList] = React.useState([])
  const [newList, setNewList] = React.useState([])
  const [menu, setMenu] = useState(0)

  const [currentPage, setCurrentPage] = React.useState(0)
  const [total, setTotal] = React.useState(0)

  React.useEffect(() => {
    getResentList()
  }, [])

  React.useEffect(() => {
    if (menu === 9) {
      getMarketNFTList(currentPage, order)
      return
    }
    getMarketList(currentPage, menu, order)
  }, [currentPage, menu, order])


  const setLike = async (data, setData, pk) => {
    if (!login) {
      alert('로그인이 필요한 서비스입니다.')
      navigate('/login', {replace:true})
      return
    }

    let res;

    if(menu === 9){
       res = await publicNFT.setLike(pk)
      if (!res.resultCode) {
        return
      }
      setData(
        data.map(
          it => it.nft_PK === pk
            ? { ...it, heart: res.message === 'ADD' }
            : it
        )
      )
    }else{
       res = await publicWork.setLike(pk)
      if (!res.resultCode) {
        return
      }
      setData(
        data.map(
          it => it.pk === pk
            ? { ...it, heart: res.message === 'ADD' }
            : it
        )
      )
    }
  }


  const getMarketList = async (page, workType, orderType) => {
    const order = marketOrderList.indexOf(orderType)
    const res = await market.getMarketListByType(page, rows * slidesPerRow, workType, order)
    setMarketList(res.list)
    setTotal(res.total)
  }

  const getMarketNFTList = async (page, orderType) => {
    const order = marketOrderList.indexOf(orderType)
    const res = await market.getMarketNFTListByType(page, rows * slidesPerRow, order)
    setMarketList(res.nftList)
    setTotal(res.total)
  }

  const getResentList = async () => {
    const res = await market.getMarketListByType(0, 10, 0, 0)
    setResentList(res.list)
  }


  const payment = (work) => {

    if(!login){
      alert('로그인이 필요한 서비스입니다.');
      navigate('/login', {replace:true})

      return
    }

    navigate('/market/payment', { state: {item: work, sellType: '판매'} })
  }

  React.useEffect(() => {
    const item = [];
    let total = 0;
    // for (let i = 0; i < rowWorkTypeList.length; i++) {
    //nft 제거
    for (let i = 0; i < rowWorkTypeList.length -1; i++) {
      item.push(`${rowWorkTypeList[i][`${lang}`]}(${setComma(rowWorkTypeList[i].market_count)})`)
      total = total + rowWorkTypeList[i].market_count
    }
    item.unshift(`전체(${setComma(total)})`)
    setMarketTypeList(item)
  }, [lang, rowWorkTypeList])

  React.useEffect(() => {
    const result = []
    if (marketList === null || marketList.length === 0) {
      setSaleList(result)
      return
    }
    for (let i = 0; i < marketList.length; i++) {
      result.push(
        <div className="market-nft slider__item">
          <Link
            to={
              menu === 9
                ? `/market/nft/${marketList[i].nft_PK}`
                : `/market/${marketList[i].pk}`
            }
          >
            <div>
              <img src={
                menu === 9
                  ? marketList[i].image
                  : `${workMainImage}/${marketList[i].image}`
              } alt="item" onError={handleImgError}
              onContextMenu={
                (e) => {
                    e.preventDefault();
                }
              }
              ></img>
              <h3>
                {
                  menu === 9
                    ? checkUseName(lang, marketList[i].origin_USE_NAME, marketList[i].origin_KO_NAME, marketList[i].origin_ENG_NAME, marketList[i].origin_KO_NICK_NAME, marketList[i].origin_ENG_NICK_NAME)
                    // : checkUseName(lang, marketList[i].use_NAME, marketList[i].artist, marketList[i].eng_ARTIST, marketList[i].ko_NICK_NAME, marketList[i].ko_NICK_NAME)
                    // : checkUseName(lang, marketList[i].use_NAME, marketList[i].artist, marketList[i].eng_ARTIST, marketList[i].ko_NICK_NAME, marketList[i].eng_ARTIST)
                    : lang === 'ko' ? marketList[i].artist : marketList[i].eng_ARTIST
                }
              </h3>
              <h2>{
                menu === 9 
                ? marketList[i].name
              :lang === 'ko' ? marketList[i].title : marketList[i].eng_TITLE
              }</h2>
              <h4>
              {
                  menu === 9
                  ? marketList[i].made_IN
              // : artViewDate(marketList[i].start_CREATE, marketList[i].end_CREATE)
              :  marketList[i].start_CREATE !== '미상' ? `${marketList[i].start_CREATE.includes('-') ? marketList[i].start_CREATE.split('-')[0] : marketList[i].start_CREATE}년` : '미상'
              }
              </h4>
            </div>
          </Link>
          <div className="market-item">
            <span className="nft-price"></span>
            <div className="nft-button market-item--button ">
              <span style={{color:'#2F80ED'}}>{artViewPrice(marketList[i].price_UNIT, marketList[i].price)} </span>
              <button href="#" className={marketList[i].heart ? "market-item--heart_active" : "market-item--heart"} onClick={() => setLike(marketList, setMarketList, menu === 9 ? marketList[i].nft_PK : marketList[i].pk)}>
                <span className="ir_pm">좋아요</span>
              </button>
            </div>
          </div>
          <button className='market-all__buy_button'  onClick={() => {
              payment(marketList[i])
            }}>
              구매하기
            </button>
            {/* <Link
              to={'/market/payment'}
              state={{
                item: work,
              }}
            >
              구입하기
            </Link> */}
        </div>
      )
    }
    setSaleList(result)
    return

  }, [marketList, menu])


  React.useEffect(() => {
    const result = [];
    if (resentList === null || resentList.length === 0) {
      setNewList(result)
      return
    }
    for (let k = 0; k < resentList.length; k++) {
      const json = {
        pk: resentList[k].pk,
        info: `${workMainImage}/${resentList[k].image}`,
        // author: checkUseName('ko', resentList[k].use_NAME, resentList[k].ko_NAME, resentList[k].ko_NAME, resentList[k].ko_NICK_NAME, resentList[k].ko_NICK_NAME),
        // author_e: checkUseName('en', resentList[k].use_NAME, resentList[k].eng_NAME, resentList[k].eng_NAME, resentList[k].eng_NICK_NAME, resentList[k].eng_NICK_NAME),
        author: resentList[k].artist,
        author_e: resentList[k].eng_ARTIST,
        title: lang === 'ko' ? resentList[k].title : resentList[k].eng_TITLE,
        date: artViewDate(resentList[k].start_CREATE, resentList[k].end_CREATE),
        price: artViewPrice(resentList[k].price_UNIT, resentList[k].price),
        heart: resentList[k].heart,
        link: '/market'
      }
      result.push(json)
    }
    setNewList(result)
  }, [resentList])

  return (
    <section className="market-all">
      <BigTopArrow
        title_e={'Art Now'}
        title_k={'신규 작품'}
        items={newList}
        setItems={setNewList}
        action={setLike}
        breakRows={2}
        breakSlidesPerRow={1}
      />
      <WorkHeader
        moreList={marketTypeList}
        moreListChange={setMenu}
        moreMenu={menu}
        moreActive={true}
        title_k={'ALL'}
      ></WorkHeader>
      <div className="market-all-slider">
        <ArtMarketDetail
          rows={rows}
          slidersPerRow={slidesPerRow}
          total={total}
          options={marketOrderList}
          order={order}
          setOrder={setOrder}
          items={saleList}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </section>
  )
}
export default MarketAll
