import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import auth from '../../../services/auth/auth'
import Footer from '../../Footer/Footer'
import Header from '../../Header/Header'

import { checkDate, passCheck } from '../../../hook/utils/validation'
import { SignupStepper } from '../SignupStepper'
import useMobileDetect from 'use-mobile-detect-hook'
import './SignUpGeneral.css'
import { checkCapsLock } from '../../../hook/utils/checkCapsLock'
import { shallowEqual, useSelector } from 'react-redux'

function SignUpGeneral() {
  const { min, today } = useSelector(v => v.setting.date, shallowEqual)
  const data = useLocation().state;

  // const data = {
  //   token: 'token',
  //   next: '1',
  //   email: 'aksgml223@aver.com',
  //   phone: '01057039770',
  //   name: '이만희',
  //   type: 'LOCAL',
  //   kyc: '',
  // }

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);
  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  const navigate = useNavigate();

  const [koFirstName, setKoFirstName] = React.useState('');
  const [koLastName, setKoLastName] = React.useState('');
  const [enFirstName, setEnFirstName] = React.useState('');
  const [enLastName, setEnLastName] = React.useState('');

  const [phone, setPhone] = React.useState('');
  const [birthday, setBirthday] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [checkPassword, setCheckPassword] = React.useState('');

  const [showPassword, setShowPassword] = React.useState(false);
  const [showCheckPassword, setShowCheckPassword] = React.useState(false);

  const [agree1, setAgree1] = React.useState(false);
  const [agree2, setAgree2] = React.useState(false);
  const [showAgree2, setShowAgree2] = React.useState(false);
  const [agree3, setAgree3] = React.useState(false);
  const [showAgree3, setShowAgree3] = React.useState(false);
  const [agree4, setAgree4] = React.useState(false);
  const [showAgree4, setShowAgree4] = React.useState(false);

  const agreeAll = e => {
    setAgree1(e.target.checked)
    setAgree2(e.target.checked)
    setAgree3(e.target.checked)
    setAgree4(e.target.checked)
  }


  const detectMobile = useMobileDetect();

  const ko_pattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; //한글
  const en_pattern = /[a-zA-Z]/; //영어

  const [isCapsLockOn1, setIsCapsLockOn1] = React.useState(false);
  const [isCapsLockOn2, setIsCapsLockOn2] = React.useState(false);


  React.useEffect(() => {
    setUserInfo()
  }, [])

  const setUserInfo = () => {
    // // console.log(data)
    // if (ko_pattern.test(data.name)) {
    //   setKoName(data.name)
    // }
    // if (en_pattern.test(data.name)) {
    //   setEnName(data.name)
    // }

    setPhone(data.phone)
    setBirthday(data.birth)
  }

  const done = async () => {

    if (koFirstName === '' || koLastName === '') {
      if (enFirstName === '' || enLastName === '') {
        alert('한글 성, 이름 또는 영어 성, 이름을 입력해주세요.')
        return
      }
    }

    if (enFirstName === '' || enLastName === '') {
      if (koFirstName === '' || koLastName === '') {
        alert('한글 성, 이름 또는 영어 성, 이름을 입력해주세요.')
        return
      }
    }

    if (phone === '') {
      alert('휴대폰 번호를 입력해주세요.')
      return
    }
    if (birthday === '') {
      alert('생년월일을 입력해주세요.')
      return
    }
    if (data.type === 'LOCAL') {
      if (password === '' || checkPassword === '') {
        alert('비밀번호를 입력해주세요.')
        return
      }
      if (password !== checkPassword) {
        alert('동일한 비밀번호를 입력해주세요.');
        return;
      }
      if (!passCheck(password)) {
        setPassword('')
        setCheckPassword('')
        return
      }
    }


    if (!agree2) {
      alert('약관 동의를 해주세요.')
      return
    }
    if (!agree3) {
      alert('개인정보 수집 및 이용 동의를 해주세요.')
      return
    }

    const depart = phone.split(')')
    var zip;
    var number;

    if (depart.length === 1) {
      zip = '+82';
      number = depart[0].replaceAll('-', '')
    } else {
      zip = depart[0]
      number = depart[1].replaceAll('-', '')
    }

    let res;
    if (data.type === 'LOCAL') {
      res = await auth.generalSignUp(data.token, password, koFirstName, koLastName, enFirstName, enLastName, number, zip, birthday, data.kyc)
    } else {
      res = await auth.generalOauthSignUp(data.email, data.type, koFirstName, koLastName, enFirstName, enLastName, number, zip, birthday, data.kyc)
    }
    if (!res.resultCode) {
      alert('관리자에게 문의해주세요.')
      return
    }
    localStorage.setItem('QART_ACCESS', res.value);
    navigate("/signUp/end", { replace: true, state: { next: data.next } });
  }

  return (
    <div className="sign-general">
      <Header />
      <section className="container sign-general-container">
        <SignupStepper type={data.type} active={data.type === "LOCAL" ? 3 : 2} />
        <div className="general-content">
          <h2 className="general-title">회원 아이디 *</h2>
          <div className="general-input-container">
            <input
              autoComplete='off'
              type="text"
              className="general-input mb-8"
              value={data.email}
              readOnly
              required
            ></input>
          </div>
          {data.type === 'LOCAL' &&
            <>
              <h2 className="general-title">비밀번호 *</h2>
              <div className="general-password">
                <input
                  autoComplete='off'
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  className={isCapsLockOn1 ? "general-password-input only-ganeral--capslock" : "general-password-input only-ganeral"}
                  onKeyUp={e => checkCapsLock(e, setIsCapsLockOn1)}
                  placeholder="영문, 특수문자, 숫자 포함 8~20자 이내"
                  required
                ></input>
                {
                  isCapsLockOn1 &&
                  <div className="capslock__icon">
                    <span className="ir_pm">CapsLock</span>
                  </div>
                }
                <button className="password__icon" onClick={() => setShowPassword(!showPassword)}>
                  <span className="ir_pm">가리기</span>
                </button>
              </div>

              <h2 className="general-title">비밀번호 확인 *</h2>
              <div className="general-password">
                <input
                  autoComplete='off'
                  type={showCheckPassword ? "text" : "password"}
                  value={checkPassword}
                  onChange={e => setCheckPassword(e.target.value)}
                  className={isCapsLockOn2 ? "general-password-input only-ganeral--capslock" : "general-password-input only-ganeral"}
                  onKeyUp={e => checkCapsLock(e, setIsCapsLockOn2)}
                  placeholder="영문, 특수문자, 숫자 포함 8~20자 이내"
                  required
                ></input>
                {
                  isCapsLockOn2 &&
                  <div className="capslock__icon">
                    <span className="ir_pm">CapsLock</span>
                  </div>
                }
                <button className="password__icon" onClick={() => setShowCheckPassword(!showCheckPassword)}>
                  <span className="ir_pm">가리기</span>
                </button>
              </div>
            </>
          }
          <h2 className="general-title ">이름(NAME) *</h2>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className='general-sub'>성</span>
            <input
              autoComplete='off'
              type="text"
              value={koFirstName}
              onChange={e => setKoFirstName(e.target.value)}
              className="general-input mb-8"
              placeholder="한글 성"
              required
            ></input>
          </div>
          <div style={matches ? { marginTop: '10rem', display: 'flex', flexDirection: 'column' } : { display: 'flex', flexDirection: 'column' }}>
            <span className='general-sub'>이름</span>
            <input
              autoComplete='off'
              type="text"
              value={koLastName}
              onChange={e => setKoLastName(e.target.value)}
              className="general-input"
              placeholder="한글 이름"
              required
            ></input>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className='general-sub'>Family name</span>
            <input
              autoComplete='off'
              type="text"
              value={enFirstName}
              onChange={e => setEnFirstName(e.target.value)}
              className="general-input mb-8"
              placeholder="Family name"
              required
            ></input>
          </div>
          <div style={matches ? { marginTop: '10rem', display: 'flex', flexDirection: 'column' } : { display: 'flex', flexDirection: 'column' }}>
            <span className='general-sub'>Given name</span>
            <input
              autoComplete='off'
              type="text"
              value={enLastName}
              onChange={e => setEnLastName(e.target.value)}
              className="general-input"
              placeholder="Given name"
              required
            ></input>
          </div>


          <h2 className="general-title">휴대폰 번호 *</h2>
          <input
            autoComplete='off'
            type="text"
            value={phone}
            readOnly
            // maxLength={13}
            className="general-input"
            placeholder="- 없이 숫자만 입력"
            required
          ></input>
          <h2 className="general-title">생년월일 *</h2>
          <input
            autoComplete='off'
            type={data.birth === '' || data.birth === undefined ? "date" : "text"}
            min={min}
            max={today}
            value={birthday}
            readOnly={!(data.birth === '' || data.birth === undefined)}
            onChange={e => checkDate(setBirthday, e)}
            className="general-input date-input"
          ></input>
        </div>
        <div className="general-option">
          <label className="login-option text-option-underline">
            <input autoComplete='off' type="checkbox" checked={agree1} onChange={agreeAll} />
            <span className="login-option__check" />
            <span className="option-text" > 전체 동의</span>
          </label>
          <label className="login-option text-option-underline" style={{ paddingLeft: detectMobile.isMobile() ? '13rem' : '30rem' }}>
            <input autoComplete='off' type="checkbox" checked={agree2} onChange={e => {
              setAgree1(false)
              setAgree2(e.target.checked)

              if (showAgree2) return;
              setShowAgree2(true);
              window.open('/terms/condition')
            }} />
            <span className="login-option__check" />
            <span className="option-text" >
              (필수) 이용약관 동의 >
            </span>

          </label>
          <label className="login-option text-option-underline" style={{ paddingLeft: detectMobile.isMobile() ? '13rem' : '30rem' }}>
            <input autoComplete='off' type="checkbox" checked={agree3} onChange={e => {
              setAgree1(false)
              setAgree3(e.target.checked)

              if (showAgree3) return;
              setShowAgree3(true);
              window.open('/terms/privacy')
            }} />
            <span className="login-option__check" />
            <span className="option-text">
              (필수) 개인정보 수집 및 이용 동의 >
            </span>

          </label>
          <label className="login-option text-option-underline" style={{ paddingLeft: detectMobile.isMobile() ? '13rem' : '30rem' }}>
            <input autoComplete='off' type="checkbox" checked={agree4} onChange={e => {
              setAgree1(false)
              setAgree4(e.target.checked)

              if (showAgree4) return;
              setShowAgree4(true);
              window.open('/terms/promotion')
            }} />
            <span className="login-option__check" />
            <span className="option-text">
              (선택) 홍보 및 마케팅 정보 활용 및 수신 동의 >
            </span>
          </label>
        </div>
        <div className="general-next">
          {/* <button onClick={() => navigate(-1)}>이전</button> */}
          <button onClick={done} className="general-next-button">완료</button>
        </div>
      </section>
      <Footer />
    </div>
  )
}
export default SignUpGeneral
