import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { workMainImage } from '../../services/imageRoute'
import WorkHeader from '../WorkHeader/WorkHeader'
import { useSelector, shallowEqual } from "react-redux";

import './ArtNotMoveDetail.css'
import { checkUseName } from '../../hook/utils/checkUseName';
import { artViewDate, artViewPrice } from '../../hook/utils/artdbView';
import publicWork from '../../services/public/publicWork'

function ArtNotMoveDetail(props) {

  const navigate = useNavigate();
  const { lang } = useSelector(v => v.setting, shallowEqual)
  const { login } = useSelector(v => v.auth, shallowEqual)
  const [result, setResult] = React.useState([])
  const { items, setItems, type } = props;

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);

  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  const navigateByType = (pk,isNft) => {
    if(isNft){
      navigate(`/market/nft/auction/${pk}`, {state: {active: 1}})
    }else{
      navigate(`/market/work/auction/${pk}`, {state: {active: 1}})
    }
    return
  }

  const setLikeWork = async ( pk) => {
    
    if (!login) {
      alert('로그인이 필요한 서비스입니다.')
      navigate('/login', {replace:true})

      return
    }

    const res = await publicWork.setLike(pk)
    if (!res.resultCode) {
      return
    }

    setItems(
      items.map(
        it => it.work_PK === pk
          ? { ...it, heart: res.message === 'ADD' }
          : it
      )
    )
  }

  React.useEffect(() => {
    TableItem(items)
  }, [items])

  const TableItem = (items) => {
    const myItem = []

    if(items.length === 0){
      setResult(myItem)
      return
    }

    for (let i = 0; i < items.length; i++) {

      if(props.isMain){
        if(matches && i >= 2){
          break;
        }

        myItem.push(
          <div key={items[i].nft_PK}>
            <div className="art-md__list-item" style={{width:matches?'146rem':'390rem', marginLeft:0,paddingLeft:'0rem'}}>
              <img src={`${workMainImage}/${items[i].workInfo.image}`} alt="item" onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img>
              <h3 >{lang === 'ko' ? items[i].workInfo.artist : items[i].workInfo.eng_ARTIST }</h3>
              <h2>{lang === 'ko' ? items[i].workInfo.title : items[i].workInfo.eng_TITLE }</h2>
              <h4>
                {items[i].made_IN}
                {artViewDate(items[i].workInfo.start_CREATE, items[i].workInfo.end_CREATE)}
              </h4>
            <div className="market-item" style={{width:matches?'146rem':'390rem', marginTop:matches?'6rem':'10rem',marginLeft:0, height:'100%', padding:0}}></div>
              <div className="market-item--price" style={{display:'flex', flexDirection:'row', justifyContent:'space-between', }}>
                <span style={{color:'#4f4f4f', fontWeight:400}}>시작가</span>
                <span style={{color:'#222222'}}>{artViewPrice(1, items[i].start_PRICE)}</span>
              </div>
              <div className="market-item--price" style={{display:'flex', flexDirection:'row', justifyContent:'space-between', }}>
              <span style={{color:'#4f4f4f', fontWeight:400}}>현재가</span>
              <span style={{color:'#eb5757'}}>{artViewPrice(1, items[i].top_PRICE)}</span>
            </div>
            <div>
            <div className="market-item" style={{width:matches?'146rem':'390rem', marginTop:matches?'6rem':'10rem',marginLeft:0}}>
                <button onClick={() => navigateByType(items[i].work_PK, false)} className="buy-button" style={matches ? {width:78} : {}}>응찰하기</button>
                <button onClick={() => setLikeWork(items[i].work_PK)} className={items[i].heart ? "market-item--heart_active" : "market-item--heart"} style={{display:'flex', marginTop: matches ? '16rem' : '36rem', width: matches ? '20rem' : '46rem',height: matches ? '20rem' : '46rem'}}>
                  <span className="ir_pm">좋아요</span>
                </button>
              </div>
            </div>
              
            </div>
          </div>
        )
      }else{

        myItem.push(
          <div key={items[i].nft_PK}>
          <div className="art-md__list-item">
            <img src={`${workMainImage}/${items[i].workInfo.image}`} alt="item" onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              } ></img>
            <h3 >{lang === 'ko' ? items[i].workInfo.artist : items[i].workInfo.eng_ARTIST }</h3>
            <h2>{lang === 'ko' ? items[i].workInfo.title : items[i].workInfo.eng_TITLE }</h2>
            <h4>
              {items[i].made_IN}
              {artViewDate(items[i].workInfo.start_CREATE, items[i].workInfo.end_CREATE)}
            </h4>
          <div className="market-item" style={{width:matches?'100rem':'400rem', marginTop:matches?'6rem':'10rem',marginLeft:0, height:'100%', padding:0}}></div>
            <div className="market-item--price" style={{display:'flex', flexDirection:'row', justifyContent:'space-between', }}>
              <span style={{color:'#4f4f4f', fontWeight:400}}>시작가</span>
              <span style={{color:'#222222'}}>{artViewPrice(1, items[i].start_PRICE)}</span>
            </div>
            <div className="market-item--price" style={{display:'flex', flexDirection:'row', justifyContent:'space-between', }}>
            <span style={{color:'#4f4f4f', fontWeight:400}}>현재가</span>
            <span style={{color:'#eb5757'}}>{artViewPrice(1, items[i].top_PRICE)}</span>
          </div>
          <div>
          <div className="market-item" style={{width:matches?'100rem':'400rem', marginTop:matches?'6rem':'10rem',marginLeft:0}}>
              <button onClick={() => navigateByType(items[i].work_PK, false)} className="buy-button" style={matches ? {width:78} : {}}>응찰하기</button>
              <button onClick={() => setLikeWork(items[i].work_PK)} className={items[i].heart ? "market-item--heart_active" : "market-item--heart"} style={{display:'flex', marginTop: matches ? '16rem' : '36rem', width: matches ? '20rem' : '46rem',height: matches ? '20rem' : '46rem'}}>
                <span className="ir_pm">좋아요</span>
              </button>
            </div>
          </div>
            
          </div>
          </div>
        )
      }

    }
    setResult(myItem)
  }

  return (
    <div className="art-md">
      <section className="art-md--content">

        {
          props.isMain && result.length == 0 ?
          (
            <div className='empty_img' />     
          )
          :
          (

            <div className="container">
            <section className="art-md--content__list">
                {result}
            </section>
          </div>
          )
        }

      </section>
    </div>
  )
}
export default ArtNotMoveDetail
