import React, { useState } from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import { useNavigate } from 'react-router-dom'
import auth from '../../services/auth/auth'
import { passCheck } from '../../hook/utils/validation'
import useMobileDetect from 'use-mobile-detect-hook'

export const UserTakeOverPw = () => {
    const isMobile = useMobileDetect().isMobile();
    const navigate = useNavigate();
    const [pin, setPin] = useState("")
    const [rePin, setRePin] = useState("")
    const [showPin, setShowPin] = useState(false)
    const [showRePin, setShowRePin] = useState(false)


    const changedPw = async () => {

        if(pin === '' || rePin === ''){
            alert('변경하실 비밀번호를 입력해주세요.')
            return
        }
        if(pin !== rePin){
            alert('동일한 비밀번호를 입력해주세요.')
            return;
        }


        if(!passCheck(pin)){
            setPin('');
            setRePin('');
            return
        }
          
        const res = await auth.artsynapseChangedPw(pin);

        if(!res.resultCode){
            alert('관리자에게 문의해주세요.')
            return;
        }
        alert('비밀번호가 변경되었습니다.')
        navigate('/')
    }

    return (

        <div className="login-main">
            <Header detail={true} />
            <div className="container login-container">
                <h1>비밀번호 변경</h1>
                <h3 style={isMobile ? {padding: '10rem 0'} :{ padding: '30rem 0' }}>사용하실 비밀번호를 입력해주세요.</h3>
                <section className="login-form">
                    <>
                        <h2 className="email-title">비밀번호</h2>
                        <div className="login-password">
                            <input
                                autoComplete='off'
                                type={showPin ? "text" : "password"}
                                className="login-input"
                                placeholder="비밀번호"
                                value={pin}
                                onChange={e => setPin(e.target.value)}
                                required
                            ></input>
                            <button className="password__icon" onClick={() => setShowPin(!showPin)}>
                                <span className="ir_pm">가리기</span>
                            </button>
                        </div>
                        <h2 className="email-title">비밀번호 재입력</h2>
                        <div className="login-password">
                            <input
                                autoComplete='off'
                                type={showRePin ? "text" : "password"}
                                className="login-input"
                                placeholder="비밀번호 재입력"
                                value={rePin}
                                onChange={e => setRePin(e.target.value)}
                                required
                            ></input>
                            <button className="password__icon" onClick={() => setShowRePin(!showRePin)}>
                                <span className="ir_pm">가리기</span>
                            </button>
                        </div>
                        <div className="auth-next" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <button onClick={() => navigate('/')} className={false ? "auth-next-button" : ''}>다음에 변경</button>
                            <button onClick={changedPw} className={true ? "auth-next-button" : ''}>완료</button>
                        </div>
                    </>
                </section>
            </div>
            <Footer />
        </div>
    )
}
