
import errorImage from '../assets/default.png'

const baseURL = process.env.REACT_APP_BASE_URL;
const baseIP = process.env.REACT_APP_BASE_IP;

export const handleImgError = e => {
    e.target.src = errorImage;
}

// 기관 등록증
export const certificateOfRegistration = `${baseURL}/upload/article-main/agency`



// 서명 이미지
export const sign = `${baseURL}/upload/sign`


// nft 민팅용
export const nftMintingImage = `${baseIP}/upload/work-main`

// nft 불러오기
export const nftImage = `${baseIP}/upload/work-main`

// 작품 메인 이미지
export const workMainImage = `${baseURL}/upload/work-main`

// 작품 서브 이미지
export const workSubImage = `${baseURL}/upload/work-sub`

// 전시회  이미지
export const exhibitionImage = `${baseURL}/upload/exh-main`


// 아티클 메인 이미지
export const articleMainImage = `${baseURL}/upload/article-main`

// 아티클 서브 이미지
export const articleSubImage = `${baseURL}/upload/article-sub`

// 유저 이미지
export const userImage = `${baseURL}/upload/profile`

// news 이미지
export const newsImage = `${baseURL}/upload/admin/news`
// topic 이미지
export const topicImage = `${baseURL}/upload/admin/news`


// topic 이미지
export const bannerImage = `${baseURL}/upload/banner`


// QCoA pdf
export const coaImage = `${baseURL}/upload/COA`


// 큐톡 이미지
export const qTalkImage = `${baseURL}/upload/q-talk/thumbnail`