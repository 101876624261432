import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useSelector, shallowEqual } from 'react-redux'

import { useDispatch } from "react-redux";
import * as authAction from '../../store/reducers/auth/actions'
import './Sidebar.css'

const Sidebar = props => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { login, type } = useSelector(v => v.auth, shallowEqual);

  const logout = () => {
    alert('로그아웃 되었습니다.')
    localStorage.removeItem('QART_ACCESS')
    dispatch(authAction.LOGOUT())
  }

  const goMyPage = () => {
    switch(type){
      case 0:
        return navigate('/mypage/general')
        case 1:
        return navigate('/mypage/artist')
        case 2:
        return navigate('/mypage/gallery')
      default:
        return
    }
  }


  useEffect(() => {
    document.body.style = `overflow: hidden`
    return () => (document.body.style = `overflow: auto`)
  }, [])
  return (
    <div className="side-container">
      <div className="side-bar">
        <ul className="side-bar-list">
          <li>
            <a href="/">
              <h2 className={props.active === '0' ? 'active' : ''}>
                Art Database
              </h2>
            </a>
          </li>
          <li>
            <a href="/qcoa/">
              <h2 className={props.active === '1' ? 'active' : ''}>Q-CoA</h2>
            </a>
          </li>
          <li>
            <a href="/market">
              <h2 className={props.active === '2' ? 'active' : ''}>
                Marketplace
              </h2>
            </a>
          </li>
          <li>
            <a href="/support/">
              <h2 className={props.active === '3' ? 'active' : ''}>Support</h2>
            </a>
          </li>
          {
            login &&
            <li>
            <h2 className="mypage" onClick={goMyPage} style={{cursor: 'pointer'}}>마이페이지</h2>
          </li>
          }
        </ul>
        <div className="side-log-out">
          {
            login ?
            <Link className="logout" to="/login" onClick={logout}>
                <span className="log-out-icon"></span>
                <h2>Log-out</h2>
            </Link> :
            <Link className="login" to="/login">
              <span className="log-out-icon"></span>
              <h2>Log-in</h2>
          </Link>
          }
          {/* <Link>
            <span className="log-out-icon"></span>
            <h2>로그아웃</h2>
          </Link> */}
        </div>
      </div>
    </div>
  )
}

export default Sidebar
