import React from 'react'
import MarketHeader from '../Header/MarketHeader'
import './MarketMain.css'

import { shallowEqual, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import ArtTopic from '../../../components/ArtTopic/ArtTopic'
import BigTopArrow from '../../../components/TopArrowSlider/BigTopArrow/BigTopArrow'
import { bannerImage, qTalkImage, userImage, workMainImage } from '../../../services/imageRoute'

import useMobileDetect from 'use-mobile-detect-hook'
import ArtNotMoveDetail from '../../../components/ArtNotMoveDetail/ArtNotMoveDetail'
import AllSlide from '../../../components/BannerSlide/AllSlide'
import { artViewDate, artViewPrice } from '../../../hook/utils/artdbView'
import { checkUseName } from '../../../hook/utils/checkUseName'
import market from '../../../services/public/market/market'
import marketPick from '../../../services/public/market/pick'
import publicWork from '../../../services/public/publicWork'
import ArtHotTopic from '../../../components/ArtTopic/ArtHotTopic'
import { setBidTime } from '../../../hook/utils/getDateStr'

function MarketMain() {
  const [myCount, setMyCount] = React.useState(2)
  const detectMobile = useMobileDetect();

  const { login } = useSelector(v => v.auth, shallowEqual)
  const { lang, marketBannerList } = useSelector(v => v.setting, shallowEqual)
  const navigate = useNavigate()

  const [banner, setBanner] = React.useState([])

  const [qTalk, setQTalk] = React.useState([]);
  const [qTalkItem, setQTalkItem] = React.useState([]);
  const [hot, setHot] = React.useState([]);
  const [hotItem, setHotItem] = React.useState([]);
  const [qPlace, setQPlace] = React.useState([]);
  const [recommend, setRecommend] = React.useState([]);
  const [items, setItems] = React.useState([]);

  const auctionList = ['past', 'now', 'coming'];
  // nft 삭제
  const auctionType = ['실물'];
  // const auctionType = ['실물', 'NFT'];
  const [menu, setMenu] = React.useState(1)
  const [typeMenu, setTypeMenu] = React.useState(0)

  const [nftList, setNftList] = React.useState([])
  const count = detectMobile.isMobile() ? 3 : 3


  React.useEffect(() => {
    getList(typeMenu, menu)
  }, [typeMenu, menu])

  React.useEffect(() => {
    getQPlace()
    getRecommend()
    getHot()
    getQTalk()
  }, [])

  React.useEffect(() => {
    getHotItem(hot)
  }, [hot])

  React.useEffect(() => {
    getQTalkItem(qTalk)
  }, [qTalk])


  const getList = async (type, currentType) => {
    const res = await market.getAuctionList(type, 0, count, currentType, 3);
    if (type === 0) {
      setNftList(res.list)
    } else {
      setNftList(res.nftList)
    }
  }

  const getQPlace = async () => {
    const res = await marketPick.getQPlace()
    const data = res.list

    const myItem = [];
    for (let i = 0; i < data.length; i++) {
      const json = {
        id: data[i].user_id,
        info: `${userImage}/${data[i].image}`,
        author: lang === 'ko' ? data[i].name : data[i].eng_name,
        title: data[i].address,
        created: data[i].address1,
        price: '',
        link: '/artdb/venue',
      };
      myItem.push(json)
    }
    setQPlace(myItem)
  }

  const getRecommend = async () => {
    const res = await marketPick.getCurator()
    // const data = detectMobile.isMobile() ? res.list.slice(0, 5) : res.list;
    const data = res.list.slice(0, 5)
    // const data = res.list;
    const myItem = [];

    for (let i = 0; i < data.length; i++) {
      const json = {
        pk: data[i].pk,
        info: `${workMainImage}/${data[i].image}`,
        author: lang === 'ko' ? data[i].artist : data[i].eng_ARTIST,
        title: lang === 'ko' ? data[i].title : data[i].eng_TITLE,
        created: artViewDate(data[i].start_CREATE, data[i].end_CREATE),
        price: artViewPrice(data[i].price_UNIT, data[i].price),
        link: '/market', //이전 코드
        //link: `/artdb/venue/${data[i].user_id}`, //기관 상세페이지에 대한 고유값? 을 알 수 없어서 작업을 할 수 없음
        // -> 추천작은 큐픽으로, 큐플은 작업 완료해두었습니다 팀장님 -이만희
        heart: data[i].heart,
      }
      myItem.push(json)
    }
    setRecommend(myItem)
  }

  const getHot = async () => {
    // const count = detectMobile.isMobile() ? 4 : 5
    const res = await marketPick.getHot(5)
    const data = res.list

     
    let list = [];

    for(let i = 0; i < data.length; i ++){
      const json = {
        link: `./${data[i].pk}`,
        img: `${workMainImage}/${data[i].image}`,
        pk: data[i].pk,
        title: data[i].artist,
        sub: data[i].title,
        price: artViewPrice(data[i].price_UNIT, data[i].price)
      }
      list.push(json);
    }

    setHot(list)
  }

  const getQTalk = async () => {
    // const count = detectMobile.isMobile() ? 4 : 5
    const res = await marketPick.getQTalk(0, 5)
    const data = res.data.content
  
    let list = [];

    for(let i = 0; i < data.length; i ++){
      const json = {
        link: `./qartstalk/${data[i].pk}`,
        img: `${qTalkImage}/${data[i].thumbNail}`,
        pk: data[i].pk,
        title: data[i].title,
        sub: setBidTime(data[i].createTime)
      }
      list.push(json);
    }
    setQTalk(list)
  }


  const getQTalkItem = () => {
    if (qTalk.length === 0) {
      return
    }

    const isMobile = detectMobile.isMobile() || window.innerWidth < 480;

    if (isMobile) {
      setQTalkItem(
        <div className="hot-click-content container">
          <div className="hot-click-sub">
            {
              qTalk.length >= 1 &&
              <img src={`${qTalkImage}/${qTalk[0].thumbNail}`} alt='' onClick={() => navigate(`./qartstalk/${qTalk[0].pk}`)} onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }/>
            }
            {
              qTalk.length >= 2 &&
              <img src={`${qTalkImage}/${qTalk[1].thumbNail}`} alt='' onClick={() => navigate(`./qartstalk/${qTalk[1].pk}`)} onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              } />}
            {
              qTalk.length >= 3 &&
              <img src={`${qTalkImage}/${qTalk[2].thumbNail}`} alt='' onClick={() => navigate(`./qartstalk/${qTalk[2].pk}`)} onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }/>}
            {
              qTalk.length >= 4 &&
              <img src={`${qTalkImage}/${qTalk[3].thumbNail}`} alt='' onClick={() => navigate(`./qartstalk/${qTalk[3].pk}`)}  onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }/>}
          </div>
        </div>
      )
    } else {
      setQTalkItem(
        <div className="hot-click-content container">
          <Link to={`./qartstalk/${qTalk[0].pk}`} >
            <div className="hot-click-main">
              <img src={`${qTalkImage}/${qTalk[0].thumbNail}`} alt='' onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }/>
            </div>
          </Link>
          <div className="hot-click-sub">
            {
              qTalk.length >= 2 &&
              <img src={`${qTalkImage}/${qTalk[1].thumbNail}`} alt='' onClick={() => navigate(`./qartstalk/${qTalk[1].pk}`)} onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              } />
            }
            {
              qTalk.length >= 3 &&
              <img src={`${qTalkImage}/${qTalk[2].thumbNail}`} alt='' onClick={() => navigate(`./qartstalk/${qTalk[2].pk}`)} onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              } />}
            {
              qTalk.length >= 4 &&
              <img src={`${qTalkImage}/${qTalk[3].thumbNail}`} alt='' onClick={() => navigate(`./qartstalk/${qTalk[3].pk}`)} onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              } />}
            {
              qTalk.length >= 5 &&
              <img src={`${qTalkImage}/${qTalk[4].thumbNail}`} alt='' onClick={() => navigate(`./qartstalk/${qTalk[4].pk}`)} onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              } />}
          </div>
        </div>
      )
    }
  }


  const getHotItem = () => {
    if (hot.length === 0) {
      return
    }

    setHotItem(
      <div className="hot-click-content container art-topic-content ">
        <Link to={`./${hot[0].pk}`} className="art-topic-item artTopic_img artTopic_bg">
          <div className="hot-click-main hot-click-item">
            <img src={`${workMainImage}/${hot[0].image}`} alt='' className='art-topic--main' onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img>

            <div className='artTopic__main_hover_text'>
              <div className='artTopic_hover_text__div' style={{gap: '20rem'}}>
                <span>{hot[0].artist}</span>
                <h4>{hot[0].title}</h4>
                <h2>{artViewPrice(hot[0].price_UNIT, hot[0].price)}</h2>
              </div>
            </div>
          </div>
        </Link>
        <div className="hot-click-sub hot-click-item">
          {
            hot.length >= 2 &&
            <Link to={`./${hot[1].pk}`} className="art-topic-item artTopic_img artTopic_bg">
              <img src={`${workMainImage}/${hot[1].image}`} alt='' className='art-topic--sub' onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }/>
              <div className='artTopic__sub_hover_text'>
              <div className='artTopic_hover_text__div' style={{gap: '20rem'}}>
                <span>{hot[0].artist}</span>
                <h4>{hot[0].title}</h4>
                <h2>{artViewPrice(hot[0].price_UNIT, hot[0].price)}</h2>
              </div>
            </div>
            </Link>
          }
          {
            hot.length >= 3 &&
            <img src={`${workMainImage}/${hot[2].image}`} alt='' onClick={() => navigate(`./${hot[2].pk}`)} onContextMenu={
              (e) => {
                  e.preventDefault();
                  
              }
            }/>}
          {
            hot.length >= 4 &&
            <img src={`${workMainImage}/${hot[3].image}`} alt='' onClick={() => navigate(`./${hot[3].pk}`)} onContextMenu={
              (e) => {
                  e.preventDefault();
                  
              }
            }/>}
          {
            hot.length >= 5 &&
            <img src={`${workMainImage}/${hot[4].image}`} alt='' onClick={() => navigate(`./${hot[4].pk}`)} onContextMenu={
              (e) => {
                  e.preventDefault();
                  
              }
            }/>}
        </div>
      </div>
    )
  }





  const setLike = async (data, setData, pk) => {
    if (!login) {
      alert('로그인이 필요한 서비스입니다.')
      navigate('/login', { replace: true })

      return
    }

    const res = await publicWork.setLike(pk)
    if (!res.resultCode) {
      return
    }

    setData(
      data.map(
        it => it.pk === pk
          ? { ...it, heart: res.message === 'ADD' }
          : it
      )
    )
  }

  const setImage = () => {
    let _banner = [];

    marketBannerList.map(item => {
      _banner.push({
        info: detectMobile.isMobile() || window.innerWidth < 480 ? `${bannerImage}/${item.mobile}` : `${bannerImage}/${item.web}`,
        link: item.address,
        type: item.type
      });
    });

    setBanner(_banner)
  }

  React.useEffect(() => {
    setImage();
    window.addEventListener('resize', setImage);
    return () => {
      window.removeEventListener('resize', setImage);
    }
  }, [marketBannerList]);


  function RepresentingItems(item) {
    const result = []
    if (typeMenu === 0) {
      for (let i = 0; i < item.length; i++) {
        result.push(
          <div key={item[i].workInfo.image}
            onClick={
              () => navigate(`/market/work/auction/${item[i].workInfo.pk}`, { state: { active: menu } })
            }>
            <div className="slider__item">
              <img className='auction-image' src={`${workMainImage}/${item[i].workInfo.image}`} alt="item" onContextMenu={
                (e) => {
                    e.preventDefault();
                }
              }></img>
              <h3 className='auction-author'>{lang === 'ko' ? item[i].workInfo.artist : item[i].workInfo.eng_ARTIST}</h3>
              <h2 className='auction-title'>{lang === 'ko' ? item[i].workInfo.title : item[i].workInfo.eng_TITLE}</h2>
              <h4 className='auction-made'>
                {item[i].made_IN}
              </h4>
            </div>
          </div>
        )
      }
    } else {
      for (let i = 0; i < item.length; i++) {
        result.push(
          <div key={item[i].nft_PK}
            onClick={
              () => navigate(`/market/nft/auction/${item[i].nft_PK}`, { state: { active: menu } })
            }>
            <div className="slider__item">
              <img className='auction-image' src={item[i].image} alt="item" onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img>
              <h3 className='auction-author'>{checkUseName(lang, item[i].origin_USE_NAME, item[i].origin_KO_NAME, item[i].originENG_NAME, item[i].origin_KO_NICK_NAME, item[i].origin_ENG_NICK_NAME)}</h3>
              <h2 className='auction-title'>{item[i].name}</h2>
              <h4 className='auction-made'>
                {item[i].made_IN}
              </h4>
            </div>
          </div>
        )
      }
    }

    return result
  }

  return (
    <div className="market-main">
      <AllSlide items={banner} />
      {/* <>
      큐픽, 큐플, 옥션 개수 정하기 <br />
      큐픽은 최대 3개 <br />
      큐플은 3개 이하일때 이상한 오류 발생....ㅠ <br />
      <input
      type='number'
      value={myCount}
      onChange={e => setMyCount(e.target.value)}
      />
      </> */}

      <ArtTopic
        title_k={"큐플!"}
        title_e={" Qart's Place"}
        items={qPlace}
        gallery={true}
        hover={true}
        market={true} />

      <BigTopArrow
        title_e={'Qart’s Pick'}
        title_k={'큐픽!'}
        items={recommend}
        row2={true}
        setItems={setRecommend}
        action={setLike}
        breakRows={2}
        breakSlidesPerRow={1}
        hover={true}
        market={true}
      />
      <section className="hot-click">
        <ArtHotTopic
          title_k={"Hot Click!"}
          items={hot}
          price={true}
        />
        {/* <MarketHeader title_k={'Hot Click!'} />
        {hotItem} */}
      </section>

      <section className="hot-click">
      <ArtHotTopic
          title_k={'큐톡!'} title_e={"Qart's Talk"}
          move={'/market/qartstalk'}
          items={qTalk}
          price={false}
        />
        {/* <MarketHeader
          title_k={'큐톡!'} title_e={"Qart's Talk"}
          move={'/market/qartstalk'}
        />
        {qTalkItem} */}
      </section>
      <section className="hot-click">
        <MarketHeader
          title_k={'Auction'}
          move={'/market/auction/now'}
        />
        <div className="upcoming-auction">
          <ArtNotMoveDetail
            items={nftList}
            setItems={setNftList}
            RepresentingItems={RepresentingItems(nftList)}
            isMain={true}
          />
        </div>
      </section>
    </div>
  )
}
export default MarketMain
