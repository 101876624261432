const userSize = 2;
const mainSize = 5;
const subSize = 3;
const unit = 1024 ** 2; // 1MB


export const checkUserImageSize = (size) => {
    const maxSize = userSize * unit // 2MB

    if(size > maxSize){
        alert(`${userSize}MB 이내로 등록 가능합니다.`)
        return false
    }
    return true
};

export const checkMainImageSize = (size) => {
    const maxSize = mainSize * unit // 5MB

    if(size > maxSize){
        alert(`${mainSize}MB 이내로 등록 가능합니다.`)
        return false
    }
    return true
};

export const checkSubImageSize = (size) => {
    const maxSize = subSize * unit // 3MB

    if(size > maxSize){
        alert(`${subSize}MB 이내로 등록 가능합니다.`)
        return false
    }
    return true
};