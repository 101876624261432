import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Collapsible from '../../../components/Collapsible/Collapsible'
import HeaderSmall from '../../../components/Header/HeaderSmall/HeaderSmall'
import setting from '../../../services/setting'
import './SupportInQuiry.css'
import { getDateStr } from '../../../hook/utils/getDateStr'

function SupportInQuiry() {
  const navigate = useNavigate()
  const [choice, setChoice] = useState()
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [set, setSet] = useState(0)

  const [menu, setMenu] = useState(0)

  const [item, setItem] = React.useState([])
  const [tableItem, setTableItem] = React.useState([])


  React.useEffect(() => {
    const token = localStorage.getItem('QART_ACCESS') ?? ''
    if (token === '') {
      alert('로그인이 필요한 서비스입니다.')
      navigate('/login', {replace:true})

      return
    }
    getData()
  }, [set, menu])




  const inquiry = async e => {
    if (choice === undefined) {
      alert('문의하실 내용의 카테고리를 선택해주세요.')
      return
    }
    const category = moreList2.menu[choice].title

    const res = await setting.inquiry(category, title, content)
    if (!res.resultCode) {
      alert('서버 오류')
      return
    }
    setSet(0)
    setTitle('')
    setContent('')
    setMenu(1)
  }

  const getData = async () => {
    const res = await setting.getInquiryList()
    setItem(res.inquiry_list)
  }


  React.useEffect(() => {
    const myItem = []
    if (item.length === 0) {
      setTableItem(myItem)
      return
    }


    item.map(data => {
      myItem.push(
        <div key={data.pk}>
          <Collapsible title={`[${data.category}] ${data.title}`} sub={true} subTitle={data.answer === '' ? '[ 답변 미완료 ]' : '[ 답변 완료 ]'}>
            <div className="sf-col-item__open">
              <p>
                <span>
                  {data.content}
                </span>
              </p>
              <h4>문의 시간: {getDateStr(data.creation_TIME, '.')}</h4>

              {data.answer !== '' &&
                <>
                  <br /><br />
                  <div style={{ width: '80%' }}> <hr /></div>
                  <h2>답변</h2>
                  <br /><br /><br /><br />
                  <p><span>{data.answer}</span></p>
                  <h4>답변 시간: {getDateStr(data.answer_TIME, '.')}</h4>
                  <div style={{ width: '80%' }}> <hr /></div>
                  <br /><br />
                </>
              }
            </div>
          </Collapsible>
        </div>
      )
    })
    setTableItem(myItem)
  }, [item])


  React.useEffect(() => {
    setChoice()
  }, [menu])
  const moreList1 = {
    menu: [
      {
        title: '문의 하기',
      },
      {
        title: '문의 내역',
      }
    ],
  }
  const moreList2 = {
    menu: [
      {
        title: 'Art Database',
      },
      {
        title: 'Q-CoA',
      },
      {
        title: 'NFT',
      },
      {
        title: 'Use',
      },
      {
        title: 'Membership',
      },
      {
        title: 'Etc.',
      },
    ],
  }
  return (
    <div className="support-inq">
      <HeaderSmall
        moreList={moreList1}
        active={true}
        choice={menu}
        setMenu={setMenu}
      />
      {menu === 1 &&
        <>
          <div className="sf-content container">
            {tableItem}
          </div>
        </>
      }

      {menu === 0 &&
        <>
          <HeaderSmall
            moreList={moreList2}
            active={true}
            choice={choice}
            setMenu={setChoice}
          />
          <div className="support-inq-content">
            <div className="container">
              <ul>
                <li>
                  <h2>제목</h2>
                  <input type={'text'} value={title} onChange={e => setTitle(e.target.value)}></input>
                </li>
                <li>
                  <h2>내용</h2>
                  <textarea value={content} onChange={e => setContent(e.target.value)}></textarea>
                </li>
              </ul>
              <Link to={'.'} className="si-admit" onClick={inquiry}>
                <h1>문의하기</h1>
                <span className="si-admit-icon"></span>
              </Link>
            </div>
          </div>
        </>
      }
    </div>

  )
}
export default SupportInQuiry
