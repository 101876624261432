import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import TableSlide from '../../../../../components/TableSlide/TableSlide'
import { DebounceInput } from 'react-debounce-input';

import './MyArticleList.css'

import article from '../../../../../services/myArchive/article'
import { articleMainImage } from '../../../../../services/imageRoute'
import useMobileDetect from 'use-mobile-detect-hook'

function MyArticleList() {
  const [checkItems, setCheckItems] = useState([])
  const [items, setItems] = useState([]);
  const [tableItem, setTableItem] = useState([]);
  const [total, setTotal] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState('');
  const isMobile = useMobileDetect().isMobile();
  const row = isMobile ? 7 : 9;
  const navigate = useNavigate();



  React.useEffect(() => {
    getList(currentPage, row, search)
  }, [currentPage, search])


  const getList = async (currentPage, row, search) => {
    const res = await article.getMyList(currentPage, row, search)
    setTotal(res.total)
    setItems(res.articleList)
  }

  const deleteArticle = async (pk, action) => {
    const res = await article.deleteArticle(pk)
    if(!res.resultCode){
      alert('삭제에 실패했습니다.');
      return
    }
    alert('삭제 완료했습니다.');
    if(action){
      action();
    }
    getList(currentPage, row, search)
  }

  React.useEffect(() => {
    const changeItem = []
    items.map((item, i) => {
      changeItem.push(
        <div className="table-slider-item" key={i}>
          <ul>
            <li>{(total) - (row * currentPage + i)}</li>
            <li>
            <img src={`${articleMainImage}/${item.image}`} alt='' onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img>
            </li>
            <li>{ item.title }</li>
            <li>{ item.producer }</li>
            <li>{ item.date }</li>
            <li>
              <Link to={`./edit/${item.pk}`}>
                <button className="edit-btn">Edit</button>
              </Link>
            </li>
            <li>
                <button className="edit-btn" onClick={() => deleteArticle(item.pk)}>Delete</button>
            </li>
          </ul>
          <div className="work-item-mb">
          <Link to={`./edit/${item.pk}`}>
          <div className="work-item-mb-container">
            <div className="work-item-mb-content">
              <div className="work-item-mb__info">
              <img src={`${articleMainImage}/${item.image}`} alt='' onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img>
                <div className="work-item-mb__detail">
                  <h1>{ item.title }</h1>
                  <h4>{ item.producer }</h4>
                  <h3>{ item.date }</h3>
                </div>
                <button className="work-item-mb__delete" onClick={() => deleteArticle(item.pk, () => navigate(-1))}></button>
              </div>
            </div>
          </div>
          </Link>
        </div>
        </div>
      )
    })
    setTableItem([...changeItem])
  }, [items])


  // 개별선택
  function checkHandler(checked, id) {
    if (checked) {
      setCheckItems([...checkItems, id])
    } else {
      // 체크해제
      setCheckItems(checkItems.filter(o => o !== id))
    }
  }

  // 전체선택
  function checkAllHandler(checked) {
    if (checked) {
      const ids = []
      tableItem.forEach((v, id) => ids.push(id))
      setCheckItems(ids)
    } else {
      setCheckItems([])
    }
  }
  const titleItem = []

  titleItem.push(
    <div className="table-header-search">
      <h2>
        My News List 
        {/* <span className="download-button"></span> */}
        {/* My Article List <span className="download-button"></span> */}
      </h2>
      <div className="th-search-container">
        <section className="search__block">
        <DebounceInput
            minLength={1}
            debounceTimeout={500}
            type={'text'}
            placeholder={'자료명을 검색해주세요'}
            value={search}
            onChange={e => { setSearch(e.target.value); setCurrentPage(0) }}
            className="search__block__input"
          />
          <button className="search__block__button">
            <span className="ir_pm">검색</span>
          </button>
        </section>
        <section className="th-search-button">
          {/* <button>Delete</button> */}
          <Link to={'./add'}>
            <button className="last-button">Add</button>
          </Link>
        </section>
        <section className="th-mb-search-button">
          {/* <label className="login-option">
            <input
              type="checkbox"
              onChange={e => checkAllHandler(e.target.checked)}
              checked={
                checkItems.length === 0
                  ? false
                  : checkItems.length === tableItem.length
                  ? true
                  : false
              }
            />
            <span className="login-option__check" />
            모두선택
          </label> */}
          <Link to={'./add'}>
            <button>Add</button>
          </Link>
        </section>
      </div>
    </div>
  )
  const tableSlideHeader = []
  tableSlideHeader.push(
    <ul className="container table-slider-header-container">
      {/* <li>
        <label className="login-option">
          <input
            type="checkbox"
            onChange={e => checkAllHandler(e.target.checked)}
            checked={
              checkItems.length === 0
                ? false
                : checkItems.length === tableItem.length
                ? true
                : false
            }
          />
          <span className="login-option__check" />
        </label>
      </li> */}
      <li>번호</li>
      <li>이미지</li>
      <li>자료명</li>
      <li>발행처</li>
      <li>발행일</li>
      <li>수정</li>
      <li>삭제</li>
    </ul>
  )
  return (
    <div className="my-article-list">
      <TableSlide
        title={titleItem}
        slideHeader={tableSlideHeader}
        items={tableItem}
        rows={row}
        breakRows={8}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  )
}
export default MyArticleList
