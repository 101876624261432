import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Footer from '../../../../components/Footer/Footer'
import Header from '../../../../components/Header/Header'
import SectionHeader from '../../../../components/SectionHeader/SectionHeader'
import WorkHeader from '../../../../components/WorkHeader/WorkHeader'
import './MarketNftItem.css'
import { shallowEqual, useSelector } from 'react-redux'
import publicNFT from '../../../../services/public/publicNFT'
import { artViewPrice } from '../../../../hook/utils/artdbView'
import { handleImgError, userImage } from '../../../../services/imageRoute'
import { checkUseName } from '../../../../hook/utils/checkUseName'

function MarketNftItem() {
  const navigate = useNavigate();
  const { nftId } = useParams();
  const { lang } = useSelector(v => v.setting, shallowEqual)
  const { login } = useSelector(v => v.auth, shallowEqual)
  React.useEffect(() => { getData(nftId) }, [nftId])

  const [data, setData] = React.useState(null);  
  const [moreList, setMoreList] = React.useState(['리스트', '그래프']);
  const [menu, setMenu] = React.useState(0);  

  const getData = async (pk) => {
    const res = await publicNFT.getDetail(pk)
    setData(res.nft.nft_detail)
  }


  const setLike = async () => {
    if (!login) {
      alert('로그인이 필요한 서비스입니다.')
      navigate('/login', {replace:true})

      return
    }

      const res = await publicNFT.setLike(data.nft_PK)
      if (!res.resultCode) {
        return
      }
      setData({...data, heart: !data.heart})
  }

  const payment = () => {

    if(!login){
      alert('로그인이 필요한 서비스입니다.');
      navigate('/login', {replace:true})

      return
    }

    navigate('/market/payment', { state: {item: data, nft: true} })
  }

  if(data === null){
    return(<></>)
  }

  return (
    <section className="market-nft-item">
      <Header active="0" colored="black" detail={true} />
      <WorkHeader title_k={'NFT'} />
      <div className="container">
        <div className="market-nft-info">
          <img
            className="market-nft__image"
            src={data.image}
            alt=''
            onContextMenu={
              (e) => {
                  e.preventDefault();
              }
            }
          ></img>
          <div className="market-nft__info">
            <div className="market-nft__title">
              <h1>{data.name}</h1>
              <button
                onClick={setLike}
               className={data.heart ? 'action-heart-button' : 'heart-button'}> 
                <span className="ir_pm">좋아요</span>
              </button>
            </div>
            <div className="market-nft__price">
              <span className="price-title">판매가</span>
              <span className="price">{artViewPrice('', data.price)}</span>
            </div>
            <div className="market-nft__button">
              {/* <span>판매중</span> */}
              {/* <Link
                to={'/market/payment'}
                // state={{
                //   item: item,
                // }}
              > */}
                <button onClick={payment}>구매하기</button>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
      <section>
        <SectionHeader title={'크리에이터 정보'} />
        <div className="market-nft-header">
          <div className="container m-nft-header-container">
            <img
              className="m-nft__image"
              src={`${userImage}/${data.origin_IMAGE}`}
              onError={handleImgError}
              alt=''
              onContextMenu={
                (e) => {
                    e.preventDefault();
                }
              }
            ></img>
            <div className="m-nft__creator">
              <h1>{checkUseName(lang, data.origin_USE_NAME, data.origin_KO_NAME, data.origin_KO_NAME, data.origin_KO_NICK_NAME,data.origin_KO_NICK_NAME)}</h1>
              <span>{checkUseName(lang === 'ko' ? 'en' : 'ko', data.origin_USE_NAME, data.origin_ENG_NAME, data.origin_ENG_NAME, data.origin_ENG_NICK_NAME,data.origin_ENG_NICK_NAME)}</span>
            </div>
          </div>
        </div>
        <div className="m-nft-detail">
          <div className="container">
            <ul className="m-nft-detail__list">
              <li>
                {data.description}
              </li>
            </ul>
            <div className="m-nft-detail__info">
              <h1>NFT 정보</h1>
              <ul className="m-nft-info__list">
                <li>
                  <span className="info-title">소유자</span>
                  <span className="info-content">{data.owner}</span>
                </li>
                <li>
                  <span className="info-title">컨트렉트 주소</span>
                  <span className="info-content">
                    {data.contract}
                  </span>
                </li>
                <li>
                  <span className="info-title">토큰 ID</span>
                  <span className="info-content">{data.token_ID}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="market-nft-deal-container">
        <SectionHeader
          title={'최근 거래 체결가'}
          moreList={moreList}
          active={menu}
          setMenu={setMenu}
        />
        {menu === 0 &&
        <div className="m-nft-deal">
          <div className="container m-nft-deal-container">
            <ul className="m-nft-deal__table">
              <li className="m-nft-table__thead">
                <div>
                  <span>거래일시</span>
                  <span>에디션</span>
                  <span>체결가</span>
                </div>
              </li>
              <li className="m-nft-table__body">
                <div>
                  <span>2000.00.00</span>
                  <span>#7</span>
                  <span>0.000ETH</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        }
      </section> */}
      <Footer />
    </section>
  )
}
export default MarketNftItem
