import produce from 'immer';
import * as ActionTypes from './actionTypes';
import { countryList } from '../../../assets/countryList';

const TIME_ZONE = 9 * 60 * 60 * 1000; // 9시간=
const date = new Date();
const today = new Date(date.getTime() + TIME_ZONE).toISOString().replace('T', ' ').slice(0, -5).split(' ')[0];

const minDate = new Date('1900-01-01');
const min = new Date(minDate.getTime() + TIME_ZONE).toISOString().replace('T', ' ').slice(0, -5).split(' ')[0];
const maxDate = new Date('9999-12-31');
const max = new Date(maxDate.getTime() + TIME_ZONE).toISOString().replace('T', ' ').slice(0, -5).split(' ')[0];

const initialState = {
    date: {
        min: min,
        today: today,
        max: max
    },
    education: {
        degreeList: ['전문 학사', '학사', '석사', '박사', '기타'],
        eduStateList: ['재학중', '수료', '졸업']
    },
    work: {
        timeSetList: ['고미술', '근현대'],
        rowWorkTypeList: [],
        editionList: ['없음', '있음'],
        archiveList: ['비공개', '공개'],
        unitList: ['cm', 'inch', 'px', '시간'],
        nftSaleTypeList: ['판매', '경매'],
        saleTypeList: ['원화 (원)', 'USD ($)'],
        marketList: ['비매', '판매', '경매', '선물하기'],
        // marketList: ['비매', '판매', '경매', '오프라인'],
        exhibitList: ['선택해주세요.'],
        undefinedList: ['직접입력', '미상'],
        undefinedDateList: ['직접입력(YYYY-MM-DD)', '직접입력(YYYY)', '미상'],
    },
    exhibition: {
        typeList: ['개인전', '단체전', '아트페어', '기타']
    },
    marketOrderList: ['최신등록순', '인기순', '작가명순', '작품명순', '가격순'],
    countryList: ['대한민국', '미국', '일본', '중국', '대만', '기타'],
    // TODO 국가선택하는 곳에 적용하기
    // countryList: countryList,
    phoneList: ['+1', '+81', '+82', '+86', '+886'],
    hostList: ['직접입력 ', 'naver.com', 'gmail.com', 'daum.net'],
    lang: 'ko',
    company: null,
    mainBannerList: [],
    collectionBannerList: [],
    marketBannerList: [],
    platformBannerList: [],
    popupList: []
};

const settingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_WORK_TYPE_LIST:
            return produce(state, draft => {
                draft.work.rowWorkTypeList = action.data
            })
        case ActionTypes.SET_EXHIBIT_LIST:
            return produce(state, draft => {
            })
        case ActionTypes.SET_COMPANY_INFO:
            return produce(state, draft => {
                draft.company = action.data
            })
        case ActionTypes.SET_BANNER_LIST:
            return produce(state, draft => {

                let main = [];
                let market = [];
                let platform = [];
                let collection = [];

                for (let i = 0; i < action.data.length; i++) {
                    const type = action.data[i].code;

                    switch (type) {
                        case 'main':
                            main.push(action.data[i]);
                            break;
                        case 'market':
                            market.push(action.data[i]);
                            break;
                        case 'platform':
                            platform.push(action.data[i]);
                            break;
                        case 'collection':
                            collection.push(action.data[i]);
                            break;
                        default:
                            break;
                    }
                }

                draft.mainBannerList = main;
                draft.collectionBannerList = collection;
                draft.marketBannerList = market;
                draft.platformBannerList = platform;
            })
        case ActionTypes.SET_POPUP_LIST:
            return produce(state, draft => {
                draft.popupList = action.data;
            })
            case ActionTypes.CHECK_ADMIN_NODE00:
                return produce(state, draft => {
                    if(action.email === 'node00@artsynapse.art'){
                        draft.work.marketList = ['비매', '판매', '경매', '선물하기', '오프라인']
                    }
                })
        default:
            return state
    }
}

export default settingReducer;
