import axios from "axios";
import moment from "moment";
import { Buffer } from 'buffer'
const baseURL = process.env.REACT_APP_BASE_URL;

const pay = process.env.REACT_APP_COOKEYPAY_URL;


export const parseJwt = (token, index) => {
  const base64Payload = token.split('.')[index]; //value 0 -> header, 1 -> payload, 2 -> VERIFY SIGNATURE
  const payload = base64Payload === undefined ? '' : Buffer.from(base64Payload.toString(), 'base64').toString('utf8');
  const result = base64Payload === undefined ? {} : JSON.parse(payload.toString())
  return result;
};

const json = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": 'application/json'
  }
})

let count = 0;

// json 요청 인터셉터
json.interceptors.request.use(
  function (config) {
    try {
      const token = localStorage.getItem('QART_ACCESS') ?? ''
      if(token !== undefined){
        // console.log(parseJwt(token, 1).exp)
        // console.log(Math.ceil(Date.now() / 1000))
        if(parseJwt(token, 1).exp < Math.ceil(Date.now() / 1000)){
          localStorage.removeItem('QART_ACCESS')
          return config
        }
      }
      if (config.headers['x-access-token'] === undefined || config.headers['x-access-token'] === null) {
        config.headers['x-access-token'] = token
      }
      return config
    } catch (err) {

    }
  }
)

// json 응답 인터셉터
json.interceptors.response.use(
  function (response) {
    try {
      // 토큰 오류 처리

      if(window.location.pathname === '/login'){
        count = 0
      }
      if (response.data.message === 'InvalidToken') {
        localStorage.removeItem('QART_ACCESS')
        if(window.location.pathname !== '/login' && count === 0){
          count = 1
          window.location.href = `${window.location.protocol}//${window.location.host}/login`
          alert('다시 로그인을 해주세요.')
          throw 'login expire'
        }
      }

      if (response.data.resultCode === 'false') {
        console.log(`resultCode false :: <${response.config.method.toUpperCase()}> ${response.config.url}`)
        return
      }
      return response
    } catch (err) {

    }
  },
  function (error) {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          console.log(`400 ERROR :: <${error.response.config.method.toUpperCase()}> ${error.response.config.url}`)
          return new Promise(() => { })
        case 500:
          console.log(`500 ERROR :: <${error.response.config.method.toUpperCase()}> ${error.response.config.url}`)
          return new Promise(() => { })
        default:
          return Promise.reject(error)
      }
    }
    return Promise.reject(error)
  }
)


const formData = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "multipart/form-data",
  }
})

// formData 요청 인터셉터
formData.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('QART_ACCESS') ?? ''


    if (config.headers['x-access-token'] === undefined || config.headers['x-access-token'] === null) {
      config.headers['x-access-token'] = token
    }
    return config
  }
)

// formData 응답 인터셉터
formData.interceptors.response.use(
  function (response) {
    // 토큰 오류 처리
    if (response.data.message === 'InvalidToken') {
      localStorage.removeItem('QART_ACCESS')
      alert('다시 로그인을 해주세요.')
      window.location.href = `${window.location.protocol}//${window.location.host}/login`
      return
    }

    if (response.data.resultCode === 'false') {
      console.log(`resultCode false :: <${response.config.method.toUpperCase()}> ${response.config.url}`)
      return
    }
    return response
  },
  
  function (error) {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          console.log(`400 ERROR :: <${error.response.config.method.toUpperCase()}> ${error.response.config.url}`)
          return new Promise(() => { })
        case 500:
          console.log(`500 ERROR :: <${error.response.config.method.toUpperCase()}> ${error.response.config.url}`)
          return new Promise(() => { })
        default:
          return Promise.reject(error)
      }
    }
    return Promise.reject(error)
  }
)


const cookie = axios.create({
  baseURL: pay,
  headers: {
    "Content-Type": 'application/json'
  }
})

const client = {
  json, formData, cookie
}

export default client
