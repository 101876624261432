import React, { useState } from 'react'
import './AuctionModal.css'
import CheckModal from './CheckModal'
import ModalPortal from './ModalPortal'
import nft from '../../services/myArchive/work/nft'
import { checkNumber, onChangePoints, setComma } from '../../hook/utils/comma'
import { shallowEqual, useSelector } from 'react-redux'
import { artViewPrice } from '../../hook/utils/artdbView'
import { useNavigate } from 'react-router-dom'
import { setBidTime } from '../../hook/utils/getDateStr'
import work from '../../services/myArchive/work/work'

function AuctionModal({ setModalOpen, pk, isNft, bidList, setBidList, topBid, startPrice, setTopBid }) {
  // 모달창 노출 여부 state
  const [checkOpen, setCheckOpen] = useState(false)
  const navigate = useNavigate()
  const [price, setPrice] = useState('');
  const { artist, gallery, type } = useSelector(v => v.auth, shallowEqual)
  
  // 모달창 노출
  const showCheckModal = () => {
    setCheckOpen(true)
    //document.body.style.overflow = 'hidden' // 모달 창 오픈 시 스크롤 불가
  }
  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false)
    document.body.style.overflow = 'unset' // 모달 창 종료 시 스크롤 가능
  }

  const bid = async () => {


    if(bidList.length === 0){
      if (+startPrice > +price) {
        alert('시작가 보다 낮은 금액은 불가능합니다.')
        return
      }
    }else{
      if (+topBid >= +price) {
        alert('현재가랑 같거나 보다 낮은 금액은 불가능합니다.')
        return
      }
    }

    

    let res;

    if(isNft){
      res = await nft.bid(pk, price);
    }else{
      res = await work.bid(pk, price);
    }

    if (!res.resultCode) {
      if(res.message === 'already'){
        alert('현재 상위 입찰자입니다.');
        return;
      }
      if(res.message === 'need PRICE up than top_bid'){
        alert('현재가가 변경이 되었습니다.');
        window.location.reload()
        return
      }
      if(res.message === 'time over'){
        alert('마감 되었습니다.');
        navigate('/market/auction/nft/now')
        return
      }
      alert('관리자에게 문의해주세요.');
      return;
    }

    setTopBid(price);

    const addBid = {
      bid_PRICE: price,
      date: new Date().getTime(),
      email: type === 2 ? gallery.email : artist.email,
    }
    alert("응찰되었습니다.")
    setBidList([addBid, ...bidList])
    setPrice('')
  }


  return (
    <div className="auc-modal">
      <div className="modal-container" onClick={closeModal}>
        <div className="modal-body" onClick={e => e.stopPropagation()}>
          <header className="auc-header">
            <h1>응찰하기</h1>
            <button className="auc-close" onClick={closeModal}></button>
          </header>
          <div className="auc-content">
            <div className="auc-list-th">
              <h1>응찰자</h1>
              {/* <h1>응찰하기</h1> */}
            </div>
            <ul className="auc-list-tb">
              {
                bidList.map(item => {
                  return (
                    <li key={item.bid_PRICE}>
                      <div className='email__size'>
                      <span className='email_font'>{item.email}</span>
                      </div>
                      <div>
                      <span className="price">{artViewPrice(1, item.bid_PRICE)}</span>
                      </div>
                      <div>
                      <span className="time">{setBidTime(item.date)}</span>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <div className="auc-add">
            <div className="auc-add__input">
              <input autoComplete='off'
                style={{ textAlign: 'end' }}
                type="text"
                onKeyPress={checkNumber}
                inputMode="numeric"
                value={setComma(price)}
                onChange={e => setPrice(onChangePoints(e))}></input>
            </div>
            <button onClick={showCheckModal}>응찰하기</button>
            {checkOpen && (
              <ModalPortal>
                <CheckModal
                  setModalOpen={setCheckOpen}
                  title={'응찰 확인'}
                  content={[
                    '응찰하기를 완료하시면 취소가 불가능합니다.',
                    <br></br>,
                    '계속하시겠습니까?',
                  ]}
                  setFunc={bid}
                />
              </ModalPortal>
            )}
          </div>
          <span className="auc-warn">
            *응찰된 상태에서 취소가 불가능합니다.
          </span>
        </div>
      </div>
    </div>
  )
}

export default AuctionModal
