const emailRegex =
      /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[*~!@#$%^&*|\°\"; :/?])(?=.*[0-9]).{8,20}$/
// const specialCharacter =  /[\\\{\}\[\]}]/gi;
export const passCheck = (temp) => {
    if (!passwordRegex.test(temp)) {
        alert('비밀번호는 영문, 특수문자, 숫자 포함 8~20자로 가능합니다.')
        return false
    }else{
      // if(specialCharacter.test(temp)){
      //   alert('[, ], {, }, \ 특수문자는 사용이 불가능합니다.')
      //   return;
      // }else{
      // }
        return true
    }
}

export const emailCheck = (temp) => {
    if (!emailRegex.test(temp)) {
        alert('올바른 이메일을 입력해주세요.')
        return false
    }else{
        return true
    }
}

export const checkDate = (set, e) => {
    // e.preventDefault();
    // e.stopPropagation();
      const target = e.target.value
      const min = e.target.min
      const max = e.target.max

      if (target.length === 10) {
        const checkMin = target >= min
        if(max === ''){
          if (checkMin) {
            return set(target)
          } else {
            return set('')
          }
        }
        const checkMax = target <= max 
        // document.activeElement.blur()
        if (checkMin && checkMax) {
          return set(target)
        } else {
          return set('')
        }
      } else {
        return set(target)
      }
  }


export  const handleInputMaxLength = (set, e) => {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
    set(e.target.value);
  };
