import React from 'react'
import './ReturnNoticeModal.css'

function ReturnNoticeModal({ setModalOpen, callFunc }) {


  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false)
  }
  
  return (
    <div className="return-notice-info-modal">
      <div className="modal-container" onClick={closeModal}>
        <div className="modal-body" onClick={e => e.stopPropagation()} >
          <h1 style={{ whiteSpace: 'pre-wrap', textAlign: 'center'}}>취소/반품 신청</h1>
          <ul>
          <li>
            <h2>단순 변심에 의한 취소는 불가합니다.</h2>
           </li>
           <li>
           <h2>큐아트는 통신판매중개자로, 취소 및 반품 절차는 판매자와 구매자 간의 합의후에 최종적인 환불이 가능합니다.</h2>
           </li>
          </ul>

          <div className="return-notice-btn">
            <button onClick={closeModal}>취소</button>     
            <div style={{width:12}}></div>
            <button onClick={() => callFunc()}>확인 및 최종 신청</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReturnNoticeModal
