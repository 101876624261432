import React from 'react'
import Footer from '../../../components/Footer/Footer'
import Header from '../../../components/Header/Header'
import SearchHeader from '../../../components/SearchHeader/SearchHeader'
import ActionHeader from '../../../components/Header/AuctionHeader/AuctionHeader'
import './MarketAuction.css'
import { Link, useNavigate } from 'react-router-dom'
import ArtDetailOption from '../../../components/ArtDetailOption/ArtDetailOption'
import useMobileDetect from 'use-mobile-detect-hook';
import market from '../../../services/public/market/market'
import { checkUseName } from '../../../hook/utils/checkUseName'
import { shallowEqual, useSelector } from 'react-redux'
import { artViewDate, artViewPrice } from '../../../hook/utils/artdbView'
import publicNFT from '../../../services/public/publicNFT'
import { workMainImage } from '../../../services/imageRoute'
import publicWork from '../../../services/public/publicWork'

function MarketAuction(props) {
  const detectMobile = useMobileDetect();

  const navigate = useNavigate();
  const { lang } = useSelector(v => v.setting, shallowEqual)
  const { login } = useSelector(v => v.auth, shallowEqual)


  const {active} = props; // past, now ,coming
  const options = ['최신등록순', '시작가 낮은순', '시작가 높은순', '응찰 인기순']
  const [menu, setMenu] = React.useState(options[0])
  const [rows, setRows] = React.useState(3)
  const [slidersPerRow, setSlidersPerRow] = React.useState(3)
  const [currentPage, setCurrentPage] = React.useState(0)
  const [nftList, setNftList] = React.useState([]);
  const [workList, setWorkList] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [items, setItems] = React.useState([]);

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = React.useState(mediaMatch.matches);

  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  const navigateByType = (pk,isNft) => {
    if(isNft){
      navigate(`/market/nft/auction/${pk}`)
    }else{
      navigate(`/market/work/auction/${pk}`)
    }
    return
    if(active === 0){
      navigate(`/market/auction/${pk}`)
      return
    }
    if(active === 1){
      navigate(`/market/auction/${pk}`)
      return
    }
    if(active === 2){
      alert('경매 예정입니다.');
      return
    }
  }
  React.useEffect(() => {
    if (detectMobile.isMobile()) {
      setRows(3)
      setSlidersPerRow(2)
    }
  }, [detectMobile.isMobile()])

  React.useEffect(() => {
    const myMenu = options.indexOf(menu);
    const type = active === 0 ? 1 : active === 1 ? 0 : 2
    getList(props.isNft, type, currentPage, rows * slidersPerRow, myMenu )
  },[props.isNft, active, currentPage, rows, slidersPerRow, menu])


  const getList = async(isNft, currentType, page, count, order) => {
    const res = await market.getAuctionList(isNft, page, count, currentType, order);

    if(isNft === 0){
      setWorkList(res.list)
    }else{
      setNftList(res.nftList)
    }
    setTotal(res.total)
  }

  React.useEffect(() => {
    const result = []
    if(nftList.length === 0){
      setItems(result)
      return
    }


    for (let i = 0; i < nftList.length; i++) {
      result.push(
        <div key={nftList[i].nft_PK}>
          <div className="slider__item">
            <img src={nftList[i].image} alt="item" onContextMenu={
                (e) => {
                    e.preventDefault();
                }
              }></img>
            <h3>{checkUseName(lang, nftList[i].origin_USE_NAME, nftList[i].origin_KO_NAME, nftList[i].originENG_NAME, nftList[i].origin_KO_NICK_NAME, nftList[i].origin_ENG_NICK_NAME)}</h3>
            <h2>{nftList[i].name}</h2>
            <h4>
              {nftList[i].made_IN}
            </h4>
          </div>
          <div className="market-item">
          <span className="market-item--price">{artViewPrice(1, nftList[i].top_PRICE === 0 ? nftList[i].start_PRICE : nftList[i].top_PRICE)}</span>
            <button onClick={() => setLike(nftList[i].nft_PK)} className={nftList[i].heart ? "market-item--heart_active" : "market-item--heart"}>
              <span className="ir_pm">좋아요</span>
            </button>
          </div>
            <button onClick={() => navigateByType(nftList[i].nft_PK, true)} className="buy-button">응찰하기</button>
        </div>
      )
    }

    setItems(result)
  },[nftList])


  React.useEffect(() => {
    const result = []

    if(workList.length === 0){
      setItems(result)
      return
    }


    for (let i = 0; i < workList.length; i++) {
      result.push(
        <div key={workList[i].nft_PK}>
          <div className="slider__item">
            <img src={`${workMainImage}/${workList[i].workInfo.image}`} alt="item" onContextMenu={
                (e) => {
                    e.preventDefault();
                }
              }></img>
            <h3>{lang === 'ko' ? workList[i].workInfo.artist : workList[i].workInfo.eng_ARTIST }</h3>
            <h2>{lang === 'ko' ? workList[i].workInfo.title : workList[i].workInfo.eng_TITLE }</h2>
            <h4>
              {workList[i].made_IN}
              {artViewDate(workList[i].workInfo.start_CREATE, workList[i].workInfo.end_CREATE)}
            </h4>
          </div>
          <div className="market-item"></div>
            <div className="market-item--price" style={{display:'flex', flexDirection:'row', justifyContent:'space-between', }}>
              <span style={{color:'#4f4f4f', fontWeight:400}}>시작가</span>
              <span style={{color:'#222222'}}>{artViewPrice(1, workList[i].start_PRICE)}</span>
            </div>
            <div className="market-item--price" style={{display:'flex', flexDirection:'row', justifyContent:'space-between', }}>
            <span style={{color:'#4f4f4f', fontWeight:400}}>현재가</span>
            <span style={{color:'#eb5757'}}>{artViewPrice(1, workList[i].top_PRICE)}</span>
            </div>
          <div>
          <div className="market-item" style={{marginTop:'10rem', justifyContent:'space-between'}}>
              <button onClick={() => navigateByType(workList[i].work_PK, false)} className="buy-button">응찰하기</button>
              <button onClick={() => setLikeWork(workList[i].work_PK)} className={workList[i].heart ? "market-item--heart_active" : "market-item--heart"} style={{display:'flex', marginTop: matches ? '16rem' : '36rem', width: matches ? '20rem' : '46rem',height: matches ? '20rem' : '46rem'}}>
                <span className="ir_pm">좋아요</span>
              </button>
            </div>
          </div>
            
        </div>
      )
    }

    setItems(result)
  },[workList])



  const moreList = {
    menu: [
      {
        title: 'Now',
        link: '/market/auction/now',
      },
      {
        title: 'Upcoming',
        // link: '/market/auction/up-coming', 
        disable: true
      },
      {
        title: 'Past',
        link: '.',
        disable: true
        //link: '/market/auction/past',
      },
    ],
  }

  const moreList2 = {
    menu: [
      {
        title: '실물',
        link: '.',
      },
      // nft 삭제
      {
        title: 'NFT',
        link: '.',
      }
    ],
  }


  const setLike = async (pk) => {
    if (!login) {
      alert('로그인이 필요한 서비스입니다.')
      navigate('/login', {replace:true})

      return
    }

      const res = await publicNFT.setLike(pk)
      if (!res.resultCode) {
        return
      }

      setNftList(
        nftList.map(
          it => it.nft_PK === pk
            ? { ...it, heart: res.message === 'ADD' }
            : it
        )
      )
    }

    const setLikeWork = async (pk) => {
      
      if (!login) {
        alert('로그인이 필요한 서비스입니다.')
        navigate('/login', {replace:true})

        return
      }
  
      const res = await publicWork.setLike(pk)
      if (!res.resultCode) {
        return
      }
  
      setWorkList(
        workList.map(
          it => it.work_PK === pk
            ? { ...it, heart: res.message === 'ADD' }
            : it
        )
      )
    }
  

  


  return (
    <div className='market-auction'>
      <Header active="2" colored="black" detail={true} />
      <SearchHeader title="Auction" active={props.search} />
      <ActionHeader
        moreList={moreList}
        active={props.search}
        choice={props.active}
      />
      <ActionHeader
        moreList={moreList2}
        active={props.search}
        setMenu={props.setIsNft}
        choice={props.isNft}
      />
      
      <section className="auction-detail-slider">
        <ArtDetailOption
          title_k={(active === 0 ? '진행중인 ' : '다가오는 ') + '경매 (' + total + ')'}
          title_e={active === 0 ? 'Now' : 'Upcoming'}
          options={options}
          menu={menu}
          setMenu={setMenu}
          rows={rows}
          slidersPerRow={slidersPerRow}
          total={total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          items={items}
          isAuction={true}
        />
      </section>
      <Footer />
    </div>
  )
}
export default MarketAuction
