import React, { useState } from 'react'
import './ArtistSchool.css'
import DropDown from '../../../../components/DropDown/DropDown'
import Footer from '../../../../components/Footer/Footer'
import Header from '../../../../components/Header/Header'
import HeaderSmall from '../../../../components/Header/HeaderSmall/HeaderSmall'
import SectionHeader from '../../../../components/SectionHeader/SectionHeader'
import ModalPortal from '../../../../components/Modal/ModalPortal'
import CheckModal from '../../../../components/Modal/CheckModal'

import { useSelector, shallowEqual } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import profile from '../../../../services/auth/profile'
import { checkDate } from '../../../../hook/utils/validation'

function ArtistSchool() {

  const [school, setSchool] = React.useState('');
  const [country, setCountry] = React.useState();
  const [admission, setAdmission] = React.useState('');
  const [graduated, setGraduated] = React.useState('');
  const [degreeType, setDegreeType] = React.useState();
  const [eduStateType, setEduStateType] = React.useState('재학중');
  const [etc, setEtc] = React.useState('');

  const { min, today } = useSelector(v => v.setting.date, shallowEqual)
  const { countryList } = useSelector(v => v.setting, shallowEqual)
  const { degreeList, eduStateList } = useSelector(v => v.setting.education, shallowEqual)
  const navigate = useNavigate();

  const done = async () => {

    if (school === '' ) {
      alert('기관명을 입력해주세요.')
      return
    }
    if (admission === '') {
      alert('입학시기를 입력해주세요.')
      return
    }
    if (graduated === '' ) {
      alert('졸업시기를 입력해주세요.')
      return
    }
    if (degreeType === '' ) {
      alert('학위유형을 입력해주세요.')
      return
    }

    const status = eduStateType ?? eduStateList[0]
    // console.log(`status :: ${status}`)

    const res = await profile.addSchool(school, country ?? countryList[0],
      admission, graduated, degreeType ?? degreeList[0], etc,
      eduStateList.indexOf(status) );
    
    if(!res.resultCode){
      alert('저장에 실패했습니다.')
      return
    }
    alert('저장 완료 했습니다.')
    navigate(-1)
  }

  React.useEffect(()=> {
    if(degreeType === '기타'){
      setEduStateType('재학중')
    }
  }, [degreeType])

  React.useEffect(()=> {
    if(eduStateType === '수료'){
      setGraduated('수료')
    } else if (eduStateType === '재학중'){
      setGraduated('재학중')
    }
  }, [eduStateType])

  return (
    <div className="artist-school">
      <Header login={true} colored="black" detail={true} />
      <SectionHeader title={'학력'} />
      <section className="container a-exhibition-content">
        <ul className="a-ex-edit-info">
          <li>
            <h2>국가 *</h2>
            <DropDown options={countryList} setValue={setCountry} />
          </li>
          <li>
            <h2>기관명 *</h2>
            <input type="text" value={school} onChange={e => setSchool(e.target.value)}></input>
          </li>

          {/*<li>*/}
          {/*  <h2>학위종류 *</h2>*/}
          {/*  <input value={degreeType} onChange={e => setDegreeType(e.target.value)} type="text"></input>*/}
          {/*</li>*/}
          <li>
            <h2>학위유형 *</h2>
            <DropDown options={degreeList} setValue={setDegreeType} />
          </li>
          <li>
            <h2>상태 *</h2>
            <DropDown
                options={degreeType === '기타' ? ['재학중', '수료'] : eduStateList}
                setValue={setEduStateType} />
          </li>
          <li>
            <h2>입학시기 *</h2>
            <input
                value={admission}
                onChange={e => checkDate(setAdmission, e)}
                type="date"
                id="date"
                className="date-input"
                min={min}
                max={today}
            ></input>
          </li>
          {eduStateType === '졸업'
             ? <li>
                <h2>졸업시기 *</h2>
                <input
                    value={graduated}
                    onChange={e => checkDate(setGraduated, e)}
                    type="date"
                    id="date"
                    className="date-input"
                    min={min}
                    max={today}
                ></input>
              </li>
            : null
          }

          <li>
            <h2>Note</h2>
            <textarea value={etc} onChange={e => setEtc(e.target.value)} placeholder="상세한 설명입니다."></textarea>
          </li>
        </ul>
        <div className="a-ex-edit--button">
        <button onClick={() => navigate(-1)}>취소</button>
          <button onClick={done}>저장</button>
        </div>
      </section>
      <Footer />
    </div>
  )
}
export default ArtistSchool
