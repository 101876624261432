import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import './SignUpAuth.css'

import auth from '../../services/auth/auth'
import { SignupStepper } from './SignupStepper'

function SignUpPhone() {
  const navigate = useNavigate()

  const data = useLocation().state

  //   const data = {
  //   token: 'token',
  //   next: '1',
  //   email: 'aksgml223@aver.com',
  //   phone: '+8860966354125', <해외용
  //   name: '이만희',
  //   type: 'LOCAL'
  // }


  const [select, setSelect] = useState(0)
  const [phone, setPhone] = useState(data.phone)
  const [phoneLock, setPhoneLock] = useState(false)
  const [code, setCode] = useState('')
  const [codeLock, setCodeLock] = useState(false)


  const sendEmail = async() => {
    
    if(phoneLock){
      return
    }
    // setPhoneLock(true)
    
    const depart = phone.split(')')
    var zip;
    var number;

    if(depart.length === 1){
      zip = '+82';
      number = depart[0]
    }else{
      zip = depart[0].replaceAll('+','')
      number = depart[1].replaceAll('-','')
    }
    // console.log(zip)
    // console.log(number)
    // return
    const res = await auth.sendSMS(zip, number)
    if(res.data) {
      alert("휴대폰으로 보낸 인증코드를 입력해주세요.");
    }else{
      alert("다시 시도해주세요.");
      setPhoneLock(false)
    }
  }

  const checkCode = async() => {
    const depart = phone.split(')')
    var zip;
    var number;

    if(depart.length === 1){
      zip = '+82';
      number = depart[0].replaceAll('-','')
    }else{
      zip = depart[0]
      number = depart[1].replaceAll('-','')
    }
    const res = await auth.checkSMS(zip, number, code)
    if(codeLock){
      alert('인증이 완료되었습니다.')
      return
    }
    if(res.data){
      alert('휴대폰이 인증되었습니다.')
      setCodeLock(true)
    }else{
      alert("인증번호를 확인해주세요.")
      setCodeLock(false)
    }
  }

  const next = () => {
    // console.log(data)
    if(!codeLock){
      alert('휴대폰 인증을 먼저 해주세요.')
      return
    }
    switch(data.next){
      case 1 :
        navigate("/signUp/auth/artist", {
          state: {
            token: data.token,
            kyc: data.kyc,
            name: data.name,
            birth: data.birth,
            email: data.email,
            phone: phone,
            type: data.type,
            next: data.next
          },
        });
        // window.location.reload();
        return;
        case 2 :
        navigate("/signUp/auth/agency", {
          state: {
            token: data.token,
            kyc: data.kyc,
            name: data.name,
            birth: data.birth,
            email: data.email,
            phone: phone,
            type: data.type,
            next: data.next
          },
        });
        // window.location.reload();
        return;
        case 0 :
        // window.location.reload();
        navigate("/signUp/auth/general", {
          state: {
            token: data.token,
            kyc: data.kyc,
            name: data.name,
            birth: data.birth,
            email: data.email,
            phone: phone,
            type: data.type,
            next: data.next
          },
        });
        return ;
        default :
        return
    }
  }
  
  return (
    <div className="sign-auth">
      <Header />
      <section className="container sign-auth-container">
      <SignupStepper type={data.type} active={data.type === "LOCAL" ? 2 : 1} />
        <div className="auth-list">
          <button className="auth-item" onClick={() => setSelect(0)}>
            <h1 className={select === 0 ? 'auth-select' : ''}>휴대폰 인증</h1>
            <span className={select === 0 ? 'auth-select' : ''}></span>
          </button>
        </div>
        <div className="auth-content">
          {select === 0 ? (
            <>
              <h2 className="email-title">휴대폰 번호</h2>
              <div className="email-input-container">
                <input
                  autoComplete='off'
                  type="text"
                  className="email-input-num"
                  value={phone}
                  readOnly={data.phone !== ''}
                  placeholder='+82)01000000000 // 01000000000'
                  onChange={e => setPhone(e.target.value)}
                  required></input>
                <button onClick={sendEmail}>인증하기</button>
              </div>
              <h2 className="email-title">인증번호</h2>
              <div className="email-input-container">
                <input
                  autoComplete='off'
                  type="text"
                  className="email-input-num"
                  value={code}
                  onChange={e => setCode(e.target.value.toUpperCase())}
                  required></input>
                <button onClick={checkCode}>확인하기</button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="auth-next">
          <a href="/signUp">
            <button>이전</button>
          </a>
            <button onClick={next} className={codeLock ? "auth-next-button" : ''}>다음</button>
        </div>
      </section>
      <Footer />
    </div>
  )
}
export default SignUpPhone
