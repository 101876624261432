import React from 'react';
import './PinModal.css';

function PinModal({ time, title, setModalOpen, setMyPin, afterAction, action }) {

  const [password1, setPassword1] = React.useState('');
  const [alphabet1, setAlphabet1] = React.useState('');
  const [full, setFull] = React.useState();
  const nextFocus1 = React.useRef(null);


  const [next, setNext] = React.useState(false);

  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false)
  }

  const goNext = async() => {

    if(time === 1){
      // setMyPin(`${password1}${alphabet1.toUpperCase()}`)
      if(afterAction){
        const res = await action(`${password1}${alphabet1.toUpperCase()}`)
        if(!res){
          setPassword1("")
          setAlphabet1("")
        }else{
          closeModal()
        }
      }
    }else{
      if (password1.length === 4 && alphabet1.toUpperCase().length === 1) {
        setFull(`${password1}${alphabet1.toUpperCase()}`)
        setPassword1('')
        setAlphabet1('')
        setNext(true)
      }else{
        alert("정확한 간편패스워드를 입력해주세요.")
      }  
    }
    
  }

  const onOk = async() => {
    // loadingState.setText('잠시만 기다려주세요.')
    // loadingState.setOpen(true)
    // if(lock){
    //   alert('잠시만 기다려주세요.')
    //   return
    // }
    if(`${password1}${alphabet1.toUpperCase()}` !== full){
      alert('동일한 비밀번호를 입력해주세요.')
      return
    }
    
    setMyPin(full)
    setPassword1('')
    setAlphabet1('')
    setFull('')
    
    closeModal()
    if(afterAction){
      action(full)
      setPassword1('')
      setAlphabet1('')
      setFull('')
    }
    // loadingState.setOpen(false)
  }

  return (
    <div className="pin-modal">
      <div className="modal-container">
        <div className="modal-body" onClick={e => e.stopPropagation()}>
          <h1 style={{whiteSpace: 'pre-line', textAlign: 'center'}}>
            {
              !next ? title : '동일한 간편패스워드를 한번더 입력해주세요.'
            }
          </h1>

            <>
            <div className="pin-content">
            4 Number + 1 Alphabet
          </div>
          <div className='pin__input'>
            <input
            className='pin-input num'
            style={password1.length !== 0 ? {letterSpacing: '21rem'} : {letterSpacing: '50rem'}}

            type="password"
            placeholder="****"
            maxLength={4}
            minLength={4}
            pattern="[0-9]+"
            value={password1}
            onKeyPress={(e) => {
              // 정규표현식 /[0-9]/ 이 아니면 press X
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              setPassword1(e.target.value);
              if (e.target.value.length === 4) {
                nextFocus1.current.focus();
              }
            }}
            required
            />
            <input
            className='pin-input alp'
            ref={nextFocus1}
            type="password"
            value={alphabet1}
            placeholder="*"
            maxLength={1}
            minLength={1}
            pattern="[A-Za-z]+"
            onKeyPress={(e) => {
              // 정규표현식 ^[A-Za-z]+$/i 이 아니면 press X
              if (!/^[A-Za-z]+$/i.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              setAlphabet1(e.target.value.toUpperCase());
            }}
            required
            />
            </div>
            </>
           
          <div className="pin-btn">
            {
              !next ? 
              <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
              <button onClick={closeModal}>취소</button>
              <button onClick={goNext}>다음</button>
              </div>
              :
              <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
              <button onClick={closeModal}>취소</button>
              <button onClick={onOk}>다음</button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default PinModal
