
import client from '../../index'

/**
 * 내 작품 전체 리스트
 * @param {number} currentPage 현재 페이지
 * @param {number} row 요청 갯수
 * @param {Stirng} search 검색
*/
const getMyList = async(currentPage, row, search) => {
  const setData = {
    page : currentPage,
    count : row,
    search: search
  }
    const res = await client.json.get('/user/work/my-work', {params: setData});
    return res.data;
}

/**
 * 내 CoA 전체 리스트
 * @param {number} currentPage 현재 페이지
 * @param {number} row 요청 갯수
 * @param {Stirng} search 검색
*/
const getMyCoAList = async(currentPage, row, search) => {
  const setData = {
    page : currentPage,
    count : row,
    search: search
  }
    const res = await client.json.get('/user/work/my-work/coa', {params: setData});
    return res.data;
}

/**
  작품 상세보기
   * @param {number} pk 간편 패스워드
*/
const getDetail = async(pk) => {
    const res = await client.json.get(`/user/work/my-work/${pk}`);
    return res.data;
}

/**
  작품 삭제
   * @param {number} pk 간편 패스워드
*/
const deleteWork = async(pk) => {
  const res = await client.json.delete(`/user/work/my-work/${pk}`);
  return res.data;
}


/**
  내 전시이력 가져오기
*/
const getMyHistory = async() => {
  const res = await client.json.get('/user/my-exhibition/history');
  return res.data;
}




/**
  (작가)작품 추가
   * @param {File} mainImage 메인이미지
   * @param {File} subImage 서브이미지 최대 10개
   * @param {String} koTitle 한글 제목
   * @param {String} enTitle 영어 제목
   * @param {number} timeSet 1: 고미술, 2: 근현대
   * @param {number} workTypePK 작품 유형pk
   * @param {String} start 오픈 시간
   * @param {String} end 마감 시간
   * @param {String} material 재질
   * @param {String} unit 단위
   * @param {String} vertical 높이/시
   * @param {String} horizontal 너비/분
   * @param {String} width 폭/초
   * @param {String} collection 소장처
   * @param {String} detail 상세 설명
   * @param {number} saleType 1: 원, 2: $
   * @param {String} price 판매가격
   * @param {number} view 0: 미노출, 1: 노출, 2:삭제됨
   * @param {number} sale 0: 비매, 1: 판매
   * @param {String} history 전시 이력
   * @param {number} totalEdition 에디션 수
   * @param {number} totalIssue 총 발행 수
*/
const addByArtist = async(mainImage, subImage, koTitle, enTitle, timeSet, workTypePK, start, end, material, unit, vertical, horizontal, width, collection, detail, saleType, price, view, sale, history, totalEdition, totalIssue) => {
  

    const formData = new FormData();
    formData.append("MAIN_IMAGE", mainImage); 
    for(let i = 0; i < subImage.length; i++){
      formData.append("SUB_IMAGE", subImage[i]); 
    }
    formData.append("TITLE", koTitle); 
    formData.append("ENG_TITLE", enTitle); 
    formData.append("DATE_TYPE", timeSet); 
    formData.append("WORK_TYPE_PK", workTypePK); 
    formData.append("START_CREATE", start); 
    formData.append("END_CREATE", end); 
    formData.append("MATERIAL", material); 
    formData.append("UNIT", unit); 
    formData.append("SIZE_X", vertical); 
    formData.append("SIZE_Y", horizontal); 
    formData.append("SIZE_W", width); 
    formData.append("COLLECTION", collection); 
    formData.append("DETAIL_NOTE", detail); 
    formData.append("PRICE_UNIT", saleType); 
    formData.append("PRICE", price); 
    formData.append("VIEW", view); 
    formData.append("MARKET", sale);
    formData.append("EXHIBIT_HISTORY", JSON.stringify(history)); 
    formData.append("EDITION", totalEdition); 
    formData.append("EDITION_TOTAL", totalIssue); 

    // for (let key of formData.keys()) {
    //     console.log(key, ":", formData.get(key));
    // }

    const res = await client.formData.post(`/user/work/my-work`, formData);
    return res.data;
}

/**
  (작가)작품 수정
   * @param {String} uniqueKey 작품 uniqueKey
   * @param {File || ''} mainImage 메인이미지
   * @param {FileArray} newSubImage 새로 추가한 서브이미지
   * @param {String} oldSubImage 삭제한 서브 이미지
   * @param {String} koTitle 한글 제목
   * @param {String} enTitle 영어 제목
   * @param {number} timeSet 1: 고미술, 2: 근현대
   * @param {number} workTypePK 작품 유형pk
   * @param {String} start 오픈 시간
   * @param {String} end 마감 시간
   * @param {String} material 재질
   * @param {String} unit 단위
   * @param {String} vertical 높이/시
   * @param {String} horizontal 너비/분
   * @param {String} width 폭/초
   * @param {String} collection 소장처
   * @param {String} detail 상세 설명
   * @param {number} saleType 1: 원, 2: $
   * @param {String} price 판매가격
   * @param {number} view 0: 공개, 1: 비공개
   * @param {number} sale 0: 비매, 1: 판매
   * @param {String} history 전시 이력
   * @param {number} totalEdition 에디션 수
   * @param {number} totalIssue 총 발행 수
*/
const editByArtist = async(uniqueKey, mainImage, newSubImage, oldSubImage, koTitle, enTitle, timeSet, workTypePK, start, end, material, unit, vertical, horizontal, width, collection, detail, saleType, price, view, sale, history, totalEdition, totalIssue) => {
    

  const formData = new FormData();

  formData.append("UNIQUE_KEY", uniqueKey);
  formData.append("MAIN_IMAGE", mainImage);
  if(newSubImage.length === 0){
    formData.append("NEW_SUB", '');
  } else {
    for(let i = 0; i < newSubImage.length; i++){
      formData.append("NEW_SUB", newSubImage[i]);
    } 
  }
  
  formData.append("OLD_SUB", oldSubImage);
  formData.append("TITLE", koTitle); 
  formData.append("ENG_TITLE", enTitle); 
  formData.append("DATE_TYPE", timeSet); 
  formData.append("WORK_TYPE_PK", workTypePK); 
  formData.append("START_CREATE", start); 
  formData.append("END_CREATE", end); 
  formData.append("MATERIAL", material); 
  formData.append("UNIT", unit); 
  formData.append("SIZE_X", vertical); 
  formData.append("SIZE_Y", horizontal); 
  formData.append("SIZE_W", width); 
  formData.append("COLLECTION", collection); 
  formData.append("DETAIL_NOTE", detail); 
  formData.append("PRICE_UNIT", saleType); 
  formData.append("PRICE", price); 
  formData.append("VIEW", view); 
  formData.append("MARKET", sale);
  formData.append("EXHIBIT_HISTORY", JSON.stringify(history)); 
  formData.append("EDITION", totalEdition); 
  formData.append("EDITION_TOTAL", totalIssue); 

  // for (let key of formData.keys()) {
  //     console.log(key, ":", formData.get(key));
  // }

  const res = await client.formData.post(`/user/work/my-work/edit`, formData);
  return res.data;
}


/**
  COA 생성
   * @param {String} SHORT_PASSWORD 간편 패스워드
   * @param {String} UUID 작품의 UUID
*/
const createCOA = async(SHORT_PASSWORD, UUID) => {
  const setData = {
    SHORT_PASSWORD: SHORT_PASSWORD,
    UUID: UUID
  }
  const res = await client.json.post('/user/COA', setData);
  return res.data;
}


/**
  (기관)작품 추가
   * @param {File} mainImage 메인이미지
   * @param {File} subImage 서브이미지 최대 10개
   * @param {String} koTitle 한글 제목
   * @param {String} enTitle 영어 제목
   * @param {String} koArtist 한글 작가
   * @param {String} enArtist 영어 작가
   * @param {String} artistBirthday 작가 생일
   * @param {String} artistDeath 작가 몰일
   * @param {number} timeSet 1: 고미술, 2: 근현대
   * @param {number} workTypePK 작품 유형pk
   * @param {String} start 오픈 시간
   * @param {String} end 마감 시간
   * @param {String} material 재질
   * @param {String} unit 단위
   * @param {String} vertical 높이/시
   * @param {String} horizontal 너비/분
   * @param {String} width 폭/초
   * @param {String} collection 소장처
   * @param {String} detail 상세 설명
   * @param {number} saleType 1: 원, 2: $
   * @param {String} price 판매가격
   * @param {number} view 0: 미노출, 1: 노출, 2:삭제됨
   * @param {number} sale 0: 비매, 1: 판매
   * @param {String} history 전시 이력
   * @param {number} totalEdition 에디션 수
   * @param {number} totalIssue 총 발행 수
*/
const addByAgency = async(mainImage, subImage, koTitle, enTitle, koArtist, enArtist, artistBirthday, artistDeath, timeSet, workTypePK, start, end, material, unit, vertical, horizontal, width, collection, detail, saleType, price, view, sale, history, totalEdition, totalIssue) => {
  

  const formData = new FormData();
  formData.append("MAIN_IMAGE", mainImage); 
  for(let i = 0; i < subImage.length; i++){
    formData.append("SUB_IMAGE", subImage[i]); 
  }
  formData.append("TITLE", koTitle); 
  formData.append("ENG_TITLE", enTitle); 


  formData.append("KO_NAME", koArtist); 
  formData.append("ENG_NAME", enArtist); 
  formData.append("BIRTHDAY", artistBirthday); 
  formData.append("DEATH_DAY", artistDeath); 


  formData.append("DATE_TYPE", timeSet); 
  formData.append("WORK_TYPE_PK", workTypePK); 
  formData.append("START_CREATE", start); 
  formData.append("END_CREATE", end); 
  formData.append("MATERIAL", material); 
  formData.append("UNIT", unit); 
  formData.append("SIZE_X", vertical); 
  formData.append("SIZE_Y", horizontal); 
  formData.append("SIZE_W", width); 
  formData.append("COLLECTION", collection); 
  formData.append("DETAIL_NOTE", detail); 
  formData.append("PRICE_UNIT", saleType); 
  formData.append("PRICE", price); 
  formData.append("VIEW", view); 
  formData.append("MARKET", sale);
  formData.append("EXHIBIT_HISTORY", JSON.stringify(history)); 
  formData.append("EDITION", totalEdition); 
  formData.append("EDITION_TOTAL", totalIssue); 

  // for (let key of formData.keys()) {
  //     console.log(key, ":", formData.get(key));
  // }

  const res = await client.formData.post(`/user/work/my-work/fix-artist`, formData);
  return res.data;
}


/**
  (기관)작품 수정
   * @param {String} uniqueKey 작품 uniqueKey
   * @param {File || ''} mainImage 메인이미지
   * @param {FileArray} newSubImage 새로 추가한 서브이미지
   * @param {String} oldSubImage 삭제한 서브 이미지
   * @param {String} koTitle 한글 제목
   * @param {String} enTitle 영어 제목
   * @param {String} koArtist 한글 작가
   * @param {String} enArtist 영어 작가
   * @param {String} artistBirthday 작가 생일
   * @param {String} artistDeath 작가 몰일
   * @param {number} timeSet 1: 고미술, 2: 근현대
   * @param {number} workTypePK 작품 유형pk
   * @param {String} start 오픈 시간
   * @param {String} end 마감 시간
   * @param {String} material 재질
   * @param {String} unit 단위
   * @param {String} vertical 높이/시
   * @param {String} horizontal 너비/분
   * @param {String} width 폭/초
   * @param {String} collection 소장처
   * @param {String} detail 상세 설명
   * @param {number} saleType 1: 원, 2: $
   * @param {String} price 판매가격
   * @param {number} view 0: 공개, 1: 비공개
   * @param {number} sale 0: 비매, 1: 판매
   * @param {String} history 전시 이력
   * @param {number} totalEdition 에디션 수
   * @param {number} totalIssue 총 발행 수
*/
const editByAgency = async(uniqueKey, mainImage, newSubImage, oldSubImage, koTitle, enTitle, koArtist, enArtist, artistBirthday, artistDeath, timeSet, workTypePK, start, end, material, unit, vertical, horizontal, width, collection, detail, saleType, price, view, sale, history, totalEdition, totalIssue) => {
    

  const formData = new FormData();

  formData.append("UNIQUE_KEY", uniqueKey);
  formData.append("MAIN_IMAGE", mainImage);
  if(newSubImage.length === 0){
    formData.append("NEW_SUB", '');
  } else {
    for(let i = 0; i < newSubImage.length; i++){
      formData.append("NEW_SUB", newSubImage[i]);
    } 
  }
  
  formData.append("OLD_SUB", oldSubImage);
  formData.append("TITLE", koTitle); 
  formData.append("ENG_TITLE", enTitle); 



  formData.append("KO_NAME", koArtist); 
  formData.append("ENG_NAME", enArtist); 
  formData.append("BIRTHDAY", artistBirthday); 
  formData.append("DEATH_DAY", artistDeath); 



  formData.append("DATE_TYPE", timeSet); 
  formData.append("WORK_TYPE_PK", workTypePK); 
  formData.append("START_CREATE", start); 
  formData.append("END_CREATE", end); 
  formData.append("MATERIAL", material); 
  formData.append("UNIT", unit); 
  formData.append("SIZE_X", vertical); 
  formData.append("SIZE_Y", horizontal); 
  formData.append("SIZE_W", width); 
  formData.append("COLLECTION", collection); 
  formData.append("DETAIL_NOTE", detail); 
  formData.append("PRICE_UNIT", saleType); 
  formData.append("PRICE", price); 
  formData.append("VIEW", view); 
  formData.append("MARKET", sale);
  formData.append("EXHIBIT_HISTORY", JSON.stringify(history)); 
  formData.append("EDITION", totalEdition); 
  formData.append("EDITION_TOTAL", totalIssue); 

  // for (let key of formData.keys()) {
  //     console.log(key, ":", formData.get(key));
  // }

  const res = await client.formData.post(`/user/work/my-work/fix-artist/edit`, formData);
  return res.data;
}




/**
 * 내가 좋아요한 목록
 * @param {number} currentPage 현재 페이지
 * @param {number} row 요청 갯수
 * @param {number} type 0: 작품, 1: 작가, 2: 기관
*/
const getMyLike = async(currentPage, row, type) => {
  const setData = {
    page : currentPage,
    count : row,
    type: type
  }
    const res = await client.json.get('/user/heart', {params: setData});
    return res.data;
}

/**
 * 내가 좋아요한 nft 작품
 * @param {number} currentPage 현재 페이지
 * @param {number} row 요청 갯수
*/
const getMyLikeNft = async(currentPage, row, type) => {
  const setData = {
    page : currentPage,
    count : row
  }
    const res = await client.json.get('/user/nft/heart', {params: setData});
    return res.data;
}

/**
 * 내가 좋아요한 작가
 * @param {number} currentPage 현재 페이지
 * @param {number} row 요청 갯수
*/
const getMyLikeArtist = async(currentPage, row, type) => {
  const setData = {
    page : currentPage,
    count : row
  }
    const res = await client.json.get('/user/artist/heart', {params: setData});
    return res.data;
}


/**
 * 작품 ENC_SERIAL 가져오기
 * @param {Stirng} key 작품 unique_key
*/
const getEnc = async(key) => {
  const setData = {
    UNIQUE_KEY : key,
  }
    const res = await client.json.post('/user/work/getEncSerial', setData);
    return res.data;
}



/**
  작품 경매 입찰
  * @param {number} pk
  * @param {number} price
*/

const bid = async (pk, price) => {
  const setData = {
    PRICE : price
  }

  const res = await client.json.post(`/user/bid/${pk}`, setData);
  return res.data;
}


/**
  Q-CoA 핀 변경 1단계 - 이메일
*/
const coaSendEmail = async () => {
  const res = await client.json.put(`/user/coa/password`, );
  return res.data;
}

/**
  Q-CoA 핀 변경 2단계 - 인증번호 확인
   * @param {Stirng} code 이메일로 받은 코드
*/
const coaCheckCode = async (code) => {
  const postData = {
    code: code
  }
  const res = await client.json.post(`/user/coa/password`, postData);
  return res.data;
}

/**
  Q-CoA 핀 변경 2단계 - 인증번호 확인
   * @param {number} pk 변경할 작품의 pk
   * @param {Stirng} pin 변경할 비밀번호
   * @param {Stirng} token 2단계에서 받은 토큰
*/
const coaChangePassword = async (pk, pin, token) => {
  const postData = {
    new_short_password: pin,
    token: token
  }
  const res = await client.json.patch(`/user/coa/password/${pk}`, postData);
  return res.data;
}


/**
  Q-CoA 핀 확인
   * @param {number} pk 작품의 pk
   * @param {Stirng} pin 비밀번호
*/
const coaCheckPassword = async (pk, pin) => {
  const postData = {
    work_pk: pk,
    short_password: pin
  }
  const res = await client.json.post(`/user/work/password`, postData);
  return res.data;
}

const work = {
    getMyHistory, getMyCoAList,
    getMyList, getDetail, deleteWork,
    addByArtist,editByArtist,
    addByAgency, editByAgency,
    createCOA, getMyLike, getMyLikeNft, getMyLikeArtist,
    getEnc,
    bid,
    coaSendEmail, coaCheckCode, coaChangePassword,
    coaCheckPassword
}
export default work
