import React, { useRef, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import DropDown from '../../../../components/DropDown/DropDown'
import Footer from '../../../../components/Footer/Footer'
import Header from '../../../../components/Header/Header'
import HeaderSmall from '../../../../components/Header/HeaderSmall/HeaderSmall'
import CheckModal from '../../../../components/Modal/CheckModal'
import ModalPortal from '../../../../components/Modal/ModalPortal'
import SectionHeader from '../../../../components/SectionHeader/SectionHeader'
import './ArtistExhibition.css'

import profile from '../../../../services/auth/profile'
import { exhibitionImage, handleImgError } from '../../../../services/imageRoute'
import { checkDate, handleInputMaxLength } from '../../../../hook/utils/validation'
import { checkMainImageSize } from '../../../../hook/utils/fileSize'

function ArtistExhibitionEdit() {

  const navigate = useNavigate();
  const { countryList } = useSelector(v => v.setting, shallowEqual)
  const { typeList } = useSelector(v => v.setting.exhibition, shallowEqual)
  const { min, today } = useSelector(v => v.setting.date, shallowEqual)

  const location = useLocation();
  const {data} = location.state;

  // console.log(`data :: ${JSON.stringify(data)}`)

  const [image, setImage] = useState(data.image)
  const [imageShow, setImageShow] = useState(`${exhibitionImage}/${data.image}`)
  const [title, setTitle] = useState(data.title)
  const [ place, setPlace] = useState(data.place)
  const [ country, setCountry] = useState(data.country)
  const [ type, setType] = useState(data.type)
  const [ date, setDate] = useState(data.date)
  const [ end, setEnd] = useState(data.end)
  const [ publisher, setPublisher] = useState(data.publisher) // 주관자
  const [ hoster, setHoster] = useState(data.hoster) // 주최자
  const [ planner, setPlanner] = useState(data.planner) // 기획자
  const [ reference, setReference] = useState(data.reference)
  const [ work, setWork] = useState(data.work)
  
  const saveImgFile = e => {
    e.preventDefault();
    const fileList = e.target.files
    const file = fileList[fileList.length - 1]

    const check = checkMainImageSize(file.size)
    if(!check) return

    setImage(file);
    setImageShow(URL.createObjectURL(file));
  }

  const done = async () => {
    if (image === null) {
      alert('대표 이미지를 설정해주세요.')
      return
    }
    if (title === '') {
      alert('전시명을 입력해주세요.')
      return
    }
    if (place === '') {
      alert('개최장소를 입력해주세요.')
      return
    }

    if (date === '' || end === '') {
      alert('개최기간을 입력해주세요.')
      return
    }
    // if (publisher === '') {
    //   alert('주관을 입력해주세요.')
    //   return
    // }
    // if (hoster === '') {
    //   alert('주최를 입력해주세요.')
    //   return
    // }
    // if (planner === '') {
    //   alert('기획자를 입력해주세요.')
    //   return
    // }

    const sendCountry = country ?? countryList[0]
    const sendType = type ?? typeList[0]
    
    const res = await profile.changeExhibition(image, title, place, sendCountry,
      sendType, date, end, publisher, planner, hoster, reference, [], data.pk)
    if (!res.resultCode) {
      alert('수정에 실패했습니다.')
      return
    }
    alert('수정 완료되었습니다.')
    navigate(-1)
  
  }

  return (
    <div className="artist-exhibition">
      <Header login={true} colored="black" detail={true} />
      <SectionHeader title={'전시회 수정'} />
      <div className="a-ex-edit-image">
        <div className="container a-ex-edit-image-container">
          <h2 className="a-ex-img-tlt">대표 이미지 *</h2>
          <img
            className={imageShow ? 'a-ex-img-show' : 'a-ex-img-hide'}
            src={imageShow}
            alt="선택 이미지"
            onError={handleImgError}
            onContextMenu={
              (e) => {
                  e.preventDefault();
                  
              }
            }
          />
          <button
            className={image ? 'edit-photo' : 'edit-photo a-ex-img-hide'}
            onClick={() => {setImage(null); setImageShow(null)}}
          ></button>
          <form method="post" enctype="multipart/form-data">
            <div className="button">
              <label className="image-button" for="chooseFile"></label>
            </div>
            <input
              type="file"
              id="chooseFile"
              name="chooseFile"
              accept="image/*"
              onChange={saveImgFile}
            />
          </form>
          {/* <span className="ma-img-tlt--sub image-size-check">*5MB 이하</span> */}
        </div>
      </div>
      <section className="container a-exhibition-content">
        <ul className="a-ex-edit-info">
          <li>
            <h2>전시명 *</h2>
            <input type="text" value={title}  onChange={e => setTitle(e.target.value)}></input>
          </li>
          <li>
            <h2>개최장소 *</h2>
            <input type="text" value={place}  onChange={e => setPlace(e.target.value)}></input>
          </li>
          <li>
            <h2>국가 *</h2>
            <DropDown options={countryList} setValue={setCountry} select={countryList.indexOf(country)} onChange={e => setCountry(e.target.value)}/>
          </li>
          <li>
            <h2>전시형태 *</h2>
            <DropDown options={typeList} setValue={setType} select={typeList.indexOf(type)} onChange={e => setType(e.target.value)}/>
          </li>
          <li>
            <h2>개최일시 *</h2>
            <div className="mb-date-container" >
              <input
                type="date"
                id="date"
                className="date-input"
              min={min}
              max={today}
              value={date}
              onChange={e => checkDate(setDate, e)}
              ></input>
              <span className="date-bar">-</span>
              <input
                type="date"
                id="date"
                className="date-input"
               
              min={min}
              max={today}
              value={end}
              onChange={e => checkDate(setEnd, e)}
              ></input>
            </div>
          </li>
          <li>
            <h2>주최</h2>
            <input type="text" value={hoster} onChange={e => setHoster(e.target.value)}></input>
          </li>
          <li>
            <h2>주관</h2>
            <input type="text" value={publisher} onChange={e => setPublisher(e.target.value)}></input>
          </li>
          <li>
            <h2>기획자</h2>
            <input type="text" value={planner} onChange={e => setPlanner(e.target.value)}></input>
          </li>
          <li>
            <h2>전시 설명</h2>
            <textarea
              placeholder="한글 500자 이내(공백 포함)"
              maxLength={'500'}
              value={reference}
              onChange={e => handleInputMaxLength(setReference, e)}></textarea>
          </li>
        </ul>
        <div className="a-ex-edit--button">
          <button onClick={() => navigate(-1) } >취소</button>
          <button onClick={done}>저장</button>

        </div>
      </section>
      <Footer />
    </div>
  )
}
export default ArtistExhibitionEdit
