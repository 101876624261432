import MetaMaskSDK from '@metamask/sdk';
import Web3 from "web3";


import { web3Net } from './abi';
import { createURI } from './urlToFile';

const useNetwork = web3Net;
const serverWallet = process.env.REACT_APP_SERVER_WALLET;


/**
 * 메타마스크 연결
  * @param {context} walletState
 */
const connect = async (walletState)=>{
  let ethereum;
  walletState.setWallet("metamask")
  if(window.ethereum){
      walletState.setProvider(window.ethereum)
      ethereum = window.ethereum
  }
  else{
    const options = {
      injectProvider: false,
      communicationLayerPreference: 'webrtc',
    };
    const MMSDK = new MetaMaskSDK(options);
    const provider = MMSDK.getProvider();
    walletState.setProvider(provider);  
    ethereum = provider
  }
  await ethereum.request({method : "eth_requestAccounts"}).then(()=>{})

  const web3 = new Web3(ethereum);

  // 지갑 주소 얻기
  const address = await web3.eth.getAccounts();
  walletState.setAddress(address[0])

  console.log(address)

  // chainId 얻기
  const chainId = await web3.eth.getChainId();
  console.log(chainId)

  if(chainId !== useNetwork.chainId){
    web3.eth.currentProvider.request({
      jsonrpc: '2.0',
      method: 'wallet_addEthereumChain',
      params: [
          {
            chainId: Web3.utils.toHex(useNetwork.chainId),
              chainName: useNetwork.chainName,
              rpcUrls: [useNetwork.rpc],
              nativeCurrency: {
                  name: useNetwork.symbol,
                  symbol: useNetwork.symbol,
                  decimals: useNetwork.decimals
              },
              blockExplorerUrls: [useNetwork.block]
          }
      ],
      id: 0
  }).catch((error) => {
        // Error returned when rejected
        console.error(error);
      });
      web3.eth.currentProvider.request({
        method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex(useNetwork.chainId) }],
        }).then((result) => {
          // Returns request result
          // console.log(result);
        })
        .catch((error) => {
          // Error returned when rejected
          console.error(error);
        });
  }
}

/**
 * NFT 민팅
  * @param {context} walletState 
  * @param {*} work 작품 정보 
 */
const minting = async (walletState, work, navigate)=>{
  try{
    const web3 = new Web3(walletState.provider);
    
    const abi = useNetwork.abi;
    const contractAddress = useNetwork.contractAddress;
    const uri = await createURI(work, contractAddress)
      
    const contractInstance = new web3.eth.Contract(abi, contractAddress ,{
      from : walletState.address
    });
    
    const tx = {
      from: walletState.address,
      to: contractAddress,
      // gas: '158650', // 158650
      // gasPrice: '30000000000',// 40gwie
      value: "0",
      data: contractInstance.methods.mintNFT(uri).encodeABI()
    };
  
    console.log('start')
    await web3.eth.sendTransaction(tx, async function (error, hash) {
      console.log('aa')
      if (!error) {
        walletState.setCurrentHash(hash)
        // let scanPage = window.open("about:blank", "_blank");
        // const url = `${useNetwork.block}/tx/${hash}`
        // if(scanPage) scanPage.location.href = url;
        alert('NFT 민팅이 되었습니다.')
        navigate(-1)
      }
    });
    console.log('end')
  }catch(err){
    console.log(err)
    if(err === 'already'){
      alert('이미 민팅된 작품입니다.');
    }
  }
  // .then(v => {
  //   if(v.status){
  //     const txHash = v.transactionHash;
  //     const contract = v.to;
  //     const owner = v.from;
  //     const tokenId = v.logs[0].topics.pop()
  //     contractInstance.methods.tokenURI(tokenId).call();
  //   }
  // });
}

/**
 * NFT 권한 서버지갑으로 옮기기
  * @param {context} walletState 
  * @param {*} tokenId 작품 정보 
  * @param {*} action 이후 동작
 */
const approve = async (walletState, tokenId, action)=>{  
  const web3 = new Web3(walletState.provider);
  const abi = useNetwork.abi;
  const contractAddress = useNetwork.contractAddress;
  
  const contractInstance = new web3.eth.Contract(abi, contractAddress ,{
    from : walletState.address
  });
  
  const tx = {
    from: walletState.address,
    to: contractAddress,
    // gas: '158650', // 158650
    // gasPrice: '30000000000',// 40gwie
    value: "0",
    data: contractInstance.methods.approve(serverWallet, tokenId).encodeABI()
  };

  await web3.eth.sendTransaction(tx, async function (error, hash) {
    if (!error) {
      walletState.setCurrentHash(hash)
      await action()
    }
  });
}

const getNFTList = async(walletState) => {
  const web3 = new Web3(walletState.provider);
  const abi = useNetwork.abi;
  const contractAddress = useNetwork.contractAddress;

  const contractInstance = new web3.eth.Contract(abi, contractAddress, {
    from: walletState.address
  });

  const balance = await contractInstance.methods.balanceOf(walletState.address).call();

  const nftList = [];
  for(let i = 0; i < balance; i++){
    const tokenId = await contractInstance.methods.tokenOfOwnerByIndex(walletState.address, i).call();
    const uri =  await contractInstance.methods.tokenURI(tokenId).call();
    const jsonData = {
      uri: uri,
      tokenId: tokenId,
    }
    nftList.push(jsonData)
  }
  walletState.setNftList(nftList)
  return nftList
}


const metamask = {
  connect, minting, approve, getNFTList
}

export default metamask