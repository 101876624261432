import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import DropDown from '../../../../components/DropDown/DropDown'
import Footer from '../../../../components/Footer/Footer'
import Header from '../../../../components/Header/Header'
import HeaderSmall from '../../../../components/Header/HeaderSmall/HeaderSmall'
import CheckModal from '../../../../components/Modal/CheckModal'
import SignModal from '../../../../components/Modal/SignModal'
import QrModal from '../../../../components/Modal/QrModal'
import ModalPortal from '../../../../components/Modal/ModalPortal'
import SectionHeader from '../../../../components/SectionHeader/SectionHeader'
import './ArtistProfile.css'
import question from '../../../../assets/question-line.png'

import auth from '../../../../services/auth/auth'
import { useDispatch } from 'react-redux'
import * as authAction from '../../../../store/reducers/auth/actions'
import { useSelector, shallowEqual } from 'react-redux'

import profile from '../../../../services/auth/profile'
import { handleImgError, userImage } from '../../../../services/imageRoute'
import { checkUserImageSize } from '../../../../hook/utils/fileSize'
import AddressModal from '../../../../components/Modal/AddressModal'
import { LoadingState } from '../../../../context/loadingStateProvider'
import Tooltip from '../../../../components/Tooltip'
import { handleInputMaxLength } from '../../../../hook/utils/validation'

function ArtistProfile() {
  const loadingState = React.useContext(LoadingState);

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { artist, type } = useSelector(v => v.auth, shallowEqual)
  const { countryList } = useSelector(v => v.setting, shallowEqual)

  const mediaMatch = window.matchMedia('(max-width: 480px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
    React.useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    });
  const ko_pattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; //한글
  const en_pattern = /[a-zA-Z]/; //영어

  
  const [addressOpen, setAddressOpen] = useState(false)

  const [address, setAddress] = React.useState(artist.address)
  const [addressDetail, setAddressDetail] = React.useState(artist.address_DETAIL)
  const [picture, setPicture] = React.useState(artist.picture === null ? null : `${userImage}/${artist.picture}`)
  const [pictureShow, setPictureShow] = React.useState(artist.picture === null ? null : `${userImage}/${artist.picture}`)

  const [koFirstName, setKoFirstName] = React.useState(artist.first_NAME);
  const [koLastName, setKoLastName] = React.useState(artist.name);
  const [enFirstName, setEnFirstName] = React.useState(artist.first_ENG_NAME);
  const [enLastName, setEnLastName] = React.useState(artist.eng_NAME);
  const [koNickname, setKoNickname] = React.useState(artist.nickname)
  const [enNickname, setEnNickname] = React.useState(artist.eng_NICKNAME)
  const [useName, setUseName] = React.useState(null)

  const [country, setCountry] = React.useState(artist.country)
  const [birthday, setBirthday] = React.useState(artist.birthday)
  const [phone, setPhone] = React.useState(artist.phone_NUMBER)
  const [localNumber, setLocalNumber] = React.useState(artist.location_NUMBER);

  const [sign, setSign] = React.useState(null)
  const [userType, setUserType] = React.useState(null)
  const [uEmail, setUEmail] = React.useState(artist.add_EMAIL)
  const [sns, setSns] = React.useState(artist.sns)
  const [introduce, setIntroduce] = React.useState(artist.introduce)

  const [edu, setEdu] = React.useState(null)
  const [eduTable, setEduTable] = React.useState(null)
  const [eduTableMobile, setEduTableMobile] = React.useState(null)

  const [exhibitionTable, setexhibitionTable] = React.useState(null)
  const [exhibitionTableMobile, setexhibitionTableMobile] = React.useState(null)
  const [exhibition, setExhibition] = React.useState(null)
  const [kycInfo, setKycInfo] = React.useState(null)

  const [accountCompany, setAccountCompany] = React.useState()
  const [accountNumber, setAccountNumber] = React.useState()
  const [accountOwner, setAccountOwner] = React.useState()

  const [withdrawalModal, setWithdrawalModal] = React.useState(false)

  const getInfo = async (myType) => {
    if (myType === 2) {
      navigate('/myPage/gallery');
    } else {
      const res = await auth.getArtistInfo()
      setKycInfo(res.kyc)
      setAccountCompany(res.kyc?.account_COMPANY)
      setAccountNumber(res.kyc?.account_NUMBER)
      setAccountOwner(res.kyc?.name)
      dispatch(authAction.SET_ARTIST_INFO(res.data))
    }
  }
  const getList = async () => {
    const res = await profile.school()
    // console.log(`res :: ${JSON.stringify(res)}`)
    setEdu(res.wellParsedListData)
  }

  const exhibitionDelete = async (pk) => {
    const res = await profile.deleteExhibition(pk)
    if (!res.resultCode) {
      alert('삭제 실패했습니다.');
      return
    }
    alert('삭제되었습니다.');
    setExhibition(exhibition.filter(item => item.pk !== pk))
  }

  const withdrawalUser = async () => {
    loadingState.setText('잠시만 기다려주세요.')
    loadingState.setOpen(true)

    const res = await auth.withdrawalUser()

    if(!res.resultCode){
      if(res.message === 'rental or transaction'){
        alert('진행 중인 거래가 있습니다.\n완료 후 탈퇴를 진행해 주세요.')
        loadingState.setOpen(false)
        loadingState.setText('')
        return;
      }
      alert('관리자에게 문의해주세요.')
      loadingState.setOpen(false)
      loadingState.setText('')
      return
    }

    localStorage.removeItem('QART_ACCESS')
    dispatch(authAction.LOGOUT())
    alert('회원탈퇴를 완료했습니다.')
    
    loadingState.setOpen(false)
    loadingState.setText('')
    navigate('/', {replace: true})
  }
  React.useEffect(() => {
    List()
  }, [])

  const List = async () => {
    const res = await profile.exhibition();
    setExhibition(res.exhibitions)
  }

  React.useEffect(() => {
    getInfo(type)
  }, [])


  React.useEffect(() => {
    getList()

  }, [])

  React.useEffect(() => {
    if (exhibition === null) {
      return
    }
    const table = []
    const tableMobile = []
    // console.log(`exhi :: ${JSON.stringify(exhibition)}`)
    exhibition.map((item, index) => {
      table.push(
        <li className="ap-list">
          <span>{index + 1}</span>
          <span>{item.title}</span>
          <span>{item.place}</span>
          <span>{item.country} </span>
          <span>{item.date}<br />{item.end}</span>
          <span>{item.type}</span>

          <div className="gp-liat--button">
           <button onClick={() => { navigate('/mypage/artist/exhibition/edit', { state: { data: item } }) }}>수정</button>
           <button onClick={() => { exhibitionDelete(item.pk) }}>삭제</button>
          </div>
        </li>
      )
      tableMobile.push(
        <li className="ap-list-mb">
          <div className="ap-list-mb-content">
            <span className="ap-list-title">전시명</span>
            <span>{item.title}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">개최장소</span>
            <span>
              {item.place}
            </span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">국가</span>
            <span>{item.country}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">전시일</span>
            <span>{item.date}~{item.end}</span>
          </div>
          <div className="ap-list-mb-button">
           <button onClick={() => { navigate('/mypage/artist/exhibition/edit', { state: { data: item } }) }}>수정</button>
           <button onClick={() => { exhibitionDelete(item.pk) }}>삭제</button>
          </div>
        </li>
      )
    })
    setexhibitionTable(table)
    setexhibitionTableMobile(tableMobile)
  }, [exhibition])

  React.useEffect(() => {

    // console.log(`edu :: ${JSON.stringify(edu)}`)
    if (edu === null) {
      return
    }
    const table = []
    const tableMobile = []

    edu.map((item, index) => {
      table.push(
        <li className="ap-list">
          <span>{index + 1}</span>
          <span>{item.school}</span>
          <span>{item.country}</span>
          <span>{item.admission}</span>
          <span>{item.graduated}</span>
          <span>{item.degree_TYPE}</span>
          <div className="ap-liat--button">
            <button
              onClick={() => {
                navigate(`/mypage/artist/school/edit/${item.pk}`, {
                  state: { data: item },
                })
              }}
            >
              수정
            </button>
            <button
              onClick={() => {
                eduDelete(item.pk)
              }}
            >
              삭제
            </button>
          </div>
        </li>
      )
      tableMobile.push(
        <li className="ap-list-mb">
          <div className="ap-list-mb-content">
            <span className="ap-list-title">기관명</span>
            <span>{item.school}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">국가</span>
            <span>{item.country}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">입학</span>
            <span>{item.admission}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">졸업</span>
            <span>{item.graduated}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">학위종류</span>
            <span>{item.degree_TYPE}</span>
          </div>
          <div className="ap-list-mb-button">
            <button
              onClick={() => {
                navigate(`/mypage/artist/school/edit/${item.pk}`, {
                  state: { data: item },
                })
              }}
            >
              수정
            </button>
            <button
              onClick={() => {
                eduDelete(item.pk)
              }}
            >
              삭제
            </button>
          </div>
        </li>
      )
    })
    setEduTable(table)
    setEduTableMobile(tableMobile)
  }, [edu])

  React.useEffect(() => {
    setPicture(artist.picture === null ? null : `${userImage}/${artist.picture}`)
    setPictureShow(artist.picture === null ? null : `${userImage}/${artist.picture}`)
    
    setKoFirstName(artist.first_NAME)
    setKoLastName(artist.name)
    setEnFirstName(artist.first_ENG_NAME)
    setEnLastName(artist.eng_NAME)

    setKoNickname(artist.nickname)
    setEnNickname(artist.eng_NICKNAME)
    setUseName(null)

    setCountry(artist.country)
    setBirthday(artist.birthday)
    setLocalNumber(artist.location_NUMBER)
    setPhone(artist.phone_NUMBER)

    setSign(null)
    setUserType(null)
    setSns(artist.sns === 'null' ? '' : artist.sns)
    setIntroduce(artist.introduce === 'null' ? '' : artist.introduce)

    // setEdu(null)
    // setEduTable(null)
    // setEduTableMobile(null)
  }, [artist])


  const eduDelete = async pk => {
    const res = await profile.deleteSchool(pk)
    if (!res.resultCode) {
      alert('삭제 실패했습니다.')
      return
    }
    alert('삭제되었습니다.')
    setEdu(edu.filter(item => item.pk !== pk))
  }






  const moreList = {
    menu: [
      {
        title: '프로필',
        link: '/mypage/artist',
      },
      {
        title: 'My Archive',
        link: '/mypage/artist/archive',
      },
      {
        title: '구매목록',
        link: '/mypage/artist/wallet',
      },
      {
        title: '관심목록',
        link: '/mypage/artist/like',
      },
      // {
      //   title: '거래하기',
      //   link: '/mypage/artist/trade',
      // },
      {
        title: '알림',
        link: '/mypage/artist/notice',
      },
      {
        title: '멤버십',
        link: '/mypage/artist/membership',
      }
    ],
  }






  const editInfo = async () => {
    const res = await auth.editArtistInfo(
      artist.picture,
      picture === null ? '' : `${userImage}/${artist.picture}` === picture ? artist.picture : picture,
      koFirstName, koLastName, enFirstName, enLastName,
      koNickname,
      enNickname,
      useName === null ? artist.use_NAME : infoList.indexOf(useName),
      country,
      birthday,
      localNumber,
      phone,
      sign,
      userType === null ? artist.user_TYPE : infoList2.indexOf(userType) + 1,
      sns,
      introduce,
      address,
      addressDetail,
      uEmail,
      accountCompany,
      accountNumber,
      accountOwner
    )
    if (!res.resultCode) {
      alert('저장에 실패했습니다.')
      return
    }

    const myType =
      userType === null ? artist.user_TYPE : infoList2.indexOf(userType) + 1
    await getInfo(myType)
    if (myType !== 1) {
      dispatch(authAction.SET_TYPE(myType))
      navigate('/myPage/gallery')
    } else {
      setEdit(true)
      // setSign(null)
      // setUseName(null)
    }
  }

  const [edit, setEdit] = useState(true)

  // 수정 완료 모달창 노출 여부 state
  const [checkOpen, setCheckOpen] = useState(false)

  // 수정 완료 모달창 노출
  const showCheckModal = () => {
    setCheckOpen(!checkOpen)
  }

  // 서명 모달창 노출 여부 state
  const [signOpen, setSignOpen] = useState(false)

  // 서명 모달창 노출
  const showSignModal = () => {
    setSignOpen(!signOpen)
  }

  // QR 모달창 노출 여부 state
  const [qrOpen, setQrOpen] = useState(false)

  // QR 모달창 노출
  const showQrModal = () => {
    setQrOpen(!qrOpen)
  }

  const banklist = ['은행', '은행', '은행', '은행']
  const infoList = ['본명으로 활동(Real Name)', '활동명으로 활동(Nickname)']
  const infoList2 = ['작가', '기관']

  const saveImgFile = e => {
    e.preventDefault();
    const fileList = e.target.files
    const file = fileList[fileList.length - 1]

    const check = checkUserImageSize(file.size)
    if (!check) return

    setPicture(file);
    setPictureShow(URL.createObjectURL(file));
  }

  return (
    <div
      className={edit ? 'mypage-artist' : 'mypage-artist mypage-artist-profile'}
    >
      <Header colored="black" />
      <nav className="search">
        <div className="container search__container">
          <h2 className="search__title">마이페이지</h2>
        </div>
      </nav>
      <HeaderSmall moreList={moreList} active={true} choice={0} />
      {edit ? <></> : <SectionHeader title="프로필 수정" />}

      <div className="artist-profile gallery-profile">
        <section
          className={
            edit
              ? 'container artist-profile-edit cant-edit'
              : 'container artist-profile-edit'
          }
        >
          {edit ? (
            <div className="ap-edit-header">
              <div className="ap-edit-header-mb">
                <h1>프로필 관리</h1>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {/* <button onClick={() => showQrModal()}>QR 발급</button> */}
                  <button onClick={() => setEdit(false)}>수정하기</button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="ap-edit-content">
            <div className="ma-edit-image">
              <div className="container ma-edit-image-container" style={edit ? { border: 'none', height: '350rem' } : { border: 'none' }}>
                <div className="main-image-container">
                  <img
                    className={picture ? 'ma-img-show' : 'ma-img-hide'}
                    style={edit ? { display: 'block' } : {}}
                    src={pictureShow ?? ''}
                    onError={handleImgError}
                    // onError={!edit ? () => {} : handleImgError}
                    alt="이미지"
                    onContextMenu={
                      (e) => {
                          e.preventDefault();
                          
                      }
                    }
                  />
                  {edit ? <></> :
                    <button
                    className={picture ? 'edit-photo' : 'edit-photo ma-img-hide'}
                    onClick={() => { setPicture(null); setPictureShow(null); }}
                    
                    ></button>
                  }
                  <form method="post" encType="multipart/form-data">
                    <div className="button">
                      <label className={edit ? "" : "image-button"} htmlFor="mainImage"></label>
                    </div>
                    <input
                      type="file"
                      id="mainImage"
                      name="mainImage"
                      accept="image/*"
                      onChange={saveImgFile}
                      style={{ display: 'none' }}
                    />
                  </form>
                </div>
                {!edit &&
                  <span className="ma-img-tlt--sub image-size-check">*2MB 이하</span>
                }
              </div>
            </div>
            <ul className="ap-edit-basic">
              <div className="ap-edit-content-mb">
                <li>
                  <h2>이름 (실명)</h2>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                <span>성</span>
                  <input
                  type="text"
                  className="a-profile-input"
                  value={koFirstName}
                  // placeholder='성'
                  // readOnly={ko_pattern.test(artist.name)}
                  onChange={e => setKoFirstName(e.target.value)}
                  disabled={edit}
                ></input>
                </div>
                <div style={matches ? {marginTop: '10rem', display: 'flex', flexDirection: 'column'} : {display: 'flex', flexDirection: 'column'}}>
                <span>이름</span>
                 <input
                  type="text"
                  className="a-profile-input"
                  value={koLastName}
                  // placeholder='이름'
                  // readOnly={en_pattern.test(artist.eng_NAME)}
                  onChange={e => setKoLastName(e.target.value)}
                  disabled={edit}
                ></input>
                </div>
                </li>
                <li>
                <h2>Name</h2>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <span>Family Name</span>
                <input
                  type="text"
                  className="a-profile-input"
                  value={enFirstName}
                  // placeholder='Family Name'
                    // readOnly={ko_pattern.test(artist.name)}
                  onChange={e => setEnFirstName(e.target.value)}
                  disabled={edit}
                ></input>
                </div>
                <div style={matches ? {marginTop: '10rem', display: 'flex', flexDirection: 'column'} : {display: 'flex', flexDirection: 'column'}}>
                <span>Given Name</span>
                <input
                  type="text"
                  className="a-profile-input"
                  value={enLastName}
                  // placeholder='Given Name'
                  // readOnly={en_pattern.test(artist.eng_NAME)}
                  onChange={e => setEnLastName(e.target.value)}
                  disabled={edit}
                ></input>
                </div>
                </li>
                <li>
                  <h2>활동명 / 단체명 (Artist / Collective Name)</h2>
                  <input
                    type="text"
                    className="a-profile-input"
                    value={koNickname}
                    onChange={e => setKoNickname(e.target.value)}
                    disabled={edit}
                  ></input>
                  <input
                    type="text"
                    className="a-profile-input"
                    value={enNickname}
                    onChange={e => setEnNickname(e.target.value)}
                    disabled={edit}
                  ></input>
                </li>
                <li className="ap-edit-drop">
                  <h2>작가정보 노출 (Please set your name to use for Qart)</h2>
                  <DropDown
                    disabled={edit}
                    options={infoList}
                    select={
                      useName === null
                        ? artist.use_NAME
                        : infoList.indexOf(useName)
                    }
                    setValue={setUseName}
                  />
                </li>
              </div>
            </ul>
            <ul className="ap-edit-personal">
              <div className="ap-edit-content-mb">
              <li className="ap-edit-drop">
                  <h2>국적 (Nationality)</h2>
                  <DropDown
                    disabled={edit}
                    options={countryList}
                    select={
                      countryList.indexOf(country)
                    }
                    setValue={setCountry}
                  />
                  {/* <input
                    type="text"
                    className="a-profile-input"
                    value={country}
                    onChange={e => setCountry(e.target.value)}
                    disabled={edit}
                  ></input> */}
                </li>
                <li>
                  <h2>생년월일 (Birth Date)</h2>
                  <input
                    type='text'
                    // type={edit ? 'text' : 'date'}
                    className="a-profile-input date-input"
                    value={birthday}
                    // onChange={e => setBirthday(e.target.value)}
                    readOnly
                    // disabled={edit}
                  ></input>
                </li>
                <li>
                  <h2>핸드폰 번호 (Contact Number)</h2>
                  <div className="gp-drop">
                  <input
                    type="text"
                    className="a-profile-input__local-number"
                    value={localNumber}
                    onChange={e => setLocalNumber(e.target.value)}
                    placeholder='+82'
                    disabled={edit}
                  ></input>
                   <input
                    type="text"
                    className="a-profile-input__number"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    // maxLength={13}
                    disabled={edit}
                  ></input>
                  </div>
                </li>
                <li>
                  <h2>서명 및 인장 등록</h2>
                  {edit ? (
                    <input
                      type="text"
                      className="a-profile-input"
                      placeholder={artist.sign === null ? '미등록' : '등록 완료'}
                      disabled={true}
                    ></input>
                  ) : (
                    <div className="ap-drop">
                      <input
                        type="text"
                        className="a-profile-input__number"
                        placeholder={
                          sign !== null
                            ? '등록됨'
                            : artist.sign === null
                              ? '미등록'
                              : '등록 완료'
                        }
                        disabled={true}
                      ></input>
                      <button
                        className='drop-button'
                        onClick={showSignModal}
                        style={{ marginLeft: '12rem', marginRight: '0px' }}
                      >
                        {artist.sign === null ? '등록' : '변경'}
                      </button>
                    </div>
                  )}
                </li>
                
                
                <li>
                  <h2>KYC 인증 여부</h2>
                  {edit ? (
                    <input
                      type="text"
                      className="a-profile-input"
                      placeholder={
                        artist.status === 0
                          ? '미인증'
                          : artist.status === 1
                            ? '인증 대기'
                            : '인증 완료'
                      }
                      disabled={true}
                    ></input>
                  ) : (
                    <div className="ap-drop">
                      <input
                        type="text"
                        className="a-profile-input__number"
                        placeholder={
                          artist.status === 0
                            ? '미인증'
                            : artist.status === 1
                              ? '인증 대기'
                              : '인증 완료'
                        }
                        disabled={true}
                      ></input>

                      <button
                        className="drop-button"
                        style={{ marginLeft: '12rem', marginRight: '0px' }}
                        onClick={() => navigate("/cert/kyc")}
                      >
                        {artist.status === 2 ? '재인증': '인증하기'}
                      </button>
                    </div>
                  )}
                </li>
                <li>
                  <div className='bank_blank' />
                </li>
                {artist.status === 2 && 
                <>
                <li>
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '10rem'}}>
                      <h2 style={{position: 'relative'}}>계좌번호</h2>
                      {!edit && <Tooltip
                      text={'변경을 원할시, KYC인증을 다시해주세요.'}>
                        <img src={question} alt='' className='a-profile-bank-question' onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }/>
                      </Tooltip>}
                      </div>
                      <div className="ap-drop">
                      <input
                          type="text"
                          className="a-profile-input__bank"
                          value={accountCompany ? accountCompany : ''}
                          placeholder="-"
                          onChange={(e) => setAccountCompany(e.target.value)}
                          disabled={edit}
                        ></input>
                        <input
                          type="text"
                          value={accountNumber ? accountNumber : ''}
                          className="a-profile-input__bank_num"
                          placeholder="-"
                          onChange={(e) => setAccountNumber(e.target.value)}
                          disabled={edit}
                        ></input>
                      </div>
                    </li>
                <li>
                  <h2>소유주</h2>
                  <input
                    type="text"
                    value={accountOwner ? accountOwner : ''}
                    className="a-profile-input"
                    placeholder="-"
                    onChange={(e) => setAccountOwner(e.target.value)}
                    disabled={edit}
                  ></input>
                </li>
                </>
                }
              </div>
            </ul>
            <ul className="ap-edit-personal gp-edit-basic">
              <div className="ap-edit-content-mb">
              <li className="has-2-input">
                  <h2>주소</h2>
                  <div className="search__block">
                    <input
                      type="text"
                      value={address}
                      className="search__block"
                      disabled={edit}
                      readOnly
                    ></input>
                    <button className="search__block__button" onClick={() => setAddressOpen(true)}>
                      <span className="ir_pm">검색</span>
                    </button>
                  </div>
                  <input
                    type="text"
                    value={addressDetail}
                    onChange={e => setAddressDetail(e.target.value)}
                    className="a-profile-input"
                    disabled={edit}
                  ></input>
                </li>

                {addressOpen && (
                  <ModalPortal>
                    <AddressModal setModalOpen={setAddressOpen} setAddress={setAddress} />
                  </ModalPortal>
                )}


                <li>
                  <h2>이메일</h2>
                  <input
                    type="text"
                    className="a-profile-input"
                    value={uEmail}
                    onChange={e => setUEmail(e.target.value)}
                    disabled={edit}
                  ></input>
                </li>
                <li>
                  <h2>웹 / SNS</h2>
                  <input
                    type="text"
                    className="a-profile-input"
                    value={sns}
                    onChange={e => setSns(e.target.value)}
                    disabled={edit}
                    placeholder='여러개 입력시, 구분자 ; 를 사용해주세요.'
                  ></input>
                </li>
              </div>
            </ul>
            <ul className="ap-edit-intro">
              <li>
                <h2>작가 소개(글자수는 최대 1000자로 제한됩니다.)</h2>
                <div className='content-text'>
                    <textarea
                      value={introduce}
                      maxLength={1000}
                      onChange={e => handleInputMaxLength(setIntroduce, e)}
                      readOnly={edit}></textarea>
                      {!edit && 
                        <span>{introduce === null ? '0/1000' : `${introduce.length}/1000`}</span>
                      }
                    {/* <span>{content.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length}/2000</span> */}
                  </div>
              </li>
            </ul>
          </div>
          
          {edit ? (
            <></>
          ) : (
            <section className="ap-profile-edit--button">
              <button onClick={showCheckModal}>저장하기</button>
              <div className="ap-mobile-edit--button">
                <button onClick={() => setEdit(true)}>취소</button>
                <button onClick={showCheckModal}>저장</button>
              </div>
              {/* <div className="ap-mobile-edit--button">
                <button onClick={showCheckModal}>취소</button>
                <button onClick={showCheckModal}>저장</button>
              </div> */}
              {checkOpen && (
                <ModalPortal>
                  <CheckModal
                    setModalOpen={setCheckOpen}
                    setFunc={editInfo}
                    title={'저장하기'}
                    content={'프로필 저장 하시겠습니까?'}
                    isAuth={true}
                  />
                </ModalPortal>
              )}
              {signOpen && (
                <ModalPortal>
                  <SignModal setModalOpen={setSignOpen} setFunc={setSign} />
                </ModalPortal>
              )}
            </section>

            
          )}

          {!edit &&
          <>
          <span className='withdrawal'  onClick={() => setWithdrawalModal(true)}>탈퇴하기</span>
          <span className='withdrawal-sub'>*회원 탈퇴시 기존의 자료가 삭제됩니다.</span>
          </>
          }

          {withdrawalModal && 
          <CheckModal
          setModalOpen={setWithdrawalModal}
          setFunc={withdrawalUser}
          title={'회원 탈퇴와 동시에 등록된 모든 정보와 자료는 삭제됩니다.'}
          content={'복구가 불가하니 신중히 선택해주세요.\n정말 탈퇴하겠습니까?'}
        />
          }

        </section>

        {edit ? (
          <div>
            {/* <section className="artist-profile-exhibition">
              <div className="container artist-profile-exhibition-container">
                <div className="ap-edit-header">
                  <div className="ap-edit-header-mb">
                    <h1>Exhibition History</h1>
                    <Link to={'./exhibition'}>
                      <button>전시 추가</button>
                    </Link>
                  </div>
                </div>
                <ul className="ap-exhibition-list">
                  <li className="ap-list--header">
                    <span>N</span>
                    <span>Exname</span>
                    <span>Place</span>
                    <span>Country</span>
                    <span>Date</span>
                  </li>
                  <li className="ap-list">
                    <span>1</span>
                    <span>Company name</span>
                    <span>Representive Name</span>
                    <span>Change Date</span>
                    <span>Address</span>
                  </li>
                  <li className="ap-list">
                    <span>2</span>
                    <span>Company name</span>
                    <span>Representive Name</span>
                    <span>Change Date</span>
                    <span>Address</span>
                  </li>
                  <li className="ap-list">
                    <span>3</span>
                    <span>Company name</span>
                    <span>Representive Name</span>
                    <span>Change Date</span>
                    <span>Address</span>
                  </li>
                  <li className="ap-list">
                    <span>4</span>
                    <span>Company name</span>
                    <span>Representive Name</span>
                    <span>Change Date</span>
                    <span>Address</span>
                  </li>
                  <li className="ap-list-mb">
                    <div className="ap-list-mb-content">
                      <span className="ap-list-title">Exname</span>
                      <span>Company name</span>
                    </div>
                    <div className="ap-list-mb-content">
                      <span className="ap-list-title">Place</span>
                      <span>
                        서울특별시 서울시 송파구 송파대로 570 101동 101호
                      </span>
                    </div>
                    <div className="ap-list-mb-content">
                      <span className="ap-list-title">Country</span>
                      <span>Korea</span>
                    </div>
                    <div className="ap-list-mb-content">
                      <span className="ap-list-title">Date</span>
                      <span>0000-00-00</span>
                    </div>
                  </li>
                  <li className="ap-list-mb">
                    <div className="ap-list-mb-content">
                      <span className="ap-list-title">Exname</span>
                      <span>Company name</span>
                    </div>
                    <div className="ap-list-mb-content">
                      <span className="ap-list-title">Place</span>
                      <span>
                        서울특별시 서울시 송파구 송파대로 570 101동 101호
                      </span>
                    </div>
                    <div className="ap-list-mb-content">
                      <span className="ap-list-title">Country</span>
                      <span>Korea</span>
                    </div>
                    <div className="ap-list-mb-content">
                      <span className="ap-list-title">Date</span>
                      <span>0000-00-00</span>
                    </div>
                  </li>
                  <li className="ap-list-mb">
                    <div className="ap-list-mb-content">
                      <span className="ap-list-title">Exname</span>
                      <span>Company name</span>
                    </div>
                    <div className="ap-list-mb-content">
                      <span className="ap-list-title">Place</span>
                      <span>
                        서울특별시 서울시 송파구 송파대로 570 101동 101호
                      </span>
                    </div>
                    <div className="ap-list-mb-content">
                      <span className="ap-list-title">Country</span>
                      <span>Korea</span>
                    </div>
                    <div className="ap-list-mb-content">
                      <span className="ap-list-title">Date</span>
                      <span>0000-00-00</span>
                    </div>
                  </li>
                  <li className="ap-list-mb">
                    <div className="ap-list-mb-content">
                      <span className="ap-list-title">Exname</span>
                      <span>Company name</span>
                    </div>
                    <div className="ap-list-mb-content">
                      <span className="ap-list-title">Place</span>
                      <span>
                        서울특별시 서울시 송파구 송파대로 570 101동 101호
                      </span>
                    </div>
                    <div className="ap-list-mb-content">
                      <span className="ap-list-title">Country</span>
                      <span>Korea</span>
                    </div>
                    <div className="ap-list-mb-content">
                      <span className="ap-list-title">Date</span>
                      <span>0000-00-00</span>
                    </div>
                  </li>
                </ul>
              </div>
            </section> */}
            <section className="artist-profile-school">
              <div className="container artist-profile-school-container">
                <div className="ap-edit-header">
                  <div className="ap-edit-header-mb">
                    <h1>학력정보</h1>
                    <Link to={'./school'}>
                      <button>학력 추가</button>
                    </Link>
                  </div>
                </div>
                <ul className="ap-exhibition-list">
                  <li className="ap-list--header">
                    <span>번호</span>
                    <span>기관명</span>
                    <span>국가</span>
                    <span>입학</span>
                    <span>졸업</span>
                    <span>학위종류</span>
                    <div className="ap-liat--button"></div>
                  </li>
                  {eduTable}
                  {eduTableMobile}
                </ul>
              </div>
            </section>
            <section className="gallery-profile-exhibition">
              <div className="container gallery-profile-exhibition-container">
                <div className="gp-edit-header">
                  <div className="ap-edit-header-mb">
                    <h1>전시이력</h1>
                    <Link to={'./exhibition'}>
                     <button>전시 추가</button>
                    </Link>
                  </div>
                </div>
                <ul className="gp-exhibition-list gp-history-list">
                  <li className="gp-list--header">
                    <span>번호</span>
                    <span>전시명</span>
                    <span>개최장소</span>
                    <span>국가</span>
                    <span>개최기간</span>
                    <span>전시유형</span>
                    <div className="gp-liat--button"></div>
                  </li>
                  {exhibitionTable}
                  {exhibitionTableMobile}
                </ul>
              </div>
            </section>
          </div>
        ) : (
          <></>
        )}
      </div>

      <Footer />
    </div>
  )
}
export default ArtistProfile
