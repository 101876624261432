import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import TableSlide from '../../../../../components/TableSlide/TableSlide'
import COAModal from '../../../../../components/Modal/COAModal'
import ModalPortal from '../../../../../components/Modal/ModalPortal'
import { DebounceInput } from 'react-debounce-input';

import './MyQCoAList.css'
import useMobileDetect from 'use-mobile-detect-hook';


import work from '../../../../../services/myArchive/work/work'
import { handleImgError, workMainImage } from '../../../../../services/imageRoute'
import { getDateStr, setBidTime } from '../../../../../hook/utils/getDateStr'
import { shallowEqual, useSelector } from 'react-redux'
import { artViewDate, tableDate } from '../../../../../hook/utils/artdbView'
import PDFViewerModal from '../../../../../components/Modal/PDFViewerModal'

function MyQcoAList() {
  const navigate = useNavigate()
  const { type, artist, gallery } = useSelector(v => v.auth, shallowEqual)
  const [items, setItems] = useState([]);
  const [tableItem, setTableItem] = useState([]);
  const [select, setSelect] = useState();

  const [total, setTotal] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState('');

  const detectMobile = useMobileDetect();
  const row = detectMobile.isMobile() ? 6 : 9;


  const [ open, setOpen ] = React.useState(false);
  const [ openLink, setOpenLink ] = React.useState(null);


  // coa 모달창 노출 여부 state
  const [checkOpen, setCheckOpen] = useState(false)

  // coa 완료 모달창 노출
  const showCheckModal = () => {
    setCheckOpen(!checkOpen)
  }


  React.useEffect(() => {
    getList(currentPage, row, search)
  }, [currentPage, search])

  const getList = async (currentPage, row, search) => {
    const res = await work.getMyCoAList(currentPage, row, search)
    setTotal(res.total)
    setItems(res.work)
  }



  const createCOA = async (item) => {

    if(item.image === null){
      alert('메인이미지가 없는 작품은 Q-CoA생성이 불가능합니다.')
      return
    }
        // 일반일떄
        if (type === 0) {
          if (artist.status !== 2) {
            alert('e-KYC 인증이 필요합니다.');
            navigate('/mypage/general');
            return
          }
        }
    // 작가일떄
    if (type === 1) {
      if (artist.status !== 2) {
        alert('e-KYC 인증이 필요합니다.');
        navigate('/mypage/artist');
        return
      }
      if (artist.sign === null) {
        alert('서명을 먼저 해주세요.');
        navigate('/mypage/artist');
        return
      }
    }
    // 기관일떄
    if (type === 2) {
      if (gallery.status !== 2) {
        alert('e-KYC 인증이 필요합니다.');
        navigate('/mypage/gallery');
        return
      }
      if (gallery.sign === null) {
        alert('서명을 먼저 해주세요.');
        navigate('/mypage/gallery');
        return
      }
    }

    setSelect(item)
    showCheckModal()
  }

  const viewCoA = (item) => {
    if (item.coa === 'null') {
      return createCOA(item)
    }
    setOpenLink(item.unique_KEY);
    setOpen(true)
  }

  React.useEffect(() => {
    const changeItem = []
    items.map((item, i) => {
      changeItem.push(
        <div className="table-slider-item" onClick={() => viewCoA(item)}>
          <ul>
            <li>{(total) - (row * currentPage + i)}</li>
            <li>
              <img src={`${workMainImage}/${item.image}`} onError={handleImgError} alt='' onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img>
            </li>
            <li>{item.artist}</li>
            <li>{item.title}</li>
            {/*             
            <li>{ item.material }</li>
            <li>{ item.start_CREATE }<br />{ item.end_CREATE }</li>
            <li>{ item.collection}</li>
            <li>
            {
                item.coa === 'null' 
                ? <span className="fz-c-black">미발급</span>
                : <span className="fz-c-blue">{getDateStr(item.coa_CREATE_TIME, '-')}</span>
              }
            </li> */}


            <li>{tableDate(item.start_CREATE, item.end_CREATE)}</li>
            <li>
              {
                item.coa === 'null'
                  ? <span className="fz-c-black">미발급</span>
                  : <span className="fz-c-blue">{getDateStr(item.coa_CREATE_TIME, '-')}</span>
              }
            </li>
            <li>{item.rental_EXPIRED === null ? '' : item.collection}</li>
            <li>{setBidTime(item.rental_EXPIRED)}</li>

          </ul>
          <div className="work-item-mb">
            <div className="work-item-mb-container">
              <div className="work-item-mb-content">
                <div className="work-item-mb__info">
                  <img src={`${workMainImage}/${item.image}`} onError={handleImgError} alt='' onContextMenu={
                (e) => {
                    e.preventDefault();
                    
                }
              }></img>
                  <div className="work-item-mb__detail">
                    <h1>{item.title}</h1>
                    <h3>{item.artist}</h3>
                    {/* <h4>[ {item.collection} ]</h4> */}
                    <h3>{artViewDate(item.start_CREATE, item.end_CREATE)}</h3>
                    <h3>대여처: {item.rental_EXPIRED === null ? '' : item.collection}</h3>
                    <h3>반납일: {setBidTime(item.rental_EXPIRED)}</h3>
                    {
                      item.coa === 'null'
                        ? <h3 style={{ cursor: 'pointer' }} onClick={() => createCOA(item, i)}>Q-CoA 발급받기</h3>
                        : <h3>Q-CoA 등록일 : {getDateStr(item.coa_CREATE_TIME, '-')}</h3>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })

    setTableItem([...changeItem])
  }, [items])

  const titleItem = []
  titleItem.push(
    <div className="table-header-search">
      <h2>
        My Q-CoA List 
        {/* <span className="download-button"></span> */}
      </h2>
      <div className="th-search-container">
        <section className="search__block">
          <DebounceInput
            autoComplete='off'
            minLength={1}
            debounceTimeout={500}
            type={'text'}
            placeholder={'작품을 검색해주세요'}
            disabled={checkOpen}
            value={search}
            onChange={e => { setSearch(e.target.value); setCurrentPage(0) }}
            className="search__block__input"
          />
          <button className="search__block__button">
            <span className="ir_pm">검색</span>
          </button>
        </section>

        {/* <section className="th-mb-search-button">
          <Link>
            <button>Add</button>
          </Link>
        </section> */}
      </div>
    </div>
  )
  const tableSlideHeader = []
  tableSlideHeader.push(
    <ul className="container table-slider-header-container">
      {/* <li>
        <label className="login-option">
          <input
            type="checkbox"
            onChange={e => checkAllHandler(e.target.checked)}
            checked={
              checkItems.length === 0
                ? false
                : checkItems.length === tableItem.length
                ? true
                : false
            }
          />
          <span className="login-option__check" />
        </label>
      </li> */}
      {/* <li>번호</li>
      <li>대표 이미지</li>
      <li>작가명</li>
      <li>작품명</li>
      <li>재질</li>
      <li>제작일</li>
      <li>소장처</li>
      <li>Q-CoA 등록일</li> */}

      <li>번호</li>
      <li>대표 이미지</li>
      <li>작가명</li>
      <li>작품명</li>
      <li>제작일</li>
      <li>Q-CoA 등록일</li>
      <li>대여처</li>
      <li>반납일</li>
    </ul>
  )
  return (
    <>
    {open && openLink && (
        <ModalPortal>
          <PDFViewerModal
            path={openLink}
            setPath={setOpenLink}
            setModalOpen={setOpen}
          />
        </ModalPortal>
      )}
      {checkOpen && (
        <ModalPortal>
          <COAModal
            setModalOpen={setCheckOpen}
            item={select}
            setItem={setItems}
            items={items}
          />
        </ModalPortal>
      )}
      <div className="my-qcoa-list">
        <TableSlide
          title={titleItem}
          slideHeader={tableSlideHeader}
          items={tableItem}
          rows={row}
          breakRows={7}
          total={total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  )
}
export default MyQcoAList
