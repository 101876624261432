import React, { useState } from 'react'
import WebScanner from '../../../../components/WebScanner/WebScanner'
import DropDown from '../../../../components/DropDown/DropDown'

import { addComma, onChangePoints } from '../../../../hook/utils/comma'
import './ArtistTrade.css'

import scan from '../../../../services/myArchive/work/scan'

function ArtistTrade() {
  const [menu, setMenu] = useState(0)

  const TradeHeader = () => {
    return (
      <header className="table-header">
        <div className="container table-header-container">

          <div className="table-header-title">
            <span
              style={{ cursor: 'pointer', textAlign: 'center' }}
              className={
                menu === 0
                  ? 'th-title-select-active th-title-select '
                  : 'th-title-select'
              }
              onClick={() => setMenu(0)}
            >
              <h1>판매 하기</h1>
              <span></span>
            </span>
            <span
              style={{ cursor: 'pointer', textAlign: 'center' }}

              className={
                menu === 1
                  ? 'th-title-select-active th-title-select '
                  : 'th-title-select'
              }
              onClick={() => setMenu(1)}
            >
              <h1>대여/반납 하기</h1>
              <span></span>
            </span>
          </div>
        </div>
      </header>
    )
  }

  const MyButton = (props) => {

    const { temp, setValue, value } = props

    return (
      <button
        onClick={() => setValue(value)}
        style={{
          border: '1px #000 solid',
          background: props.background || '#000',
          color: props.color || '#fff',
          boxSizing: 'border-box',
          fontWeight: 700,
          fontSize: '24rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '300rem',
          height: '80rem'
        }}>
        {temp}
      </button>
    )
  }

  const Sale = () => {
    const [token1, setToken1] = useState('');
    const [token2, setToken2] = useState('');
    // const [step, setStep] = useState('option');
    const [step, setStep] = useState('load');
    const [workQR, setWorkQR] = useState('');
    const [workPin, setWorkPin] = useState('');
    const [userQR, setUserQR] = useState('');
    const [price, setPrice] = useState('')
    const [password, setPassword] = useState('');
    const [alphabet, setAlphabet] = useState('');
    const [wait, setWait] = useState('.');

    const nextFocus = React.useRef(null);



    React.useEffect(() => {
      const timer = setInterval(() => {
        if(wait.length > 5){
          setWait('.');
        }else{
          setWait(wait + '.');
        }
      }, 1000);
  
      return () => clearInterval(timer);
    }, [wait]);



    const first = async () => {
      setWorkPin(`${password}${alphabet.toUpperCase()}`)
      setPassword('')
      setAlphabet('')
      setStep('wait')
      setWait('.');
      const res = await scan.sale1(workQR, `${password}${alphabet.toUpperCase()}`)

      if (!res.resultCode) {
        alert('일치하지 않는 패스워드입니다.')
        setStep('load')
        return
      }
      setToken1(res.value)
      setStep('option')
    }

    const second = async () => {
      setWait('.')
      const res = await scan.sale2(token1, userQR, price)

      if (!res.resultCode) {
        alert('일치하지 않는 패스워드입니다.')
        setStep('load')
        return
      }
      setToken2(res.value)
      setStep('sale')
    }

    const third = async () => {
      setWait('.')
      const res = await scan.sale3(token2, userQR, workPin)

      if (!res.resultCode) {
        alert('오류로 인해 판매하지 못했습니다.')
        setStep('load')
        return
      }
      setStep('fin')
    }


    React.useEffect(() => {
      if (step === 'load') {
        setToken1('')
        setToken2('')
        setWorkQR('')
        setWorkPin('')
        setUserQR('')
        setPrice('')
        setPassword('')
        setAlphabet('')
      }
      if (step === 'connect') {
        second()
      }
      if (step === 'sale') {
        third()
      }
    }, [step])




    return (
      <div className="artist-profile" style={{ height: '55vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <section
          className={'container artist-profile-edit'}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          {
            step === 'load' ?
              <MyButton temp="시작하기" setValue={setStep} value="start" />
              : step === 'start' ?
                <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                  <span style={{ fontSize: "45rem", fontWeight: 700, textAlign: 'center' }}>작품의 QR을 스캔해주세요.</span>
                  <WebScanner setValue={setWorkQR} setStep={setStep} step={'password'} done={'not'} doneFunc={() => { }} />
                </div>
                : step === 'password' ?
                  <>
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '100px' }}>
                        <span style={{ fontSize: "45rem", fontWeight: 700, textAlign: 'center' }}>작품의 간편 패스워드를 입력해주세요.</span>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                          <input
                            className='qr-input num'
                            type="password"
                            placeholder="****"
                            maxLength={4}
                            minLength={4}
                            pattern="[0-9]+"
                            value={password}
                            onKeyPress={(e) => {
                              // 정규표현식 /[0-9]/ 이 아니면 press X
                              if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              if (e.target.value.length === 4) {
                                nextFocus.current.focus();
                              }
                            }}
                            required
                          />
                          <input
                            className='qr-input alp'
                            ref={nextFocus}
                            type="password"
                            value={alphabet}
                            placeholder="*"
                            maxLength={1}
                            minLength={1}
                            pattern="[A-Za-z]+"
                            onKeyPress={(e) => {
                              // 정규표현식 ^[A-Za-z]+$/i 이 아니면 press X
                              if (!/^[A-Za-z]+$/i.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              setAlphabet(e.target.value.toUpperCase());
                            }}
                            required
                          />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '30px' }}>
                          <MyButton background="#fff" color="#000" temp="취소" setValue={setStep} value="load" />
                          <MyButton temp="확인" setValue={first} value="" />
                        </div>
                      </div>
                    </div>
                  </> : step === 'wait' ?
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                      <span style={{ fontSize: "45rem", fontWeight: 700, textAlign: 'center' }}>잠시만 기다려주세요.</span>
                      <span style={{ fontSize: "30rem", fontWeight: 400, textAlign: 'center' }}>블록체인 검증중 {wait}</span>
                    </div> : step === 'option' ?
                      <>
                        <div>
                          <div style={{ display: 'flex', flexDirection: 'column', gap: '100px' }}>
                            <span style={{ fontSize: "45rem", fontWeight: 700, textAlign: 'center' }}>작품 판매가격을 입력해주세요.</span>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                              <div className="ma-info-02-input" style={{ border: '1px #000 solid' }}>
                                <input
                                  style={{ border: 'none', textAlign: 'end' }}
                                  type="text"
                                  placeholder="1,000,000"
                                  value={addComma(price) || ""}
                                  onChange={e => setPrice(onChangePoints(e))}
                                  onKeyPress={(e) => {
                                    // 정규표현식 /[0-9]/ 이 아니면 press X
                                    if (!/[0-9]/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                <span style={{ border: 'none' }}>원</span>
                              </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '30px' }}>
                              <MyButton background="#fff" color="#000" temp="취소" setValue={setStep} value="load" />
                              <MyButton temp="확인" setValue={setStep} value="user" />
                            </div>
                          </div>
                        </div>
                      </> : step === 'user' ?
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                          <span style={{ fontSize: "45rem", fontWeight: 700, textAlign: 'center' }}>유저의 QR을 스캔해주세요.</span>
                          <WebScanner setValue={setUserQR} setStep={setStep} step={'connect'} done={'not'} doneFunc={() => { }} />
                        </div>
                        : step === 'connect' ?
                          <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                            <span style={{ fontSize: "45rem", fontWeight: 700, textAlign: 'center' }}>잠시만 기다려주세요.</span>
                            <span style={{ fontSize: "30rem", fontWeight: 400, textAlign: 'center' }}>블럭 생성중 {wait}</span>
                          </div> : step === 'sale' ? <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                            <span style={{ fontSize: "45rem", fontWeight: 700, textAlign: 'center' }}>잠시만 기다려주세요.</span>
                            <span style={{ fontSize: "30rem", fontWeight: 400, textAlign: 'center' }}>체인 연결중 {wait}</span>
                          </div> : <div style={{ display: 'flex', flexDirection: 'column', gap: '30px', alignItems: 'center' }}>
                            <span style={{ fontSize: "45rem", fontWeight: 700, textAlign: 'center' }}>판매가 완료되었습니다.</span>
                            <MyButton temp="확인" setValue={setStep} value="load" />
                          </div>
          }
        </section>
      </div>
    )
  }


  const Lend = () => {
    const [token1, setToken1] = useState('');
    const [token2, setToken2] = useState('');
    const [before, setBefore] = useState('0'); //0 대여, 1 반납
    // const [step, setStep] = useState('option');
    const [step, setStep] = useState('load');
    const [workQR, setWorkQR] = useState('');
    const [workPin, setWorkPin] = useState('');
    const [userQR, setUserQR] = useState('');
    const [allow, setAllow] = useState('판매 시 정보를 받지않겠습니다.')
    const [date, setDate] = useState('')
    const [password, setPassword] = useState('');
    const [alphabet, setAlphabet] = useState('');
    const [wait, setWait] = useState('.');
    const nextFocus = React.useRef(null);


    const infoList = ['판매 시 정보를 받지않겠습니다.', '판매 시 정보를 받겠습니다.']

    React.useEffect(() => {
      const timer = setInterval(() => {
        if(wait.length > 5){
          setWait('.');
        }else{
          setWait(wait + '.');
        }
      }, 1000);
  
      return () => clearInterval(timer);
    }, [wait]);


    const first = async () => {
      setWorkPin(`${password}${alphabet}`)
      setPassword('')
      setAlphabet('')
      setStep('wait')
      const res = await scan.lend1(workQR, `${password}${alphabet}`)

      if (!res.resultCode) {
        alert('일치하지 않는 패스워드입니다.')
        setStep('load')
        return
      }
      setBefore(res.info.RENTAL)

      setToken1(res.value)

      if(res.info.RENTAL === '0'){
        setStep('option')
      }else{
        setStep('user')
      }
    }

    const second = async () => {
      const sendAllow = before !== '0' ? '' : allow === '' ? 0 : infoList.indexOf(allow)

      const res = await scan.lend2(token1, userQR, sendAllow, date)

      if (!res.resultCode) {
        alert(`오류로 인해 ${before === '0' ? '대여' : '반납'}하지 못했습니다.`)
        setStep('load')
        return
      }
      setToken2(res.value)
      setStep('sale')
    }

    const third = async () => {
      const res = await scan.lend3(token2, userQR, workPin)

      if (!res.resultCode) {
        alert(`오류로 인해 ${before === '0' ? '대여' : '반납'}하지 못했습니다.`)
        setStep('load')
        return
      }
      setStep('fin')
    }


    React.useEffect(() => {
      if (step === 'load') {
        setToken1('')
        setToken2('')
        setWorkQR('')
        setWorkPin('')
        setUserQR('')
        setAllow('판매 시 정보를 받지않겠습니다.')
        setDate('')
        setPassword('')
        setAlphabet('')
      }
      if (step === 'connect') {
        second()
      }
      if (step === 'sale') {
        third()
      }
    }, [step])



    return (
      <div className="artist-profile" style={{ height: '55vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <section
          className={'container artist-profile-edit'}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          {
            step === 'load' ?
              <MyButton temp="시작하기" setValue={setStep} value="start" />
              : step === 'start' ?
                <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                  <span style={{ fontSize: "45rem", fontWeight: 700, textAlign: 'center' }}>작품의 QR을 스캔해주세요.</span>
                  <WebScanner setValue={setWorkQR} setStep={setStep} step={'password'} done={'not'} doneFunc={() => { }} />
                </div>
                : step === 'password' ?
                  <>
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '100px' }}>
                        <span style={{ fontSize: "45rem", fontWeight: 700, textAlign: 'center' }}>작품의 간편 패스워드를 입력해주세요.</span>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                          <input
                            className='qr-input num'
                            type="password"
                            placeholder="****"
                            maxLength={4}
                            minLength={4}
                            pattern="[0-9]+"
                            value={password}
                            onKeyPress={(e) => {
                              // 정규표현식 /[0-9]/ 이 아니면 press X
                              if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              if (e.target.value.length === 4) {
                                nextFocus.current.focus();
                              }
                            }}
                            required
                          />
                          <input
                            className='qr-input alp'
                            ref={nextFocus}
                            type="password"
                            value={alphabet}
                            placeholder="*"
                            maxLength={1}
                            minLength={1}
                            pattern="[A-Za-z]+"
                            onKeyPress={(e) => {
                              // 정규표현식 ^[A-Za-z]+$/i 이 아니면 press X
                              if (!/^[A-Za-z]+$/i.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              setAlphabet(e.target.value.toUpperCase());
                            }}
                            required
                          />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '30px' }}>
                          <MyButton background="#fff" color="#000" temp="취소" setValue={setStep} value="load" />
                          <MyButton temp="확인" setValue={first} value="" />
                        </div>
                      </div>
                    </div>
                  </> : step === 'wait' ?
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                      <span style={{ fontSize: "45rem", fontWeight: 700, textAlign: 'center' }}>잠시만 기다려주세요.</span>
                      <span style={{ fontSize: "30rem", fontWeight: 400, textAlign: 'center' }}>블록체인 검증중 {wait}</span>
                    </div> : step === 'option' ?
                      <>
                        <div>
                          <div style={{ display: 'flex', flexDirection: 'column', gap: '100px' }}>
                            <span style={{ fontSize: "45rem", fontWeight: 700, textAlign: 'center' }}>대여 정보를 입력해주세요.</span>



                            <div className="ap-edit-image">
                            <div className="ap-edit-drop" style={{width: '100%', border: '1px #000 solid'}}>
                            <DropDown style={{width: '100%'}} options={infoList} setValue={setAllow} />
                            </div>
                            </div>




                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                              <div className="ma-info-02-input" style={{ border: '1px #000 solid' }}>
                                <input
                                  style={{ border: 'none', textAlign: 'end' }}
                                  type="text"
                                  value={addComma(date) || ""}
                                  onChange={e => setDate(onChangePoints(e))}
                                  onKeyPress={(e) => {
                                    // 정규표현식 /[0-9]/ 이 아니면 press X
                                    if (!/[0-9]/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                <span style={{ border: 'none' }}>일</span>
                              </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '30px' }}>
                              <MyButton background="#fff" color="#000" temp="취소" setValue={setStep} value="load" />
                              <MyButton temp="확인" setValue={setStep} value="user" />
                            </div>
                          </div>
                        </div>
                      </> : step === 'user' ?
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                          <span style={{ fontSize: "45rem", fontWeight: 700, textAlign: 'center' }}>유저의 QR을 스캔해주세요.</span>
                          <WebScanner setValue={setUserQR} setStep={setStep} step={'connect'} done={'not'} doneFunc={() => { }} />
                        </div>
                        : step === 'connect' ?
                          <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                            <span style={{ fontSize: "45rem", fontWeight: 700, textAlign: 'center' }}>잠시만 기다려주세요.</span>
                            <span style={{ fontSize: "30rem", fontWeight: 400, textAlign: 'center' }}>블럭 생성중 {wait}</span>
                          </div> : step === 'sale' ? <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                            <span style={{ fontSize: "45rem", fontWeight: 700, textAlign: 'center' }}>잠시만 기다려주세요.</span>
                            <span style={{ fontSize: "30rem", fontWeight: 400, textAlign: 'center' }}>체인 연결중 {wait}</span>
                          </div> : <div style={{ display: 'flex', flexDirection: 'column', gap: '30px', alignItems: 'center' }}>
                            <span style={{ fontSize: "45rem", fontWeight: 700, textAlign: 'center' }}>{before === '0' ? '대여를' : '반납을'} 완료되었습니다.</span>
                            <MyButton temp="확인" setValue={setStep} value="load" />
                          </div>
          }
        </section>
      </div>
    )
  }


  return (
    <div className="mypage-general-list">
      <div className="table-slide">
        <TradeHeader />
        <div className="artist-profile" style={{ height: '55vh' }}>
          <section
            className={'container artist-profile-edit'}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            {
              menu === 0 ?
                <Sale /> :
                <Lend />
            }
          </section>
        </div>

      </div>
    </div>
  )
}
export default ArtistTrade
