import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import DropDown from '../../../../components/DropDown/DropDown'
import Footer from '../../../../components/Footer/Footer'
import Header from '../../../../components/Header/Header'
import HeaderSmall from '../../../../components/Header/HeaderSmall/HeaderSmall'
import CheckModal from '../../../../components/Modal/CheckModal'
import SignModal from '../../../../components/Modal/SignModal'
import QrModal from '../../../../components/Modal/QrModal'
import ModalPortal from '../../../../components/Modal/ModalPortal'
import SectionHeader from '../../../../components/SectionHeader/SectionHeader'
import './GalleryProfile.css'
import question from '../../../../assets/question-line.png'
import auth from '../../../../services/auth/auth'
import { useDispatch } from "react-redux";
import * as authAction from '../../../../store/reducers/auth/actions'
import { useSelector, shallowEqual } from 'react-redux'

import profile from '../../../../services/auth/profile'
import { handleImgError, userImage } from '../../../../services/imageRoute'

import AddressModal from '../../../../components/Modal/AddressModal'
import { checkUseName } from '../../../../hook/utils/checkUseName'
import { checkMainImageSize, checkUserImageSize } from '../../../../hook/utils/fileSize'
import { LoadingState } from '../../../../context/loadingStateProvider'
import Tooltip from '../../../../components/Tooltip'
import { handleInputMaxLength } from '../../../../hook/utils/validation'



const ko_pattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; //한글
const en_pattern = /[a-zA-Z]/; //영어


function GalleryProfile() {
  const { type } = useSelector(v => v.auth, shallowEqual)
  const { lang } = useSelector(v => v.setting, shallowEqual)

  const [withdrawalModal, setWithdrawalModal] = React.useState(false)

  const loadingState = React.useContext(LoadingState);
  const [kycInfo, setKycInfo] = React.useState(null)

  const [accountCompany, setAccountCompany] = React.useState()
  const [accountNumber, setAccountNumber] = React.useState()
  const [accountOwner, setAccountOwner] = React.useState()

  const dispatch = useDispatch();
  React.useEffect(() => {
    getInfo(type)
  }, [type])



  const withdrawalUser = async () => {
    loadingState.setText('잠시만 기다려주세요.')
    loadingState.setOpen(true)

    const res = await auth.withdrawalUser()

    if(!res.resultCode){
      if(res.message === 'rental or transaction'){
        alert('진행 중인 거래가 있습니다.\n완료 후 탈퇴를 진행해 주세요.')
        loadingState.setOpen(false)
        loadingState.setText('')
        return;
      }
      alert('관리자에게 문의해주세요.')
      loadingState.setOpen(false)
      loadingState.setText('')
      return
    }

    localStorage.removeItem('QART_ACCESS')
    dispatch(authAction.LOGOUT())
    alert('회원탈퇴를 완료했습니다.')

    loadingState.setOpen(false)
    loadingState.setText('')
    navigate('/', { replace: true })
  }


  const getInfo = async (myType) => {
    if (myType === 1) {
      navigate('/myPage/artist');
    } else {
      const res = await auth.getAgencyInfo()
      setKycInfo(res.kyc)
      setAccountCompany(res.kyc?.account_COMPANY)
      setAccountNumber(res.kyc?.account_NUMBER)
      setAccountOwner(res.kyc?.name)
      dispatch(authAction.SET_GALLERY_INFO(res))
    }
  }

  const editInfo = async () => {
    const myType = infoList2.indexOf(userType) === 0 ? 2 : 1

    const res = await auth.editAgencyInfo(gallery.image,
      picture === null ? '' : `${userImage}/${gallery.image}` === picture ? gallery.image : picture,
      agencyKoName, agencyEnName, agencyAddress, agencyAddressDetail, managerMail, koManager, enManager, localNumber, contact, contact1, contact2, agencySince, homePage, sign, myType, introduce, accountCompany, accountNumber, accountOwner )
    if (!res.resultCode) {
      alert('저장에 실패했습니다.')
      return
    }

    await getInfo(myType)
    if (myType !== 2) {
      dispatch(authAction.SET_TYPE(myType))
      navigate('/myPage/artist')
    } else {
      setEdit(true)
      setSign(null)
    }
  }

  const navigate = useNavigate();
  const { gallery, galleryTeam } = useSelector(v => v.auth, shallowEqual);
  const [picture, setPicture] = React.useState(gallery.image === null ? null : `${userImage}/${gallery.image}`)
  const [pictureShow, setPictureShow] = React.useState(gallery.image === null ? null : `${userImage}/${gallery.image}`)
  const [agencyKoName, setAgencyKoName] = React.useState(gallery.name)
  const [agencyEnName, setAgencyEnName] = React.useState(gallery.eng_name)
  const [agencySince, setAgencySince] = React.useState(gallery.since)
  const [agencyAddress, setAgencyAddress] = React.useState(gallery.address)
  const [agencyAddressDetail, setAgencyAddressDetail] = React.useState(gallery.address1)

  const [localNumber, setLocalNumber] = React.useState(gallery.country_number);
  const [contact, setContact] = React.useState(gallery.phone_num);
  const [contact1, setContact1] = React.useState(gallery.contact1);
  const [contact2, setContact2] = React.useState(gallery.contact2);
  const [sign, setSign] = React.useState(gallery.sign)
  const [userType, setUserType] = React.useState('기관')

  const [koManager, setKoManager] = React.useState(gallery.manager_name)
  const [enManager, setEnManager] = React.useState(gallery.manager_eng_name)

  const [managerMail, setManagerMail] = React.useState(gallery.manager_mail);

  const [homePage, setHomePage] = React.useState(gallery.home_page);
  const [introduce, setIntroduce] = React.useState(gallery.introduce ?? '')

  //agency
  const [agency, setAgency] = React.useState(null)
  const [agencyTable, setAgencyTable] = React.useState(null)
  const [agencyTableMobile, setAgencyTableMobile] = React.useState(null)

  //team
  const [team, setTeam] = React.useState([])
  const [teamTable, setTeamTable] = React.useState([])
  const [teamTableMobile, setTeamTableMobile] = React.useState([])

  React.useEffect(() => {
    getTeam()
  }, [])

  React.useEffect(() => {
    setPicture(gallery.image === null ? null : `${userImage}/${gallery.image}`)
    setPictureShow(gallery.image === null ? null : `${userImage}/${gallery.image}`)
    setAgencyKoName(gallery.name)
    setAgencyEnName(gallery.eng_name)
    setAgencySince(gallery.since)
    setAgencyAddress(gallery.address)
    setAgencyAddressDetail(gallery.address1)
    setLocalNumber(gallery.country_number);
    setContact(gallery.phone_num);
    setContact1(gallery.contact1);
    setContact2(gallery.contact2);
    setSign(gallery.sign)
    setUserType('기관')
    setKoManager(gallery.manager_name)
    setEnManager(gallery.manager_eng_name)
    setManagerMail(gallery.manager_mail);
    setHomePage(gallery.home_page);
    setIntroduce(gallery.introduce === 'null' ? '' : gallery.introduce)
  }, [gallery])

  const getTeam = async () => {
    const res = await profile.getTeam()
    setTeam(res.team_list)
  }

  const deleteTeam = async (item) => {

    // 탈퇴
    if (item.user_ID === galleryTeam.user_id) {
      const res = await profile.withdrawalTeam()

      if (!res.resultCode) {
        alert('서버오류')
        return
      }
      alert('탈퇴하였습니다.')
      window.location.reload()
      return
    }


    // 추방
    if (item.team_ID !== galleryTeam.user_id) {
      alert('권한이 없습니다.')
      return
    }
    const res = await profile.deleteTeam(item.user_ID)

    if (!res.resultCode) {
      alert('서버오류')
      return
    }
    alert('팀원을 추방했습니다.')
    setTeam(team.filter(v => v.user_ID !== item.user_ID))
  }

  React.useEffect(() => {
    if (team.length === 0) {
      return
    }
    const table = []
    const tableMobile = []

    for (let i = 0; i < team.length; i++) {
      table.push(
        <li className="ap-list">
          <span>{checkUseName(lang, team[i].use_NAME, team[i].name, team[i].eng_NAME, team[i].nickname, team[i].eng_NICKNAME)}</span>
          <span>{team[i].email}</span>
          <span>{team[i].phone_NUMBER}</span>
          <span>{team[i].job}</span>
          <div className="gp-liat--button-half">
            {team[i].team_ID !== team[i].user_ID &&
              <button onClick={() => deleteTeam(team[i])}> {team[i].user_ID === galleryTeam.user_id ? '탈퇴' : '추방'}</button>
            }
          </div>
        </li>
      )
      tableMobile.push(
        <li className="ap-list-mb">
          <div className="ap-list-mb-content">
            <span className="ap-list-title">이름</span>
            <span>{checkUseName(lang, team[i].use_NAME, team[i].name, team[i].eng_NAME, team[i].nickname, team[i].eng_NICKNAME)}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">이메일</span>
            <span>{team[i].email}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">전화번호</span>
            <span>{team[i].phone_NUMBER}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">직무</span>
            <span>{team[i].job}</span>
          </div>
          <div className="ap-list-mb-button">
            {team[i].team_ID !== team[i].user_ID &&
              <button onClick={() => deleteTeam(team[i])}> {team[i].user_ID === galleryTeam.user_id ? '탈퇴' : '추방'}</button>
            }
          </div>
        </li>
      )
    }

    setTeamTable(table)
    setTeamTableMobile(tableMobile)

  }, [team])



  const agencyDelete = async (pk) => {
    const res = await profile.deleteAgency(pk)
    if (!res.resultCode) {
      alert('삭제 실패했습니다.');
      return
    }
    alert('삭제되었습니다.');
    setAgency(agency.filter(item => item.pk !== pk))
  }

  React.useEffect(() => {
    if (agency === null) {
      return
    }
    const table = []
    const tableMobile = []
    agency.map((item, index) => {
      table.push(
        <li className="ap-list">
          <span>{index + 1}</span>
          <span>{item.alias}</span>
          <span>{item.manager}</span>
          <span>{item.updatedAt} </span>
          <span>{item.address}</span>
          <div className="gp-liat--button">
            <button onClick={() => { navigate(`/mypage/gallery/agency/edit/${item.pk}`, { state: { data: item } }) }}>수정</button>
            <button onClick={() => { agencyDelete(item.pk) }}>삭제</button>
          </div>
        </li>
      )
      tableMobile.push(
        <li className="ap-list-mb">
          <div className="ap-list-mb-content">
            <span className="ap-list-title">기관명</span>
            <span>{item.alias}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">대표자명</span>
            <span>{item.manager}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">변경일</span>
            <span>{item.updatedAt}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">주소</span>
            <span>{item.address}</span>
          </div>
          <div className="ap-list-mb-button">
            <button onClick={() => { navigate(`/mypage/gallery/agency/edit/${item.pk}`, { state: { data: item } }) }}>수정</button>
            <button onClick={() => { agencyDelete(item.pk) }}>삭제</button>
          </div>
        </li>
      )
    })
    setAgencyTable(table)
    setAgencyTableMobile(tableMobile)
  }, [agency])

  React.useEffect(() => {
    List()
    historyList()
  }, [])

  const List = async () => {
    const res = await profile.exhibition();
    setExhibition(res.exhibitions)
  }



  const historyList = async () => {
    const res = await profile.v2_agency();
    setHistory(res.data)
  }


  const [exhibition, setExhibition] = React.useState(null)
  const [exhibitionTable, setexhibitionTable] = React.useState(null)
  const [exhibitionTableMobile, setexhibitionTableMobile] = React.useState(null)
  
  const [history, setHistory] = React.useState(null)
  const [historyTable, setHistoryTable] = React.useState(null)
  const [historyTableMobile, setHistoryTableMobile] = React.useState(null)

  const exhibitionDelete = async (pk) => {
    const res = await profile.deleteExhibition(pk)
    if (!res.resultCode) {
      alert('삭제 실패했습니다.');
      return
    }
    alert('삭제되었습니다.');
    setExhibition(exhibition.filter(item => item.pk !== pk))
  }

  const historyDelete = async (pk) => {
    const res = await profile.v2_deleteAgency(pk)
    if (!res.resultCode) {
      alert('삭제 실패했습니다.');
      return
    }
    alert('삭제되었습니다.');
    setHistory(history.filter(item => item.pk !== pk))
  }


  React.useEffect(() => {
    if (exhibition === null) {
      return
    }
    const table = []
    const tableMobile = []
    exhibition.map((item, index) => {
      table.push(
        <li className="ap-list">
          <span>{index + 1}</span>
          <span>{item.title}</span>
          <span>{item.place}</span>
          <span>{item.country} </span>
          <span>{item.date}<br />{item.end}</span>
          <span>{item.type}</span>
          <div className="gp-liat--button">
            <button onClick={() => { navigate('/mypage/artist/exhibition/edit', { state: { data: item } }) }}>수정</button>
            <button onClick={() => { exhibitionDelete(item.pk) }}>삭제</button>
          </div>
        </li>
      )
      tableMobile.push(
        <li className="ap-list-mb">
          <div className="ap-list-mb-content">
            <span className="ap-list-title">일자</span>
            <span>{item.title}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">내역</span>
            <span>
              {item.place}
            </span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">상세정보</span>
            <span>{item.country}</span>
          </div>
          <div className="ap-list-mb-button">
            <button onClick={() => { navigate('/mypage/artist/exhibition/edit', { state: { data: item } }) }}>수정</button>
            <button onClick={() => { exhibitionDelete(item.pk) }}>삭제</button>
          </div>
        </li>
      )
    })
    setexhibitionTable(table)
    setexhibitionTableMobile(tableMobile)
  }, [exhibition])



  React.useEffect(() => {
    if (history === null) {
      return
    }
    const table = []
    const tableMobile = []
    history.map((item, index) => {
      table.push(
        <li className="ap-list">
          {/* <span>{item.pk}</span> */}
          <span>{index + 1}</span>
          <span>{item.date}</span>
          <span>{item.content}</span>
          <span>{item.detail} </span>
          <div className="gp-liat--button">
          <button onClick={() => { navigate(`./agency/edit/${item.pk}`, { state: { data: item } }) }}>수정</button>
            <button onClick={() => { historyDelete(item.pk) }}>삭제</button>
          </div>
        </li>
      )
      tableMobile.push(
        <li className="ap-list-mb">
          <div className="ap-list-mb-content">
            <span className="ap-list-title">일자</span>
            <span>{item.date}</span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">내역</span>
            <span>
              {item.content}
            </span>
          </div>
          <div className="ap-list-mb-content">
            <span className="ap-list-title">상세정보</span>
            <span>{item.detail}</span>
          </div>
          <div className="ap-list-mb-button">
            <button onClick={() => { navigate(`./agency/edit/${item.pk}`, { state: { data: item } }) }}>수정</button>
            <button onClick={() => { historyDelete(item.pk) }}>삭제</button>
          </div>
        </li>
      )
    })
    setHistoryTable(table)
    setHistoryTableMobile(tableMobile)
  }, [history])

  //

  const moreList = {
    menu: [
      {
        title: '프로필',
        link: '/mypage/gallery',
      },
      {
        title: 'My Archive',
        link: '/mypage/gallery/archive',
      },
      {
        title: '구매목록',
        link: '/mypage/gallery/wallet',
      },
      {
        title: '관심목록',
        link: '/mypage/gallery/like',
      },
      // {
      //   title: '거래하기',
      //   link: '/mypage/gallery/trade',
      // },
      {
        title: '알림',
        link: '/mypage/gallery/notice',
      },
      {
        title: '멤버십',
        link: '/mypage/gallery/membership',
      }
    ],
  }




  const [edit, setEdit] = useState(true)

  // 수정 완료 모달창 노출 여부 state
  const [checkOpen, setCheckOpen] = useState(false)

  // 수정 완료 모달창 노출
  const showCheckModal = () => {
    setCheckOpen(!checkOpen)
  }

  // 서명 모달창 노출 여부 state
  const [addressOpen, setAddressOpen] = useState(false)

  // 서명 모달창 노출 여부 state
  const [signOpen, setSignOpen] = useState(false)

  // 서명 모달창 노출
  const showSignModal = () => {
    setSignOpen(!signOpen)
  }

  // QR 모달창 노출 여부 state
  const [qrOpen, setQrOpen] = useState(false)

  // QR 모달창 노출
  const showQrModal = () => {
    setQrOpen(!qrOpen)
  }
  const phonelist = ['+82', '+82', '+82', '+82', '+82', '+82']
  const banklist = ['은행', '은행', '은행', '은행']
  const infoList2 = ['기관', '작가']
  const options = ['직접입력', 'naver.com', 'gmail.com', 'daum.net']

  const saveImgFile = e => {
    e.preventDefault();
    const fileList = e.target.files
    const file = fileList[fileList.length - 1]

    const check = checkUserImageSize(file.size)
    if (!check) return

    setPicture(file);
    setPictureShow(URL.createObjectURL(file));
  }


  return (
    <div
      className={edit ? 'mypage-artist' : 'mypage-artist mypage-artist-profile'}
    >
      <Header login={true} colored="black" />
      <nav className="search">
        <div className="container search__container">
          <h2 className="search__title">마이페이지</h2>
        </div>
      </nav>
      <HeaderSmall moreList={moreList} active={true} choice={0} />
      {edit ? <></> : <SectionHeader title="프로필 수정" />}
      <section className="gallery-profile">
        <section
          className={
            edit
              ? 'container artist-profile-edit cant-edit'
              : 'container artist-profile-edit'
          }
        >
          {edit ? (
            <div className="ap-edit-header">
              <div className="ap-edit-header-mb">
                <h1>프로필 관리</h1>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {/* <button onClick={() => showQrModal()}>QR 발급</button> */}
                  <button onClick={() => setEdit(false)}>수정하기</button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="gp-edit-content">
            <div className="ap-edit-image">

              <div className="container ma-edit-image-container" style={{ border: 'none' }}>
                <div className={!edit ? "main-image-container" : 'main-image-container image-padding'}>
                  <img
                    className={picture ? 'ma-img-show' : 'ma-img-hide'}
                    style={edit ? { display: 'block' } : {}}
                    src={pictureShow ?? ''}
                    onError={handleImgError}
                    // onError={!edit ? () => {} : handleImgError}
                    alt="이미지"
                    onContextMenu={
                      (e) => {
                          e.preventDefault();
                          
                      }
                    }
                  />
                  {edit ? <></> :
                    <button
                      className={picture ? 'edit-photo' : 'edit-photo ma-img-hide'}
                      onClick={() => { setPicture(null); setPictureShow(null); }}

                    ></button>
                  }
                  <form method="post" encType="multipart/form-data">
                    <div className="button" style={!edit ? { paddingBottom: '100rem' } : { display: 'none' }}>
                      <label className={edit ? "" : "image-button"} htmlFor="mainImage"></label>
                    </div>
                    <input
                      type="file"
                      id="mainImage"
                      name="mainImage"
                      accept="image/*"
                      onChange={saveImgFile}
                      style={{ display: 'none' }}
                    />
                  </form>
                </div>
                {!edit &&
                  <span className="ma-img-tlt--sub image-size-check">*2MB 이하</span>
                }
              </div>
            </div>
            <ul className="gp-edit-basic">
              <div className="ap-edit-content-mb">
                <li className="has-2-input">
                  <h2>기관명</h2>
                  <input
                    type="text"
                    className="a-profile-input"
                    value={agencyKoName}
                    onChange={e => setAgencyKoName(e.target.value)}
                    disabled={edit}
                  ></input>
                  <input
                    type="text"
                    className="a-profile-input"
                    value={agencyEnName}
                    onChange={e => setAgencyEnName(e.target.value)}
                    disabled={edit}
                  ></input>
                </li>
                <li>
                  <h2>설립연월일</h2>
                  <input
                    type={edit ? "text" : "date"}
                    value={agencySince}
                    onChange={e => setAgencySince(e.target.value)}
                    className="date-input"
                    disabled={edit}
                  ></input>
                </li>
                <li>
                  <h2>홈페이지</h2>
                  <input
                    type="text"
                    value={homePage}
                    onChange={e => setHomePage(e.target.value)}
                    className="a-profile-input"
                    disabled={edit}
                    placeholder='여러개 입력시, 구분자 ; 를 사용해주세요.'
                  ></input>
                </li>
                <li className="has-2-input">
                  <h2>주소</h2>
                  <div className="search__block">
                    <input
                      type="text"
                      value={agencyAddress}
                      className="search__block"
                      disabled={edit}
                      readOnly
                    ></input>
                    <button className="search__block__button" onClick={() => setAddressOpen(true)}>
                      <span className="ir_pm">검색</span>
                    </button>
                  </div>
                  <input
                    type="text"
                    value={agencyAddressDetail}
                    onChange={e => setAgencyAddressDetail(e.target.value)}
                    className="a-profile-input"
                    disabled={edit}
                  ></input>
                </li>

                {addressOpen && (
                  <ModalPortal>
                    <AddressModal setModalOpen={setAddressOpen} setAddress={setAgencyAddress} />
                  </ModalPortal>
                )}


                <li>
                  <h2>기관 이메일</h2>
                  <input
                    type="text"
                    className="a-profile-input"
                    value={managerMail}
                    onChange={e => setManagerMail(e.target.value)}
                    disabled={edit}
                  ></input>
                  {/* {
                    edit ?
                    <input
                    type="text"
                    className="a-profile-input"
                    value={`${agencyEmail}@${agencyHost}`}
                    disabled={edit}
                  ></input>
                  :
                  <>
                  <input
                  type="text"
                  value={agencyEmail}
                  onChange={e => setAgencyEmail(e.target.value)}
                  className="email-input-email"
                  required
                ></input>
                <span>@</span>
                <input
                  type="text"
                  className="email-input-required"
                  value={agencyHost}
                  onChange={e => setAgencyHost(e.target.value)}
                  required
                ></input>
                <DropDown options={options} setValue={setAgencyHost} />
                  </>
                  } */}
                </li>



              </div>
            </ul>
            <ul className="gp-edit-basic">
              <div className="ap-edit-content-mb">
                <li>
                  <h2>대표자명</h2>
                  <input
                    type="text"
                    className="a-profile-input"
                    readOnly={en_pattern.test(enManager) && enManager === ""}
                    value={enManager}
                    onChange={e => setEnManager(e.target.value)}
                    disabled={edit}
                  ></input>
                </li>
                <li>
                  <h2>대표자 번호</h2>
                  <div className="gp-drop">
                  <input
                    type="text"
                    className="a-profile-input__local-number"
                    value={localNumber}
                    onChange={e => setLocalNumber(e.target.value)}
                    placeholder='+82'
                    disabled={edit}
                  ></input>
                  <input
                    type="text"
                    className="a-profile-input__number"
                    value={contact}
                    onChange={e => setContact(e.target.value)}
                    placeholder='00000000000'
                    disabled={edit}
                  ></input>
                  </div>
                </li>
                <li>
                  <h2>추가 연락처1</h2>
                  <input
                    type="text"
                    className="a-profile-input"
                    value={contact1}
                    onChange={e => setContact1(e.target.value)}
                    disabled={edit}
                  ></input>
                </li>
                <li>
                  <h2>추가 연락처2</h2>
                  <input
                    type="text"
                    className="a-profile-input"
                    value={contact2}
                    onChange={e => setContact2(e.target.value)}
                    disabled={edit}
                  ></input>
                </li>
              </div>
            </ul>
            <ul className="gp-edit-basic">
              <div className="ap-edit-content-mb" style={{ width: '100%' }}>
                    <li>
                      <h2>서명 및 인장 등록</h2>
                      {
                        edit ?
                          <input
                            type="text"
                            className="a-profile-input"
                            placeholder={gallery.sign === null ? "미등록" : "등록 완료"}
                            disabled={true}
                          ></input>
                          :
                          <div className="ap-drop">
                            <input
                              type="text"
                              className="a-profile-input__number"
                              placeholder={sign !== null ? '등록됨' : gallery.sign === null ? "미등록" : "등록 완료"}
                              disabled={true}
                            ></input>
                            <button
                              className='drop-button'
                              // className='my-button'
                              onClick={showSignModal}
                              style={{ marginLeft: "12rem", marginRight: "0px" }}
                            >
                              {gallery.sign === null ? '등록' : '변경'}
                            </button>
                          </div>
                      }
                    </li>
                <li>
                  <h2>KYC 인증 여부</h2>
                  {edit ? (
                    <input
                      type="text"
                      className="a-profile-input"
                      placeholder={
                        gallery.status === 0
                          ? '미인증'
                          : gallery.status === 1
                            ? '인증 대기'
                            : '인증 완료'
                      }
                      disabled={true}
                    ></input>
                  ) : (
                    <div className="ap-drop">
                      <input
                        type="text"
                        className="a-profile-input__number"
                        placeholder={
                          gallery.status === 0
                            ? '미인증'
                            : gallery.status === 1
                              ? '인증 대기'
                              : '인증 완료'
                        }
                        disabled={true}
                      ></input>

                      <button
                        className="drop-button"
                        style={{ marginLeft: '12rem', marginRight: '0px' }}
                        onClick={() => navigate("/cert/kyc")}
                      >
                        {gallery.status === 2 ? '재인증' : '인증하기'}
                      </button>
                    </div>
                  )}
                </li>
                { gallery.status === 2 && 
                <><li>
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '10rem'}}>
                      <h2 style={{position: 'relative'}}>계좌번호</h2>
                      {/* {!edit && <Tooltip
                      text={'변경을 원할시, KYC인증을 다시해주세요.'}>
                        <img src={question} alt='' className='a-profile-bank-question'/>
                      </Tooltip>} */}
                      </div>
                      <div className="ap-drop">
                        {/* <DropDown disabled={edit} options={banklist} /> */}
                        <input
                          type="text"
                          className="a-profile-input__bank"
                          value={accountCompany ? accountCompany : ''}
                          placeholder="-"
                          onChange={(e) => setAccountCompany(e.target.value)}
                          disabled={edit}
                        ></input>
                        <input
                          type="text"
                          value={accountNumber ? accountNumber : ''}
                          className="a-profile-input__bank_num"
                          placeholder="-"
                          onChange={(e) => setAccountNumber(e.target.value)}
                          disabled={edit}
                        ></input>
                      </div>
                    </li>
                    <li>
                      <h2 style={{paddingBottom: '19rem'}}>소유주</h2>
                      <input
                    type="text"
                    value={accountOwner ? accountOwner : ''}
                    className="a-profile-input"
                    placeholder="-"
                    onChange={(e) => setAccountOwner(e.target.value)}
                    disabled={edit}
                  ></input>
                    </li>
                    </>
}
                {/* <li className="ap-edit-drop">
                  <h2>사용자 유형 변경</h2>
                  <DropDown
                    disabled={edit}
                    options={infoList2}
                    select={infoList2.indexOf(userType)}
                    setValue={setUserType} />
                </li> */}
              </div>
            </ul>
            <ul className="ap-edit-intro">
              <div className="ap-edit-content-mb">
                <li>
                  <h2>기관 소개(글자수는 최대 1000자로 제한됩니다.)</h2>
                  <div className='content-text'>
                    <textarea
                      value={introduce}
                      maxLength={1000}
                      onChange={e => handleInputMaxLength(setIntroduce, e)}
                      readOnly={edit}></textarea>
                    {!edit &&
                      <span>{introduce === null ? '0/1000' : `${introduce.length}/1000`}</span>
                    }
                    {/* <span>{content.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length}/2000</span> */}
                  </div>
                </li>
              </div>
            </ul>
          </div>
          {qrOpen && (
            <ModalPortal>
              <QrModal
                setModalOpen={setQrOpen}
              />
            </ModalPortal>
          )}
          {edit ? (
            <></>
          ) : (
            <section className="ap-profile-edit--button">
              <button onClick={showCheckModal}>저장하기</button>
              <div className="ap-mobile-edit--button">
                <button onClick={() => setEdit(true)}>취소</button>
                <button onClick={showCheckModal}>저장</button>
              </div>
              {checkOpen && (
                <ModalPortal>
                  <CheckModal
                    setModalOpen={setCheckOpen}
                    setFunc={editInfo}
                    title={'저장하기'}
                    content={'프로필 저장 하시겠습니까?'}
                    isAuth={true}
                  />
                </ModalPortal>
              )}
              {signOpen && (
                <ModalPortal>
                  <SignModal
                    setModalOpen={setSignOpen}
                    setFunc={setSign}
                  />
                </ModalPortal>
              )}
            </section>
          )}
          {!edit &&
            <>
              <span className='withdrawal' onClick={() => setWithdrawalModal(true)}>탈퇴하기</span>
              <span className='withdrawal-sub'>*회원 탈퇴시 기존의 자료가 삭제됩니다.</span>
            </>
          }

          {withdrawalModal &&
            <CheckModal
              setModalOpen={setWithdrawalModal}
              setFunc={withdrawalUser}
              title={'회원 탈퇴와 동시에 등록된 모든 정보와 자료는 삭제됩니다.'}
              content={'복구가 불가하니 신중히 선택해주세요.\n정말 탈퇴하겠습니까?'}
            />
          }
        </section>
      </section>
      {edit ? (
        <div>
          {
            gallery.business_image !== null &&
            <>
              <section className="gallery-profile-school">
                <div className="container gallery-profile-school-container">
                  <div className="gp-edit-header">
                    <div className="ap-edit-header-mb">
                      <h1>팀원 관리</h1>
                      {
                        galleryTeam.owner &&
                        <Link to={'./team'}>
                          <button>팀원 추가</button>
                        </Link>
                      }
                    </div>
                  </div>
                  <ul className="gp-exhibition-list">
                    <li className="gp-list--header">
                      <span>이름</span>
                      <span>이메일</span>
                      <span>전화번호</span>
                      <span>직무</span>
                      <div className="gp-liat--button-half"></div>
                    </li>
                    {teamTable}
                    {teamTableMobile}
                  </ul>
                </div>
              </section>
              <section className="gallery-profile-exhibition">
                <div className="container gallery-profile-exhibition-container">
                  <div className="gp-edit-header">
                    <div className="ap-edit-header-mb">
                      <h1>기관이력</h1>
                      <Link to={'./agency'}>
                        <button>이력 추가</button>
                      </Link>
                    </div>
                  </div>
                  <ul className="gp-exhibition-list gp-agency-list">
                    <li className="gp-list--header">
                      <span>번호</span>
                      <span>상호</span>
                      <span>대표자명</span>
                      <span>변경일자</span>
                      <span>주소</span>
                      <div className="gp-liat--button"></div>
                    </li>
                    {agencyTable}
                    {agencyTableMobile}
                  </ul>
                </div>
              </section>
            </>
          }
          <section className="gallery-profile-exhibition">
            <div className="container gallery-profile-exhibition-container">
              <div className="gp-edit-header">
                <div className="ap-edit-header-mb">
                  <h1>전시이력</h1>
                  <Link to={'./exhibition'}>
                    <button>전시 추가</button>
                  </Link>
                </div>
              </div>
              <ul className="gp-exhibition-list gp-history-list">
                <li className="gp-list--header">
                  <span>번호</span>
                  <span>전시명</span>
                  <span>개최장소</span>
                  <span>국가</span>
                  <span>개최기간</span>
                  <span>전시유형</span>
                  <div className="gp-liat--button"></div>
                </li>
                {exhibitionTable}
                {exhibitionTableMobile}
              </ul>
            </div>
          </section>
          <section className="gallery-profile-exhibition">
            <div className="container gallery-profile-exhibition-container">
              <div className="gp-edit-header">
                <div className="ap-edit-header-mb">
                  <h1>History</h1>
                  <Link to={'./agency'}>
                    <button>이력 추가</button>
                  </Link>
                </div>
              </div>
              <ul className="gp-history-list-v2">
                <li className="gp-list--header">
                  <span>번호</span>
                  <span>일자</span>
                  <span>내역</span>
                  <span>상세정보</span>
                  <div className="gp-liat--button"></div>
                </li>
                {historyTable}
                {historyTableMobile}
              </ul>
            </div>
          </section>
        </div>
      ) : (
        <></>
      )}
      <Footer />
    </div>
  )
}
export default GalleryProfile
