import React from 'react'
import './CheckModal.css'


function CheckModal({ setModalOpen, setFunc, title, content , isAuth}) {
  const [save, setSave] = React.useState(false);
  // 모달 끄기
  const closeModal = () => {
    setModalOpen(false)
    //document.body.style.overflow = 'unset' // 모달 창 종료 시 스크롤 가능
  }
  const changeState = async() => {
    if(!isAuth && !save){
      alert('체크박스를 선택해주세요.')
      return
    }
    await setFunc()
    setModalOpen(false)
  }
  
  return (
    <div className="check-modal">
      <div className="modal-container" onClick={closeModal}>
        <div className="modal-body" onClick={e => e.stopPropagation()}>
          <h1 style={{ whiteSpace: 'pre-wrap', textAlign: 'center'}}>{title}</h1>
          <div className="check-content">{content}</div>
          
          {
            isAuth ?
            (
              <></>
            )
            :
            (
              <div>
                        <label className="info-option">
                          <input autoComplete='off' type="checkbox" checked={save} onChange={e => setSave(e.target.checked)} />
                          <span className="info-option__check" />
                          
                          <span className= {save ? `info-true-check-color` :"info-false-check-color"}>
                          안내 사항을 모두 확인하였으며, 이에 동의합니다.
                          </span>
                          
                        </label>

                        </div>
            )
          }
          
          <div className="check-btn">
            <button onClick={closeModal}>취소</button>
            <div style={{width:12}}></div>
            <button onClick={setFunc ? changeState : closeModal}>확인</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckModal
