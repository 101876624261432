import client from "../../index";

/**
 * 마켓에 최근 등록된 리스트
 * @param {number} row 요청 갯수
*/
const getNewMarketList = async(row) => {
  const setData = {
    count : row,
  }
  const res = await client.json.get('/user/work/recent', {params: setData});
  return res.data;
}


/**
  작품 상세보기
   * @param {number} pk
*/
const getDetail = async(pk) => {
  const res = await client.json.get(`/user/market/${pk}`);
  return res.data;
}

/**
 * 작품 유형별 리스트
 * @param {number} currentPage 현재 페이지
 * @param {number} row 요청 갯수
 * @param {number} work_type 작품 유형 pk, 0 전체
 * @param {Stirng} order 0 최신순, 1 인기순, 2 작가명순, 3 작품명순
*/
const getMarketListByType = async(currentPage, row, work_type, order) => {
  const setData = {
    page : currentPage,
    count : row,
    work_type: work_type,
    order: order
  }
    const res = await client.json.get('/user/market/work', {params: setData});
    return res.data;
}

/**
 * nft 리스트
 * @param {number} currentPage 현재 페이지
 * @param {number} row 요청 갯수
 * @param {Stirng} order 0 최신순, 1 인기순, 2 작가명순, 3 작품명순
*/
const getMarketNFTListByType = async(currentPage, row, order) => {
  const setData = {
    page : currentPage,
    count : row,
    type: order
  }
    const res = await client.json.get('/user/nft', {params: setData});
    return res.data;
}

/**
 * 플랫폼 Q 리스트
 * @param {number} currentPage 현재 페이지
 * @param {number} row 요청 갯수
 * @param {number} type 1: 가나다순 2: ABC순 3:최신순 4: 초고순
*/
const getPlatfromQ = async(currentPage, row, type) => {
  const setData = {
    page : currentPage,
    count : row,
    type: type
  }
    const res = await client.json.get('/user/market/platform-q', {params: setData});
    return res.data;
}


/**
 * 배송정보 불러오기
*/
const getAddress = async() => {
  const res = await client.json.get('/user/market/delivery');
  return res.data;
}

/**
 * 기본 배송지 변경
  * @param {number} pk
*/
const setDefaultAddress = async(pk) => {

    const res = await client.json.patch(`/user/market/delivery/${pk}`);
    return res.data;
}



/**
 * 배송지 추가
  * @param {String} address 주소
  * @param {String} addressDetail 상세 주소
  * @param {String} addressName 주소지 이름
  * @param {String} buyerEmail 구매자 이메일
  * @param {String} buyerName 구매자 이름
  * @param {String} buyerPhone 구매자 전화번호
  * @param {String} giver 수령인
  * @param {String} memo 배송메모
  * @param {String} phone 받는사람 전화번호
*/
const addAddress = async(address, addressDetail, addressName, buyerEmail, buyerName, buyerPhone, giver, memo, phone) => {

  const setData = {
    ADDRESS: address,
    ADDRESS_DETAIL: addressDetail,
    ADDRESS_NAME: addressName,
    BUYER_EMAIL: buyerEmail,
    BUYER_NAME: buyerName,
    BUYER_PHONE: buyerPhone,
    GIVER: giver,
    MEMO: memo,
    PHONE: phone,
  }

  // const res = await client.json.post('/user/market/sell', setData);
  const res = await client.json.post('/user/market/delivery', setData);
  return res.data;
}

/**
 * 배송지 삭제
  * @param {number} pk
*/
const deleteAddress = async(pk) => {

  const res = await client.json.delete(`/user/market/delivery/${pk}`);
  return res.data;
}


/**
 * 작품 구매
  * @param {Object} order 주문자 정보
  * @param {Object} delivery 배송 정보
  * @param {Number} point 사용하는 포인트
*/
const buy = async(order, delivery, uniqueKey, point) => {

  const setData = {
    ...order,
    ...delivery,
    UNIQUE_KEY: uniqueKey,
    USE_POINT: point,
  }

  // const res = await client.json.post('/user/market/sell', setData);
  const res = await client.json.post('/user/work/transaction', setData);
  return res.data;
}

/**
 * 작품 구매 인증
  * @param {String} orderNo 주문번호
  * @param {Object} token token 데이터
*/
const certificateBuy = async(orderNo, token) => {

  const setData = {
    ORDERNO: orderNo,
    OBJECT: token
  }

  const res = await client.json.post('/user/work/transaction/check', setData);
  return res.data;
}



/**
 * 작품 구매 취소
  * @param {Number} pk 구매목록 pk
*/
const cancel = async(pk) => {
  const res = await client.json.delete(`/user/work/market/${pk}`);
  return res.data;
}

/**
 * 작품 구매 반품(배송지 입력)
  * @param {Number} pk 구매목록 pk
  * @param {String} company 배송업체명
  * @param {String} fromDate 보낸 날짜
  * @param {String} toDate 배송 예정일
  * @param {String} invoice 송장번호
  * @param {String} contact 연락처
*/
const returned = async(pk, company, fromDate, toDate, invoice, contact) => {
  
  const setData = {
    deliveryCompany: company,
    scheduledDate: toDate,
    sendDate: fromDate,
    invoiceNumber: invoice,
    contact: contact
  }
  const res = await client.json.post(`/user/work/market/delivery/${pk}`, setData);
  return res.data;
}


/**
 * 작품 구매 반품 거절(판매자)
  * @param {Number} pk 구매목록 pk
  * @param {String} company 배송업체명
  * @param {String} fromDate 보낸 날짜
  * @param {String} toDate 배송 예정일
  * @param {String} invoice 송장번호
  * @param {String} contact 연락처
*/
const approveReturned = async(pk, company, fromDate, toDate, invoice, contact) => {
  
  const setData = {
    deliveryCompany: company,
    scheduledDate: toDate,
    sendDate: fromDate,
    invoiceNumber: invoice,
    contact: contact
  }
  const res = await client.json.post(`/user/work/market/delivery/${pk}`, setData);
  return res.data;
}

/**
 * 작품 구매 반품 승인(판매자)
  * @param {Number} pk 구매목록 pk
  * @param {String} company 배송업체명
  * @param {String} fromDate 보낸 날짜
  * @param {String} toDate 배송 예정일
  * @param {String} invoice 송장번호
  * @param {String} contact 연락처
*/
const disapproveReturned = async(pk, company, fromDate, toDate, invoice, contact) => {
  
  const setData = {
    deliveryCompany: company,
    scheduledDate: toDate,
    sendDate: fromDate,
    invoiceNumber: invoice,
    contact: contact
  }
  const res = await client.json.post(`/user/work/market/delivery/${pk}`, setData);
  return res.data;
}


/**
 * 마켓플레이스 작품 등록
 * @param {number} pk 
 * @param {String} password 적용할 간편 패스워드
*/
const register = async(pk,password) => {
  
  const res = await client.json.patch(`/user/work/my-work/${pk}`);
  return res.data;
}

/**
 * coa 생성된 작품 수정
 * @param {String} uniqueKey 
 * @param {number} view 0: 공개, 1: 비공개
 * @param {number} market 0: 비매, 1: 판매
 * @param {String} price 가격
 * @param {String} priceType 1:원화 2:달러
 * @param {Object} history 전시이력
*/
const fixWork = async(uniqueKey, view, market, price, priceType, history) => {
  
  const formData = new FormData();
    formData.append("UNIQUE_KEY", uniqueKey);
    formData.append("VIEW", view);
    formData.append("MARKET", market === 2 ? 0 : market);
    formData.append("PRICE", price);
    formData.append("PRICE_UNIT", priceType);
    formData.append("EXHIBIT_HISTORY", JSON.stringify(history)); 

  // const setData = {
  //   UNIQUE_KEY: uniqueKey,
  //   VIEW: view,
  //   MARKET: market === 2 ? 0 : market,
  //   PRICE: price,
  //   PRICE_UNIT: priceType,
  //   EXHIBIT_HISTORY: history
  // }
  
  const res = await client.formData.post('/user/work/my-work/fix-coa', formData);
  return res.data;
}


/**
 * 옥션 Q 리스트
 * @param {number} isNft 0 실물, 1 NFT
 * @param {number} currentPage 현재 페이지
 * @param {number} row 요청 갯수
 * @param {number} type 0: 과거 1: 진행 3: 예정
 * @param {number} order 0: 최신 1:작품명 2:작가명 3: 인기순
*/
const getAuctionList = async(isNFT, currentPage, row, type, order) => {
  const setData = {
    isNft: isNFT,
    page : currentPage,
    count : row,
    type: type,
    order: order
  }
  const res = await client.json.get('/user/nft/auction', {params: setData});
  return res.data;
}

/**
 * NFT 구매
  * @param {number} pk nft PK
  * @param {String} walletAddress 소유권 이전할 주소
  * @param {Number} point 사용하는 포인트
*/
const nftBuy = async(pk, walletAddress, point) => {

  const setData = {
    RECEIVE_WALLET: walletAddress,
    USE_POINT: point
  }

  const res = await client.json.post(`/user/nft/${pk}`, setData);
  return res.data;
}


/**
 * NFT 낙찰
  * @param {number} pk nft PK
  * @param {String} walletAddress 소유권 이전할 주소
  * @param {Number} point 사용하는 포인트
*/
const nftBidBuy = async(pk, walletAddress, point) => {

  const setData = {
    RECEIVE_WALLET: walletAddress,
    USE_POINT: point
  }

  const res = await client.json.post(`/user/nft/my-auction/${pk}`, setData);
  return res.data;
}

/**
 * 내 작품 경매 등록
 * @param {number} pk 작품 Pk
 * @param {Stirng} start 시작
 * @param {Stirng} end 마감
 * @param {Stirng} price 시작가
*/
const setWorkBid = async(pk, start, end, price) => {
  const setData = {
    START_TIME: start,
    END_TIME : end,
    START_PRICE : price,
  }
    const res = await client.json.post(`/user/auction/${pk}`, setData);
    return res.data;
}

/**
 * 내 작품 경매 정보
 * @param {number} pk 작품 Pk
*/
const getWorkBid = async(pk) => {
    const res = await client.json.get(`/user/auction/detail/${pk}`);
    return res.data;
}


/**
  판매, 경매 등록 취소
   * @param {number} pk
*/
const setCancel = async(pk) => {
  const res = await client.json.delete(`/user/market/${pk}`);
  return res.data;
}

/**
  작품 판매 등록
   * @param {number} pk
   * @param {String} price 판매 가격
   * @param {String} unit 판매 단위
   * @param {String} pin 현재 핀번호
*/
const setSell = async(pk, price, unit, pin) => {
  const postData = {
    PRICE: price,
    PRICE_UNIT: unit,
    SHORT_PASSWORD: pin
  }
  const res = await client.json.post(`/user/market/${pk}`,postData);
  return res.data;
}

/**
  작품 경매 등록
   * @param {number} pk
   * @param {String} price 경매 시작가
   * @param {String} start 시작 시간
   * @param {String} end 마감 시간
   * @param {String} pin 현재 핀번호
   * @param {String} unit 판매 단위
*/
const setAuction = async(pk, price,  start, end, pin, unit) => {
  const postData = {
    START_PRICE: price,
    START_TIME: start,
    END_TIME: end,
    SHORT_PASSWORD: pin,
    PRICE_UNIT: unit,
  }
  const res = await client.json.post(`/user/auction/${pk}`, postData);
  return res.data;
}


/**
  작품 경매 등록
   * @param {string} uniqueKey
*/
const priceOver = async(uniqueKey) => {
  const postData = {
    UNIQUE_KEY: uniqueKey,
  }
  const res = await client.json.post(`/user/market/purchase`, postData);
  return res.data;
}

const market = {
  getNewMarketList, getMarketListByType, getMarketNFTListByType, getDetail, getPlatfromQ,
  getAddress, addAddress,
  register, buy, certificateBuy,

  cancel, returned,
  approveReturned, disapproveReturned,
  fixWork,
  setDefaultAddress, deleteAddress,
  getAuctionList, nftBuy, nftBidBuy,
  setWorkBid, getWorkBid,

  setCancel, setSell,setAuction,

  priceOver
}

export default market
