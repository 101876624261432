import React from 'react'
import './Cert.css'
import { useNavigate } from 'react-router-dom'
import setting from '../../../../../services/setting';
import Header from '../../../../../components/Header/Header';
import Footer from '../../../../../components/Footer/Footer';
import auth from '../../../../../services/auth/auth';
import { shallowEqual, useSelector } from 'react-redux';

function Cert() {
  const { type } = useSelector(v => v.auth, shallowEqual)
  const [kycToken, setKycToken] = React.useState('')
  const navigate = useNavigate();
  const [doEKyc, setDoEKyc] = React.useState(false)
  const [already, setAlready] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const iframeRef = React.useRef(null);


  React.useEffect(() => {
    getToken()
  }, [])

  React.useEffect(() => {
    window.addEventListener("message", e => { action(e) })
    return () => {
      window.removeEventListener("message", e => { action(e) })
    };
  }, [])

  const action = async (e) => {
    if (e.origin === "https://kyc.useb.co.kr") {
      const decodedData = decodeURIComponent(atob(e.data));
      const json = JSON.parse(decodedData);

      // iframe 닫을시
      if (json.result === 'close' || json.result === 'complete') {
        setDoEKyc(false)
        navigate(-1)
      }

      //인증 완료시
      if (json.result === 'success') {
        const data = json.review_result;

        const name = data.name; // 유저이름
        const birthday = data.birthday; // 생일
        const phone = data.phone_number //폰번호

        const idCard1 = data.id_card.id_card_image; // 주민 뒷자리 가림
        const idCard2 = data.id_card.id_card_origin; // 주민 원본
        const idCard3 = data.id_card.id_crop_image; // id 카드에 있는 얼굴
        const userFace = data.face_check.selfie_image; //idCard3이랑 비교했던 사용자 얼굴

        const accountCompany = data.account.finance_company; // 은행
        const accountNumber = data.account.account_number; // 계좌번호

        const myJson = {
          NAME: name,
          BIRTHDAY: birthday,
          PHONE_NUMBER: phone,
          // ID_CARD1: idCard1, //base64
          // ID_CARD2: idCard2, //base64
          // ID_CARD3: idCard3, //base64
          // USER_FACE: userFace, //base64
          ACCOUNT_COMPANY: accountCompany,
          ACCOUNT_NUMBER: accountNumber
        }

        if (!already) {
          const res = await auth.certifiedKYC(myJson)
          if (!res.resultCode) {
            alert('서버오류')
            return
          }
          setAlready(true)
          setSuccess(true)
        }
        return
      }
    }
  }

  const navigateByType = () => {
    if(type === 1){
      navigate('/myPage/artist')
    }else{
      navigate('/myPage/gallery')
    }
  }
  const getToken = async () => {
    const res = await setting.getToken()
    setKycToken(res)
  }

  const doKyc = () => {
    setDoEKyc(true)

    // 아이프레임
    const KYC_TARGET_ORIGIN = "https://kyc.useb.co.kr";
    const KYC_URL = "https://kyc.useb.co.kr/auth";
    const params = { "access_token": kycToken };

    const kycIframe = iframeRef.current;
    kycIframe.onload = function () {
      let encodedParams = btoa(encodeURIComponent(JSON.stringify(params)));
      kycIframe.contentWindow.postMessage(encodedParams, KYC_TARGET_ORIGIN);
      // eslint-disable-next-line no-undef
      hideLoadingUI();
      // eslint-disable-next-line no-undef
      startKYC();
      kycIframe.onload = null;
    };
    kycIframe.src = KYC_URL;
  }



  return (
    <div className="cert">
      <Header />


      <div style={{ width: '100%', height: '80vh', display: doEKyc ? 'block' : 'none' }}>
        <iframe
          title='kyc'
          allow="camera"
          ref={iframeRef}
          width="100%" height="100%"
        />
      </div>

      <div className="container cert-container" style={{ display: !doEKyc ? 'flex' : 'none' }}>
        <article className="cert-content">
          <h1>KYC 인증</h1>
          <p>
            *KYC(Know Your Customer) 인증은 가입자가 온라인으로 제출하신 신분증
            및 자격증을 제 3 인증기관을 통해 진위여부를 확인하는 기술입니다.
            <br /> *KYC (Know Your Customer) verification is a technology that
            verifies the authenticity of the ID and license submitted by the
            subscriber online through a third-party certification authority.
            <br />
            <br /> *KYC인증을 거치지 않은 작가 및 기관 가입자는 서명 및 인장
            등록, 작품 인증 및 거래이력 등록을 포함한 Q-CoA 서비스에 제한이 있을
            수 있습니다.
            <br /> *Artists and institutional subscribers who have not gone
            through KYC verification may have restrictions on Q-CoA services,
            including signature and seal registration, work certification, and
            transaction history registration.
          </p>
        </article>

        <button className="cert-button" onClick={success ? () => navigateByType() : doKyc}>{success ? "완료" : "KYC 인증 진행하기"} </button>

        {!success &&
        <div className="general-next">
          <button onClick={navigateByType}>이전</button>
        </div>
        }


      </div>
      <Footer />
    </div>
  )
}
export default Cert
